import React, { Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import NetworkStatus from './NetworkStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import logoWhite from '../../assets/img/siemens-logo-white-small.png';
import besafeLogo from '../../assets/img/besafe-logo.png';
import { useAppContext } from '../../utils/auth/context';
import { Auth } from 'aws-amplify';

import './NavigationBar.scss';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <FontAwesomeIcon
    ref={ref}
    icon={faUserCircle}
    role='button'
    color='white'
    size='lg'
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  />
));

const NavigationBar = () => {
  const history = useHistory();
  const { isAuthenticated, setIsAuthenticated, authUser, setAuthUser } =
    useAppContext();

  const handleLogout = () => {
    localStorage.removeItem('idToken');
    Auth.signOut().then((response) => {
      setAuthUser(null);
      setIsAuthenticated(false);
    });
  };

  return (
    <Navbar bg='siemens-teal' variant='dark'>
      <Container fluid>
        <Link to='/' title='Home' className='mr-3'>
          <Navbar.Brand className='mr-0'>
            <img
              src={besafeLogo}
              alt='beSafe logo'
              className='mr-2'
              width='40'
            />
            <img
              src={logoWhite}
              alt='Siemens logo'
              className='mr-2'
              width='100'
            />
            <span className='align-middle'>beSafe</span>
          </Navbar.Brand>
        </Link>
        <Nav className='mr-auto'>
          {isAuthenticated && (
            <Fragment>
              <span title='Go Back'>
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  color='white'
                  size='lg'
                  className='pointer mx-2'
                  onClick={() => history.goBack()}
                />
              </span>
              <span title='Go Forward'>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  color='white'
                  size='lg'
                  className='pointer mx-2'
                  onClick={() => history.goForward()}
                />
              </span>
            </Fragment>
          )}
        </Nav>
        <Navbar.Brand>
          <NetworkStatus />
          <span className='px-2'>|</span>
          <Link to='/imprint' title='Imprint' className='text-white'>
            Imprint
          </Link>
          <span className='px-2'>|</span>
          <span>{authUser ? authUser.username : ''}</span>
        </Navbar.Brand>
        {isAuthenticated && (
          <Dropdown>
            <Dropdown.Toggle
              id='dropdown-navbar'
              as={CustomToggle}
            ></Dropdown.Toggle>
            <Dropdown.Menu className='dropdown-menu-right'>
              <Dropdown.Item onClick={handleLogout}>Log Out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
