import React, { Fragment, useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Tags from '../../shared/tags/Tags';
import LinkObservations from '../../shared/linking/LinkObservations';
import LinkDocuments from '../../shared/linking/LinkDocuments';
import LinkPhotos from '../../shared/linking/LinkPhotos';
import { useDispatch } from 'react-redux';
import { updateInterviewAnswer } from '../../../store/actions/interviewsActions';
import { convertHTMLToText } from '../../../utils/convert';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const InterviewQuestionItem = ({
  question: {
    question_id,
    audit_id,
    entity_id,
    question_text,
    tooltip,
    in_report,
    question_answer,
    is_significant,
    question_comment,
    root_cause,
    impact,
    tags,
    link_observations,
    link_documents,
    link_photos,
  },
  // handleUpdateQuestion,
  handleQuestionModified,
}) => {
  const dispatch = useDispatch();
  const [questionTags, setQuestionTags] = useState(tags || []);
  const [linkedObservations, setLinkedObservations] = useState(
    link_observations || []
  );
  const [linkedDocuments, setLinkedDocuments] = useState(link_documents || []);
  const [linkedPhotos, setLinkedPhotos] = useState(link_photos || []);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [questionSaved, setQuestionSaved] = useState(false);
  const [questionChanged, setQuestionChanged] = useState(false);
  const [error, setError] = useState(null);
  const [state, setState] = useState({
    question_answer: question_answer || '',
    is_significant: is_significant || '',
    in_report: in_report || false,
  });
  const [questionComment, setQuestionComment] = useState(
    decodeURI(question_comment)
  );
  const commentRef = useRef();
  const [rootCause, setRootCause] = useState(decodeURI(root_cause));
  const rootCauseRef = useRef();
  const [impactState, setImpactState] = useState(decodeURI(impact));
  const impactRef = useRef();
  const didMountRef = useRef(false);
  const commentMaxLength = 5000;
  const quillModules = {
    toolbar: [['bold', 'underline', { background: [] }]],
  };

  useEffect(() => {
    // didMountRef - trick used to trigger useEffect only when 'questionChanged'
    // was modified, and not on initial render
    if (didMountRef.current) {
      handleQuestionModified({ question_id, questionChanged });
    } else {
      didMountRef.current = true;
    }
    // eslint-disable-next-line
  }, [questionChanged]);

  useEffect(() => {
    // If comment field is empty, uncheck 'Add to report'
    if (convertHTMLToText(questionComment).length === 0) {
      setState({
        ...state,
        in_report: false,
      });
    }
    // eslint-disable-next-line
  }, [questionComment]);

  const handleChange = (e) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (
      (e.target.name === 'is_significant' && (value === '' || value === '-')) ||
      (e.target.name === 'question_answer' && value === '')
    ) {
      setState({
        ...state,
        [e.target.name]: value,
        in_report: false,
      });
    } else {
      setState({
        ...state,
        [e.target.name]: value,
      });
    }
  };

  const handleQuillKeyDown = (e, quillRef) => {
    // As react-quill is a semi-controlled component and handles its own changes,
    // you can't 'intercept'/handle the onChange event and add custom logic to it.
    // Hence - using onKeyDown event to detect form changes and control number of characters.
    setQuestionSaved(false);
    setQuestionChanged(true);
    // Prevent exceeding character limit
    const unprivilegedEditor = quillRef.current.unprivilegedEditor;
    if (
      unprivilegedEditor.getLength() > commentMaxLength &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete'
    ) {
      e.preventDefault();
    }
  };

  const validate = (state) => {
    if (!state.question_answer) {
      return 'Answer field is required.';
    }
    if (!state.is_significant) {
      return 'Significance field is required.';
    }
    if (!convertHTMLToText(questionComment).trim()) {
      return 'Comment field is required.';
    }
    return null;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const errorMsg = validate(state);
    if (errorMsg) {
      setError(errorMsg);
      return;
    } else {
      setError(null);
      const questionObj = {
        ...state,
        question_comment: encodeURI(questionComment.trim()),
        root_cause: encodeURI(rootCause.trim()),
        impact: encodeURI(impactState.trim()),
        tags: questionTags,
        link_observations: linkedObservations,
        link_documents: linkedDocuments,
        link_photos: linkedPhotos,
      };
      setQuestionSaved(false);
      // setButtonLoading(true);
      dispatch(
        updateInterviewAnswer({
          audit_id,
          question_id,
          interview_id: entity_id,
          questionObj,
        })
      );
      setQuestionChanged(false);
      setQuestionSaved(true);
    }
  };

  const handleAddTag = (newTag) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    setQuestionTags((questionTags) => [...questionTags, newTag]);
  };

  const handleRemoveTag = (tagName) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    setQuestionTags(questionTags.filter((tag) => tag !== tagName));
  };

  const handleAddLinkedObservation = (linkedObs) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    setLinkedObservations((linkedObservations) => [
      ...linkedObservations,
      linkedObs,
    ]);
  };

  const handleRemoveLinkedObservation = (linkedObs) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    setLinkedObservations(
      linkedObservations.filter((obs) => obs !== linkedObs)
    );
  };

  const handleAddLinkedDocument = (linkedDoc) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    setLinkedDocuments((linkedDocuments) => [...linkedDocuments, linkedDoc]);
  };

  const handleRemoveLinkedDocument = (linkedDoc) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    setLinkedDocuments(linkedDocuments.filter((doc) => doc !== linkedDoc));
  };

  const handleAddLinkedPhoto = (linkedPhotosArray) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    setLinkedPhotos([...new Set([...linkedPhotos, ...linkedPhotosArray])]);
  };

  const handleRemoveLinkedPhoto = (linkedPhoto) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    setLinkedPhotos(linkedPhotos.filter((photo) => photo !== linkedPhoto));
  };

  return (
    <Card className='mb-4'>
      <Card.Body>
        <Row>
          <Col xs={9} className='border-right'>
            <Card.Title>
              Question {question_id}
              <OverlayTrigger
                key={question_id}
                placement='right'
                overlay={
                  <Tooltip id={`tooltip-${question_id}`}>{tooltip}</Tooltip>
                }
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  size='xs'
                  className='ml-2'
                />
              </OverlayTrigger>
            </Card.Title>
            <Card.Text>{question_text}</Card.Text>
            <Form id={`questionForm${question_id}`} onSubmit={handleFormSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId={`answerSelect${question_id}`}>
                    <Form.Label>Answer *</Form.Label>
                    <Form.Control
                      size='sm'
                      as='select'
                      name='question_answer'
                      value={state.question_answer}
                      onChange={handleChange}
                    >
                      <option value=''>-</option>
                      <option value='yes'>Yes</option>
                      <option value='no'>No</option>
                      <option value='na'>Not applicable</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId={`significanceSelect${question_id}`}>
                    <Form.Label>Significance *</Form.Label>
                    <Form.Control
                      size='sm'
                      as='select'
                      name='is_significant'
                      value={state.is_significant}
                      onChange={handleChange}
                    >
                      <option value=''></option>
                      <option value='-'>-</option>
                      <option value='minor'>Minor</option>
                      <option value='significant'>Significant</option>
                      <option value='good-practice'>Good Practice</option>
                      <option value='bundle-1'>Bundle 1</option>
                      <option value='bundle-2'>Bundle 2</option>
                      <option value='bundle-3'>Bundle 3</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId={`checkbox${question_id}`}>
                <Form.Check
                  type='checkbox'
                  label='Add to report'
                  name='in_report'
                  checked={state.in_report === true}
                  disabled={
                    !state.is_significant ||
                    state.is_significant === '-' ||
                    !state.question_answer ||
                    !convertHTMLToText(questionComment).trim()
                  }
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                controlId={`commentTextarea${question_id}`}
                className='mb-0'
              >
                <Form.Label>Comment *</Form.Label>
                <ReactQuill
                  theme='snow'
                  modules={quillModules}
                  ref={commentRef}
                  value={questionComment}
                  onChange={setQuestionComment}
                  onKeyDown={(e) => handleQuillKeyDown(e, commentRef)}
                />
                <div className='d-flex justify-content-end'>
                  <small className='text-muted'>
                    {convertHTMLToText(questionComment).length}/
                    {commentMaxLength}
                  </small>
                </div>
              </Form.Group>
              {state.in_report === true && (
                <Fragment>
                  <Form.Group
                    controlId={`rootCauseTextarea${question_id}`}
                    className='mb-0'
                  >
                    <Form.Label>Root cause</Form.Label>
                    <ReactQuill
                      theme='snow'
                      ref={rootCauseRef}
                      modules={quillModules}
                      value={rootCause}
                      onChange={setRootCause}
                      onKeyDown={(e) => handleQuillKeyDown(e, rootCauseRef)}
                    />
                    <div className='d-flex justify-content-end'>
                      <small className='text-muted'>
                        {convertHTMLToText(rootCause).length}/{commentMaxLength}
                      </small>
                    </div>
                  </Form.Group>
                  <Form.Group
                    controlId={`impactTextarea${question_id}`}
                    className='mb-0'
                  >
                    <Form.Label>Impact</Form.Label>
                    <ReactQuill
                      theme='snow'
                      ref={impactRef}
                      modules={quillModules}
                      value={impactState}
                      onChange={setImpactState}
                      onKeyDown={(e) => handleQuillKeyDown(e, impactRef)}
                    />
                    <div className='d-flex justify-content-end'>
                      <small className='text-muted'>
                        {convertHTMLToText(impactState).length}/
                        {commentMaxLength}
                      </small>
                    </div>
                  </Form.Group>
                </Fragment>
              )}
            </Form>
          </Col>
          <Col xs={3}>
            <div className='d-flex flex-column justify-content-between h-100'>
              <div>
                <LinkObservations
                  linked={linkedObservations}
                  onAddLinkedObservation={handleAddLinkedObservation}
                  onRemoveLinkedObservation={handleRemoveLinkedObservation}
                />
                <LinkDocuments
                  linked={linkedDocuments}
                  onAddLinkedDocument={handleAddLinkedDocument}
                  onRemoveLinkedDocument={handleRemoveLinkedDocument}
                />
                <LinkPhotos
                  linked={linkedPhotos}
                  onAddLinkedPhoto={handleAddLinkedPhoto}
                  onRemoveLinkedPhoto={handleRemoveLinkedPhoto}
                />
                <Tags
                  tags={questionTags}
                  onAddTag={handleAddTag}
                  onRemoveTag={handleRemoveTag}
                />
              </div>
              <div className='text-right mt-3'>
                <p className='text-danger'>{error}</p>
                <Button
                  form={`questionForm${question_id}`}
                  variant={
                    questionChanged
                      ? 'warning'
                      : questionSaved
                      ? 'success'
                      : 'primary'
                  }
                  type='submit'
                >
                  {buttonLoading && (
                    <FontAwesomeIcon
                      icon='circle-notch'
                      spin
                      className='mr-2'
                    />
                  )}
                  Save question
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default React.memo(InterviewQuestionItem);
