import React from 'react';
import PhotoGallery from './photoGallery/PhotoGallery';

const PhotoGalleryTab = () => {
  return (
    <div className='py-4'>
      <PhotoGallery />
    </div>
  );
};

export default PhotoGalleryTab;
