import { FETCH_PHOTOS_REFRESH_COMMIT } from '../types/photosTypes';
import { storePhotoFile, removeDuplicatePhotos } from '../../utils/photos';

export const photoRefreshMiddleware = ({ getState, dispatch }) => {
  return function (next) {
    return async function (action) {
      if (action.type === FETCH_PHOTOS_REFRESH_COMMIT) {
        const photos = action.payload.data;
        await Promise.all(photos.map((photo) => storePhotoFile(photo)));
        await removeDuplicatePhotos();
      }
      return next(action);
    };
  };
};
