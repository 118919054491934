import {
  FETCH_AUDITS,
  FETCH_AUDIT_REFRESH,
  FETCH_AUDIT_REFRESH_COMMIT,
  SET_LOADING,
  UPDATE_AUDIT,
  UPDATE_AUDIT_COMMIT,
  EXPORT_AUDIT,
  DELETE_ARCHIVED_AUDIT,
} from '../types/auditsTypes';
import axiosInst from '../../http/axiosConfig';
import {
  fetchAuditsList,
  updateAuditData,
  fetchAuditData,
  deleteArchivedAudit,
} from '../../http/requests';
import { fetchAuditSummaryInit } from './auditsSummaryActions';
import { fetchObservationsInit } from './observationsActions';
import { fetchInterviewsInit } from './interviewsActions';
import { fetchPhotosInit } from './photosActions';
import { fetchDocumentsInit } from './documentsActions';
import { fetchGPsInit } from './gpsActions';
import { fetchCategories, fetchQuestions } from './questionsActions';
import { toast } from 'react-toastify';
import * as moment from 'moment';

export const fetchAudits = () => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    axiosInst(fetchAuditsList())
      .then((response) => {
        // Check if response is an array; in case of no audits
        // request returns empty object - {}
        let fetchedAudits = Array.isArray(response.data) ? response.data : [];
        fetchedAudits = fetchedAudits
          .map((audit) =>
            audit.hasOwnProperty('sync_status')
              ? { ...audit, authorized: false }
              : audit
          )
          .sort(
            (a, b) =>
              new moment(a.creation_timestamp) -
              new moment(b.creation_timestamp)
          )
          .map((audit, index) =>
            index > 9
              ? { ...audit, authorized: false, audit_above_limit: true }
              : audit
          );
        // Array of audit ids which data is already stored
        const storedAuditsWithData = getState()
          .audits.audits.filter((audit) => audit.authorized)
          .map((audit) => audit.audit_id);

        dispatch({
          type: FETCH_AUDITS,
          payload: fetchedAudits,
        });

        // Filter out audits that user is not authorized to edit
        // and audits which were previously fetched

        // TO DO?: delete data belonging to audits that user is no longer authorized to edit

        fetchedAudits
          .filter((audit) => audit.authorized)
          .filter(
            (audit) => storedAuditsWithData.indexOf(audit.audit_id) === -1
          )
          .forEach((audit) => {
            dispatch(fetchAuditSummaryInit(audit.audit_id));
            dispatch(fetchObservationsInit(audit.audit_id));
            dispatch(fetchInterviewsInit(audit.audit_id));
            dispatch(fetchPhotosInit(audit.audit_id));
            dispatch(fetchDocumentsInit(audit.audit_id));
            dispatch(fetchGPsInit(audit.audit_id));
          });
        dispatch(fetchCategories());
        dispatch(fetchQuestions());
      })
      .catch((error) => {
        dispatch({
          type: SET_LOADING,
          payload: false,
        });
      });
  };
};

export const fetchAuditRefresh = (auditId) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_AUDIT_REFRESH,
      payload: parseInt(auditId),
      meta: {
        offline: {
          effect: fetchAuditData(auditId),
          commit: {
            type: FETCH_AUDIT_REFRESH_COMMIT,
            meta: {
              audit_id: parseInt(auditId),
            },
          },
        },
      },
    });
  };
};

export const updateAudit = ({ auditId, data }) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_AUDIT,
      payload: {
        audit_id: parseInt(auditId),
        data,
      },
      meta: {
        offline: {
          effect: updateAuditData({ auditId, data }),
          commit: {
            type: UPDATE_AUDIT_COMMIT,
            meta: {
              audit_id: parseInt(auditId),
              then: () => () => {
                toast.success('Audit data updated');
              },
            },
          },
        },
      },
    });
  };
};

export const exportAudit = (auditId) => {
  return {
    type: EXPORT_AUDIT,
    payload: parseInt(auditId),
  };
};

// Delete archived audit
export const deleteAudit = (auditId) => {
  return (dispatch) => {
    axiosInst(deleteArchivedAudit(auditId))
      .then((response) => {
        dispatch({
          type: DELETE_ARCHIVED_AUDIT,
          payload: parseInt(auditId),
        });
      })
      .catch((error) => {
        //
      });
  };
};
