import axios from 'axios';

const getDocumentBlob = ({ operationType, documentUrl }) => {
  return axios({
    method: 'get',
    url: documentUrl,
    responseType: 'blob',
  }).then((response) => {
    let blobUrl;
    if (operationType === 'view') {
      blobUrl = URL.createObjectURL(
        new Blob([response.data], { type: 'application/pdf' })
      );
    } else {
      blobUrl = URL.createObjectURL(response.data);
    }
    return blobUrl;
  });
};

export const viewSelectedDocument = async ({ documentUrl }) => {
  const blobUrl = await getDocumentBlob({ operationType: 'view', documentUrl });
  window.open(blobUrl);
};

export const downloadSelectedDocument = async ({
  documentUrl,
  documentName,
}) => {
  const blobUrl = await getDocumentBlob({
    operationType: 'download',
    documentUrl,
  });
  const a = document.createElement('a');
  a.href = blobUrl;
  a.download = documentName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
