import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableFiltered from '../../shared/table/TableFiltered';
import SelectColumnFilter from '../../shared/table/SelectColumnFilter';
import DispatchDeleteItemModal from '../../shared/modals/DispatchDeleteItemModal';
import { isItemLinked } from '../../../utils/linking';
import { convertDate } from '../../../utils/convert';
import { getFileType } from '../../../utils/photos';
import {
  viewSelectedDocument,
  downloadSelectedDocument,
} from '../../../utils/documents';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchDocumentsRefresh,
  deleteDocument,
} from '../../../store/actions/documentsActions';

const SelectDocument = () => {
  const { auditId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { apiData, loading, isOnline } = useSelector((state) => ({
    apiData: state.documents.documents,
    loading: state.documents.loading,
    isOnline: state.offline.online,
  }));
  const [modalShow, setModalShow] = useState(false);
  const [selectedFlatRows, setSelectedFlatRows] = useState([]);

  useEffect(() => {
    if (isOnline) {
      dispatch(fetchDocumentsRefresh(auditId));
    }
    // eslint-disable-next-line
  }, []);

  const showTableFilters = true;

  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
        disableFilters: true,
      },
      {
        Header: 'File type',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          return `.${getFileType(original.document_id)}`;
        },
      },
      {
        Header: 'Category',
        accessor: 'category',
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'Valid from',
        accessor: 'valid_from',
        disableFilters: true,
        Cell: ({ value }) => {
          return convertDate(value);
        },
      },
      {
        Header: 'Valid to',
        accessor: 'valid_to',
        disableFilters: true,
        Cell: ({ value }) => {
          return convertDate(value);
        },
      },
      {
        Header: 'Country',
        accessor: 'country',
        disableFilters: true,
      },
      {
        Header: 'Permanent',
        accessor: 'permanent',
        disableFilters: true,
        Cell: ({ value }) => {
          return value ? 'Yes' : 'No';
        },
      },
    ],
    []
  );

  const data = React.useMemo(
    // Select documents for current audit and permanent ones (audit_id = 0)
    () =>
      apiData.filter(
        (document) =>
          document.audit_id === parseInt(auditId) || document.audit_id === 0
      ),
    [apiData, auditId]
  );

  const handleChangeSelection = useCallback((row) => {
    setSelectedFlatRows(row);
  }, []);

  const editSelectedDocument = () => {
    history.push(`/audit/${auditId}/documents/edit`, {
      documentId: selectedFlatRows[0].original.document_id,
      originalAuditId: selectedFlatRows[0].original.audit_id,
    });
  };

  return (
    <Fragment>
      {loading && (
        <Row>
          <Col>
            <div className='text-center mb-3'>
              <FontAwesomeIcon icon='circle-notch' spin size='lg' />
            </div>
          </Col>
        </Row>
      )}
      {!isOnline && (
        <Alert variant='info'>
          In offline mode documents functionalities are limited - you can only
          delete selected documents.
        </Alert>
      )}
      <Row>
        <TableFiltered
          data={data}
          columns={columns}
          onChangeSelection={handleChangeSelection}
          showFilters={showTableFilters}
        />
      </Row>
      <Row>
        <Col sm={showTableFilters ? { offset: 3, span: 9 } : 12}>
          {!loading && apiData.length > 0 && (
            <div className='text-right'>
              <Button
                variant='danger'
                className='mr-2'
                disabled={selectedFlatRows.length !== 1}
                onClick={() => setModalShow(true)}
              >
                Delete
              </Button>
              <Button
                variant='primary'
                className='mr-2'
                disabled={!isOnline || selectedFlatRows.length !== 1}
                onClick={() => editSelectedDocument()}
              >
                Edit
              </Button>
              <Button
                variant='primary'
                className='mr-2'
                disabled={
                  !isOnline ||
                  selectedFlatRows.length !== 1 ||
                  (selectedFlatRows.length > 0 &&
                    getFileType(
                      selectedFlatRows[0].original.document_id
                    ).toLowerCase() !== 'pdf')
                }
                onClick={() =>
                  viewSelectedDocument({
                    documentUrl: selectedFlatRows[0].original.doc_url,
                  })
                }
              >
                View <small>(only PDF)</small>
              </Button>
              <Button
                variant='primary'
                className=''
                disabled={!isOnline || selectedFlatRows.length !== 1}
                onClick={() =>
                  downloadSelectedDocument({
                    documentUrl: selectedFlatRows[0].original.doc_url,
                    documentName:
                      selectedFlatRows[0].original.document_id.split('#')[1],
                  })
                }
              >
                Download
              </Button>
            </div>
          )}
        </Col>
      </Row>
      <DispatchDeleteItemModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        selectedItemId={
          selectedFlatRows.length > 0
            ? selectedFlatRows[0].original.document_id
            : ''
        }
        isLinked={
          selectedFlatRows.length > 0
            ? isItemLinked([selectedFlatRows[0].original])
            : false
        }
        deleteAction={deleteDocument}
        auditId={auditId}
        selectedElementAuditId={
          selectedFlatRows.length > 0
            ? selectedFlatRows[0].original.audit_id
            : ''
        }
      />
    </Fragment>
  );
};

export default SelectDocument;
