import defaultQueue from '@redux-offline/redux-offline/lib/defaults/queue';
import { toUrlId, toLinkId } from '../utils/convertId';
import { replaceInArray, containsTempIds } from '../utils/linking';
import {
  UPDATE_OBSERVATION,
  CREATE_OBSERVATION,
  CREATE_OBSERVATION_COMMIT,
  DELETE_OBSERVATION,
} from './types/observationsTypes';
import { UPDATE_MPA, CREATE_MPA, DELETE_MPA } from './types/mpasTypes';
import {
  UPDATE_INTERVIEW_ANSWER,
  CREATE_INTERVIEW,
  CREATE_INTERVIEW_COMMIT,
  DELETE_INTERVIEW,
} from './types/interviewsTypes';
import {
  UPDATE_PHOTO_META,
  GET_PRESIGNED_URL,
  DELETE_PHOTO,
  DELETE_PHOTOS_ARRAY,
} from './types/photosTypes';
import {
  CREATE_GP,
  CREATE_GP_COMMIT,
  UPDATE_GP,
  DELETE_GP,
} from './types/gpsTypes';
import { UPDATE_AUDIT } from './types/auditsTypes';

export default {
  ...defaultQueue,
  enqueue(outbox, incomingAction, context) {
    switch (incomingAction.type) {
      case UPDATE_OBSERVATION: {
        // Check if outbox contains create or update observation action with same entity_id
        const existingCreateActionInOutboxIndex = outbox.findIndex(
          (e) =>
            e.type === CREATE_OBSERVATION &&
            e.payload.entity_id === incomingAction.payload.entity_id
        );

        const existingUpdateActionInOutboxIndex = outbox.findIndex(
          (e) =>
            e.type === UPDATE_OBSERVATION &&
            e.payload.entity_id === incomingAction.payload.entity_id
        );

        if (
          existingCreateActionInOutboxIndex > -1 &&
          existingUpdateActionInOutboxIndex === -1
        ) {
          // Case - outbox contains only CREATE action, no UPDATE action
          if (containsTempIds(incomingAction.payload.data.link_questions)) {
            // If incoming action payload contains linked questions with temp ids
            // add action to outbox queue
            return [...outbox, incomingAction];
          } else {
            // Else - merge incoming UPDATE action into existing CREATE action
            return outbox.map((e, index) => {
              if (index === existingCreateActionInOutboxIndex) {
                const updatedAction = {
                  ...e,
                  meta: {
                    ...e.meta,
                    offline: {
                      ...e.meta.offline,
                      effect: {
                        ...e.meta.offline.effect,
                        data: {
                          ...e.meta.offline.effect.data,
                          ...incomingAction.payload.data,
                        },
                      },
                    },
                  },
                };
                return updatedAction;
              } else {
                return e;
              }
            });
          }
        } else if (existingUpdateActionInOutboxIndex > -1) {
          // Case - outbox contains UPDATE action
          // Merge incoming UPDATE action into existing UPDATE action
          return outbox.map((e, index) => {
            if (index === existingUpdateActionInOutboxIndex) {
              const updatedAction = {
                ...e,
                meta: {
                  ...e.meta,
                  offline: {
                    ...e.meta.offline,
                    effect: {
                      ...e.meta.offline.effect,
                      data: {
                        ...e.meta.offline.effect.data,
                        ...incomingAction.payload.data,
                      },
                    },
                  },
                },
              };
              return updatedAction;
            } else {
              return e;
            }
          });
        } else {
          // Default case:
          return [...outbox, incomingAction];
        }
      }
      case DELETE_OBSERVATION: {
        // Check if outbox contains CREATE_OBSERVATION action with same entity_id
        const existingCreateActionInOutboxIndex = outbox.findIndex(
          (e) =>
            e.type === CREATE_OBSERVATION &&
            e.payload.entity_id === incomingAction.payload.entity_id
        );
        if (existingCreateActionInOutboxIndex > -1) {
          // Case - observation created offline
          // Remove all queued observation actions (CREATE or UPDATE) with same entity_id
          return outbox.filter(
            (e) => e.payload.entity_id !== incomingAction.payload.entity_id
          );
        }

        // Check if outbox contains UPDATE_OBSERVATION action with same entity_id
        const existingUpdateActionInOutboxIndex = outbox.findIndex(
          (e) =>
            e.type === UPDATE_OBSERVATION &&
            e.payload.entity_id === incomingAction.payload.entity_id
        );
        if (existingUpdateActionInOutboxIndex > -1) {
          // Case - observation updated offline
          // Replace UPDATE action with DELETE
          return outbox.map((e, index) => {
            if (index === existingUpdateActionInOutboxIndex) {
              return incomingAction;
            } else {
              return e;
            }
          });
        }

        // Default case
        return [...outbox, incomingAction];
      }
      case UPDATE_INTERVIEW_ANSWER: {
        // Check if outbox contains update interview answer action with same entity_id and question_id
        const existingActionInOutboxIndex = outbox.findIndex(
          (e) =>
            e.type === UPDATE_INTERVIEW_ANSWER &&
            e.payload.entity_id === incomingAction.payload.entity_id &&
            e.payload.question_id === incomingAction.payload.question_id
        );
        if (existingActionInOutboxIndex > -1) {
          // Merge incoming UPDATE action into existing UPDATE action
          return outbox.map((e, index) => {
            if (index === existingActionInOutboxIndex) {
              const updatedAction = {
                ...e,
                meta: {
                  ...e.meta,
                  offline: {
                    ...e.meta.offline,
                    effect: {
                      ...e.meta.offline.effect,
                      data: {
                        ...e.meta.offline.effect.data,
                        ...incomingAction.payload.data,
                      },
                    },
                  },
                },
              };
              return updatedAction;
            } else {
              return e;
            }
          });
        } else {
          return [...outbox, incomingAction];
        }
      }
      case DELETE_INTERVIEW: {
        // Check if outbox contains CREATE_INTERVIEW action with same entity_id
        const existingCreateActionInOutboxIndex = outbox.findIndex(
          (e) =>
            e.type === CREATE_INTERVIEW &&
            e.payload.entity_id === incomingAction.payload.entity_id
        );
        if (existingCreateActionInOutboxIndex > -1) {
          // Case - interview created offline
          // Remove all queued interview actions (CREATE or UPDATE) with same entity_id
          return outbox.filter(
            (e) => e.payload.entity_id !== incomingAction.payload.entity_id
          );
        }

        // Check if outbox contains UPDATE_INTERVIEW_ANSWER action with same entity_id
        const existingUpdateActionInOutboxIndex = outbox.findIndex(
          (e) =>
            e.type === UPDATE_INTERVIEW_ANSWER &&
            e.payload.entity_id === incomingAction.payload.entity_id
        );
        if (existingUpdateActionInOutboxIndex > -1) {
          // Case - interview updated offline
          // Replace UPDATE action with DELETE
          return outbox.map((e, index) => {
            if (index === existingUpdateActionInOutboxIndex) {
              return incomingAction;
            } else {
              return e;
            }
          });
        }

        // Default case
        return [...outbox, incomingAction];
      }
      case UPDATE_AUDIT: {
        // Check if outbox contains UPDATE actions for audit with same audit_id
        const existingActionInOutboxIndex = outbox.findIndex(
          (e) =>
            e.type === UPDATE_AUDIT &&
            e.payload.audit_id === incomingAction.payload.audit_id
        );
        if (existingActionInOutboxIndex > -1) {
          // Merge incoming UPDATE action into existing UPDATE action
          return outbox.map((e, index) => {
            if (index === existingActionInOutboxIndex) {
              const updatedAction = {
                ...e,
                meta: {
                  ...e.meta,
                  offline: {
                    ...e.meta.offline,
                    effect: {
                      ...e.meta.offline.effect,
                      data: {
                        ...e.meta.offline.effect.data,
                        ...incomingAction.payload.data,
                      },
                    },
                  },
                },
              };
              return updatedAction;
            } else {
              return e;
            }
          });
        } else {
          return [...outbox, incomingAction];
        }
      }
      case UPDATE_PHOTO_META: {
        // Check if outbox contains GET_PRESIGNED_URL action with same photo_id
        const existingActionInOutboxIndex = outbox.findIndex(
          (e) =>
            e.type === GET_PRESIGNED_URL &&
            e.payload.photo_id === incomingAction.payload.photo_id
        );
        if (existingActionInOutboxIndex > -1) {
          // Merge incoming UPDATE_PHOTO_META action into existing GET_PRESIGNED_URL meta data
          return outbox.map((e, index) => {
            if (index === existingActionInOutboxIndex) {
              const updatedAction = {
                ...e,
                meta: {
                  ...e.meta,
                  offline: {
                    ...e.meta.offline,
                    effect: {
                      ...e.meta.offline.effect,
                      url: e.meta.offline.effect.url.replace(
                        e.meta.offline.effect.url.substring(
                          e.meta.offline.effect.url.lastIndexOf('/') + 1
                        ),
                        incomingAction.payload.imageData.category
                      ),
                    },
                    commit: {
                      ...e.meta.offline.commit,
                      meta: {
                        ...e.meta.offline.commit.meta,
                        photoObj: {
                          ...e.meta.offline.commit.meta.photoObj,
                          ...incomingAction.payload.imageData,
                        },
                      },
                    },
                  },
                },
              };
              return updatedAction;
            } else {
              return e;
            }
          });
        }
        // Check if outbox contains UPDATE_PHOTO_META action with same photo_id
        const existingUpdateActionInOutboxIndex = outbox.findIndex(
          (e) =>
            e.type === UPDATE_PHOTO_META &&
            e.payload.photo_id === incomingAction.payload.photo_id
        );
        if (existingUpdateActionInOutboxIndex > -1) {
          // Merge incoming UPDATE_PHOTO_META action into existing UPDATE_PHOTO_META action
          return outbox.map((e, index) => {
            if (index === existingUpdateActionInOutboxIndex) {
              const updatedAction = {
                ...e,
                meta: {
                  ...e.meta,
                  offline: {
                    ...e.meta.offline,
                    effect: {
                      ...e.meta.offline.effect,
                      data: {
                        ...e.meta.offline.effect.data,
                        ...incomingAction.payload.imageData,
                      },
                    },
                  },
                },
              };
              return updatedAction;
            } else {
              return e;
            }
          });
        }
        return [...outbox, incomingAction];
      }
      case GET_PRESIGNED_URL: {
        // Check if outbox contains GET_PRESIGNED_URL action with same photo_id
        const existingActionInOutboxIndex = outbox.findIndex(
          (e) =>
            e.type === GET_PRESIGNED_URL &&
            e.payload.photo_id === incomingAction.payload.photo_id
        );
        if (existingActionInOutboxIndex > -1) {
          // Drop incoming GET_PRESIGNED_URL action as the edited file is taken from localForage
          // and all photo metadata updates are merged to initial GET_PRESIGNED_URL action
          return outbox;
        }
        return [...outbox, incomingAction];
      }
      case DELETE_PHOTO: {
        // Check if outbox contains actions (GET_PRESIGNED_URL or UPDATE_PHOTO_META) for photo with same photo_id
        const existingActionInOutboxIndex = outbox.findIndex(
          (e) => e.payload.photo_id === incomingAction.payload.photo_id
        );
        if (existingActionInOutboxIndex > -1) {
          switch (outbox[existingActionInOutboxIndex].type) {
            case GET_PRESIGNED_URL:
              // Using 'blured_automatically' field to check if photo was already synced
              if (
                outbox[existingActionInOutboxIndex].meta.offline.commit.meta
                  .photoObj.blured_automatically
              ) {
                // Photo was already synced - replace GET_PRESIGNED_URL with DELETE_PHOTO action
                return outbox.map((e, index) => {
                  if (index === existingActionInOutboxIndex) {
                    return incomingAction;
                  } else {
                    return e;
                  }
                });
              } else {
                // Photo wasn't synced yet - remove GET_PRESIGNED_URL action from outbox.
                return outbox.filter(
                  (e, index) => index !== existingActionInOutboxIndex
                );
              }
            case UPDATE_PHOTO_META:
              // Replace UPDATE action with DELETE.
              return outbox.map((e, index) => {
                if (index === existingActionInOutboxIndex) {
                  return incomingAction;
                } else {
                  return e;
                }
              });
            default:
              return [...outbox, incomingAction];
          }
        } else {
          return [...outbox, incomingAction];
        }
      }
      case DELETE_PHOTOS_ARRAY: {
        // Filter out UPDATE_PHOTO_META actions with ids from photoIdsList
        let updatedOutbox = outbox.filter((outboxAction) => {
          return !(
            outboxAction.type === UPDATE_PHOTO_META &&
            incomingAction.payload.photoIdsList.indexOf(
              outboxAction.payload.photo_id
            ) > -1
          );
        });

        // Check if outbox contains GET_PRESIGNED_URL actions for unsynced photos (!blured_automatically)
        const existingUnsyncedGetPresignedActionsIds = updatedOutbox
          .filter((outboxAction) => {
            return (
              outboxAction.type === GET_PRESIGNED_URL &&
              incomingAction.payload.photoIdsList.indexOf(
                outboxAction.payload.photo_id
              ) > -1 &&
              !outboxAction.meta.offline.commit.meta.photoObj
                .blured_automatically
            );
          })
          .map((outboxAction) => outboxAction.payload.photo_id);

        // Filter out GET_PRESIGNED_URL actions with ids from photoIdsList
        updatedOutbox = updatedOutbox.filter((outboxAction) => {
          return !(
            outboxAction.type === GET_PRESIGNED_URL &&
            incomingAction.payload.photoIdsList.indexOf(
              outboxAction.payload.photo_id
            ) > -1
          );
        });

        let updatedIncomingAction = { ...incomingAction };
        // Filter out unsynced photo ids from delete request array of ids
        const updatedRequestIds =
          updatedIncomingAction.meta.offline.effect.data.photos_list.filter(
            (id) => {
              return !(existingUnsyncedGetPresignedActionsIds.indexOf(id) > -1);
            }
          );
        if (updatedRequestIds.length) {
          updatedIncomingAction.meta.offline.effect.data.photos_list =
            updatedRequestIds;
          return [...updatedOutbox, updatedIncomingAction];
        } else {
          return updatedOutbox;
        }
      }
      case UPDATE_MPA: {
        // Check if outbox contains create or update MPA action with same mpa_id
        const existingActionInOutboxIndex = outbox.findIndex(
          (e) =>
            (e.type === CREATE_MPA || e.type === UPDATE_MPA) &&
            e.payload.mpa_id === incomingAction.payload.mpa_id
        );
        if (existingActionInOutboxIndex > -1) {
          // Merge incoming UPDATE action into existing CREATE or UPDATE action
          return outbox.map((e, index) => {
            if (index === existingActionInOutboxIndex) {
              const updatedAction = {
                ...e,
                meta: {
                  ...e.meta,
                  offline: {
                    ...e.meta.offline,
                    effect: {
                      ...e.meta.offline.effect,
                      data: {
                        ...e.meta.offline.effect.data,
                        ...incomingAction.payload.data,
                      },
                    },
                  },
                },
              };
              return updatedAction;
            } else {
              return e;
            }
          });
        } else {
          return [...outbox, incomingAction];
        }
      }
      case DELETE_MPA: {
        // Check if outbox contains action (CREATE or UPDATE) for MPA with same mpa_id
        const existingActionInOutboxIndex = outbox.findIndex(
          (e) => e.payload.mpa_id === incomingAction.payload.mpa_id
        );
        if (existingActionInOutboxIndex > -1) {
          switch (outbox[existingActionInOutboxIndex].type) {
            case CREATE_MPA:
              // Remove CREATE action from outbox.
              return outbox.filter(
                (e, index) => index !== existingActionInOutboxIndex
              );
            case UPDATE_MPA:
              // Replace UPDATE action with DELETE.
              return outbox.map((e, index) => {
                if (index === existingActionInOutboxIndex) {
                  return incomingAction;
                } else {
                  return e;
                }
              });
            default:
              return [...outbox, incomingAction];
          }
        } else {
          return [...outbox, incomingAction];
        }
      }
      case UPDATE_GP: {
        // Check if outbox contains create or update good practice action with same entity_id
        const existingCreateActionInOutboxIndex = outbox.findIndex(
          (e) =>
            e.type === CREATE_GP &&
            e.payload.entity_id === incomingAction.payload.entity_id
        );

        const existingUpdateActionInOutboxIndex = outbox.findIndex(
          (e) =>
            e.type === UPDATE_GP &&
            e.payload.entity_id === incomingAction.payload.entity_id
        );

        if (
          existingCreateActionInOutboxIndex > -1 &&
          existingUpdateActionInOutboxIndex === -1
        ) {
          // Case - outbox contains only CREATE action, no UPDATE action
          if (containsTempIds(incomingAction.payload.data.link_questions)) {
            // If incoming action payload contains linked questions with temp ids
            // add action to outbox queue
            return [...outbox, incomingAction];
          } else {
            // Else - merge incoming UPDATE action into existing CREATE action
            return outbox.map((e, index) => {
              if (index === existingCreateActionInOutboxIndex) {
                const updatedAction = {
                  ...e,
                  meta: {
                    ...e.meta,
                    offline: {
                      ...e.meta.offline,
                      effect: {
                        ...e.meta.offline.effect,
                        data: {
                          ...e.meta.offline.effect.data,
                          ...incomingAction.payload.data,
                        },
                      },
                    },
                  },
                };
                return updatedAction;
              } else {
                return e;
              }
            });
          }
        } else if (existingUpdateActionInOutboxIndex > -1) {
          // Case - outbox contains UPDATE action
          // Merge incoming UPDATE action into existing UPDATE action
          return outbox.map((e, index) => {
            if (index === existingUpdateActionInOutboxIndex) {
              const updatedAction = {
                ...e,
                meta: {
                  ...e.meta,
                  offline: {
                    ...e.meta.offline,
                    effect: {
                      ...e.meta.offline.effect,
                      data: {
                        ...e.meta.offline.effect.data,
                        ...incomingAction.payload.data,
                      },
                    },
                  },
                },
              };
              return updatedAction;
            } else {
              return e;
            }
          });
        } else {
          // Default case:
          return [...outbox, incomingAction];
        }
      }
      case DELETE_GP: {
        // Check if outbox contains CREATE_GP action with same entity_id
        const existingCreateActionInOutboxIndex = outbox.findIndex(
          (e) =>
            e.type === CREATE_GP &&
            e.payload.entity_id === incomingAction.payload.entity_id
        );
        if (existingCreateActionInOutboxIndex > -1) {
          // Case - good practice created offline
          // Remove all queued good practice actions (CREATE or UPDATE) with same entity_id
          return outbox.filter(
            (e) => e.payload.entity_id !== incomingAction.payload.entity_id
          );
        }

        // Check if outbox contains UPDATE_GP action with same entity_id
        const existingUpdateActionInOutboxIndex = outbox.findIndex(
          (e) =>
            e.type === UPDATE_GP &&
            e.payload.entity_id === incomingAction.payload.entity_id
        );
        if (existingUpdateActionInOutboxIndex > -1) {
          // Case - observation updated offline
          // Replace UPDATE action with DELETE
          return outbox.map((e, index) => {
            if (index === existingUpdateActionInOutboxIndex) {
              return incomingAction;
            } else {
              return e;
            }
          });
        }
        // Default case
        return [...outbox, incomingAction];
      }
      default:
        return [...outbox, incomingAction];
    }
  },
  dequeue(outbox, departingAction) {
    switch (departingAction.type) {
      case CREATE_INTERVIEW_COMMIT: {
        // Check if outbox contains update interview actions with same entity_id that was just synced
        const existingUpdateInterviewActionsIndexes = outbox
          .map((e, index) => index)
          .filter((i) => {
            return (
              outbox[i].type === UPDATE_INTERVIEW_ANSWER &&
              outbox[i].payload.entity_id ===
                departingAction.meta.temp_entity_id
            );
          });
        // Replace temp ids with backend synced one
        const updatedOutbox = outbox.map((e, index) => {
          if (existingUpdateInterviewActionsIndexes.indexOf(index) > -1) {
            const updatedAction = {
              ...e,
              meta: {
                ...e.meta,
                offline: {
                  ...e.meta.offline,
                  effect: {
                    ...e.meta.offline.effect,
                    url: e.meta.offline.effect.url.replace(
                      toUrlId(departingAction.meta.temp_entity_id),
                      toUrlId(departingAction.payload.data.entity_id)
                    ),
                  },
                },
              },
            };
            return updatedAction;
          } else {
            return e;
          }
        });
        const [, ...rest] = updatedOutbox;

        // Replace question link id in outbox
        const tempLinkId = toLinkId(departingAction.meta.temp_entity_id);
        const newLinkId = toLinkId(departingAction.payload.data.entity_id);
        const updatedRest = rest.map((action) => {
          const linkQuestions =
            action.meta.offline.effect?.data?.link_questions;
          if (linkQuestions) {
            return {
              ...action,
              meta: {
                ...action.meta,
                offline: {
                  ...action.meta.offline,
                  effect: {
                    ...action.meta.offline.effect,
                    data: {
                      ...action.meta.offline.effect.data,
                      link_questions: replaceInArray({
                        array: action.meta.offline.effect.data.link_questions,
                        find: tempLinkId,
                        replace: newLinkId,
                      }),
                    },
                  },
                },
              },
            };
          } else {
            return action;
          }
        });
        return updatedRest;
      }
      case CREATE_OBSERVATION_COMMIT: {
        // Replace observation link id in outbox
        const tempLinkId = toLinkId(departingAction.meta.temp_entity_id);
        const newLinkId = toLinkId(departingAction.payload.data.entity_id);
        const [, ...rest] = outbox;
        const updatedRest = rest.map((action) => {
          const linkObservations =
            action.meta.offline.effect?.data?.link_observations;
          if (linkObservations) {
            return {
              ...action,
              meta: {
                ...action.meta,
                offline: {
                  ...action.meta.offline,
                  effect: {
                    ...action.meta.offline.effect,
                    data: {
                      ...action.meta.offline.effect.data,
                      link_observations: replaceInArray({
                        array:
                          action.meta.offline.effect.data.link_observations,
                        find: tempLinkId,
                        replace: newLinkId,
                      }),
                    },
                  },
                },
              },
            };
          } else if (
            action.type === UPDATE_OBSERVATION &&
            action.payload.entity_id === departingAction.meta.temp_entity_id
          ) {
            return {
              ...action,
              meta: {
                ...action.meta,
                offline: {
                  ...action.meta.offline,
                  effect: {
                    ...action.meta.offline.effect,
                    url: action.meta.offline.effect.url.replace(
                      toUrlId(departingAction.meta.temp_entity_id),
                      toUrlId(departingAction.payload.data.entity_id)
                    ),
                  },
                },
              },
            };
          } else {
            return action;
          }
        });
        return updatedRest;
      }
      case CREATE_GP_COMMIT: {
        // Check if outbox contains update good practice actions with same entity_id that was just synced
        const existingUpdateActionsIndexes = outbox
          .map((e, index) => index)
          .filter((i) => {
            return (
              outbox[i].type === UPDATE_GP &&
              outbox[i].payload.entity_id ===
                departingAction.meta.temp_entity_id
            );
          });

        if (existingUpdateActionsIndexes.length > 0) {
          // Replace temp ids with backend synced one
          const updatedOutbox = outbox.map((e, index) => {
            if (existingUpdateActionsIndexes.indexOf(index) > -1) {
              const updatedAction = {
                ...e,
                meta: {
                  ...e.meta,
                  offline: {
                    ...e.meta.offline,
                    effect: {
                      ...e.meta.offline.effect,
                      url: e.meta.offline.effect.url.replace(
                        toUrlId(departingAction.meta.temp_entity_id),
                        toUrlId(departingAction.payload.data.entity_id)
                      ),
                    },
                  },
                },
              };
              return updatedAction;
            } else {
              return e;
            }
          });
          const [, ...rest] = updatedOutbox;
          return rest;
        } else {
          const [, ...rest] = outbox;
          return rest;
        }
      }
      default: {
        const [, ...rest] = outbox;
        return rest;
      }
    }
  },
};
