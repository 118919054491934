import React, { Fragment, useState, useEffect, useCallback } from 'react';
import axiosInst from '../../http/axiosConfig';
import { deleteInterviewQuestionById } from '../../http/requests';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import DeleteItemModal from '../shared/modals/DeleteItemModal';
import EditQuestionModal from './EditQuestionModal';
import AddQuestionModal from './AddQuestionModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectColumnFilter from '../shared/table/SelectColumnFilter';
import TableFiltered from '../shared/table/TableFiltered';
import {
  sortInterviewQuestions,
  sortInterviewCategories,
} from '../../utils/sorting';
import { useDispatch } from 'react-redux';
import {
  fetchCategories,
  fetchQuestions,
} from '../../store/actions/questionsActions';
import {
  fetchInterviewQuestionCategories,
  fetchInterviewQuestions,
} from '../../http/requests';

const QuestionsTable = () => {
  const [apiData, setApiData] = useState([]);
  const [questionCategories, setQuestionCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [addModalShow, setAddModalShow] = useState(false);
  const [selectedFlatRows, setSelectedFlatRows] = useState([]);
  const dispatch = useDispatch();

  const fetchQuestionList = () => {
    setLoading(true);
    axiosInst(fetchInterviewQuestions())
      .then((response) => {
        setApiData(response.data.sort(sortInterviewQuestions));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchQuestionCategories = () => {
    axiosInst(fetchInterviewQuestionCategories())
      .then((response) => {
        setQuestionCategories(response.data.sort(sortInterviewCategories));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchQuestionList();
    fetchQuestionCategories();

    // Trigger function when user leaves the page
    return () => {
      // Dispatch fetch actions on page leave to get updated data into redux store
      dispatch(fetchCategories());
      dispatch(fetchQuestions());
    };
    // eslint-disable-next-line
  }, []);

  const showTableFilters = true;

  const columns = React.useMemo(
    () => [
      {
        Header: 'Category',
        accessor: 'question_category',
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        Header: 'Question identifier',
        accessor: 'question_id',
        disableFilters: true,
      },
    ],
    []
  );

  const data = React.useMemo(() => apiData, [apiData]);

  const handleRemoveFromTable = (questionId) => {
    const newTableData = apiData.filter(
      (item) => item.question_id !== questionId
    );
    setApiData(newTableData);
  };

  const handleUpdateQuestion = (questionObj, initialCategoryName) => {
    const updatedQuestions = apiData.map((item) => {
      if (item.question_id === questionObj.question_id) {
        return {
          ...item,
          ...questionObj,
        };
      }
      return item;
    });

    if (initialCategoryName !== questionObj.question_category) {
      // If category name was changed, it should also be updated
      // in other questions from that category
      const updatedCategoryName = updatedQuestions.map((item) => {
        if (item.question_category === initialCategoryName) {
          return {
            ...item,
            question_category: questionObj.question_category,
          };
        }
        return item;
      });
      setApiData(updatedCategoryName);
      return;
    }
    setApiData(updatedQuestions);
  };

  const handleAddQuestion = (newQuestion) => {
    const updatedQuestions = apiData.map((item) => item);
    updatedQuestions.push(newQuestion);
    updatedQuestions.sort(sortInterviewQuestions);
    setApiData(updatedQuestions);
  };

  const handleAddCategory = (newCategory) => {
    const updatedCategories = questionCategories.map((item) => item);
    updatedCategories.push(newCategory);
    updatedCategories.sort(sortInterviewCategories);
    setQuestionCategories(updatedCategories);
  };

  const handleChangeSelection = useCallback((row) => {
    setSelectedFlatRows(row);
  }, []);

  // TODO przypadek usuwania ostatniego pytania z kategorii - usuwanie kategorii

  return (
    <Fragment>
      <Row>
        <TableFiltered
          data={data}
          columns={columns}
          onChangeSelection={handleChangeSelection}
          showFilters={showTableFilters}
        />
      </Row>
      <Row>
        <Col sm={showTableFilters ? { offset: 3, span: 9 } : 12}>
          {loading && (
            <div className='text-center'>
              <FontAwesomeIcon icon='circle-notch' spin size='lg' />
            </div>
          )}
          {!loading && apiData.length > 0 && (
            <div className='text-right'>
              <Button
                variant='danger'
                className='mr-3'
                disabled={selectedFlatRows.length !== 1}
                onClick={() => setDeleteModalShow(true)}
              >
                Delete
              </Button>
              <Button
                variant='primary'
                className='mr-3'
                disabled={selectedFlatRows.length !== 1}
                onClick={() => setEditModalShow(true)}
              >
                Edit
              </Button>
              <Button
                variant='primary'
                disabled={questionCategories.length === 0}
                onClick={() => setAddModalShow(true)}
              >
                Add new
              </Button>
            </div>
          )}
        </Col>
      </Row>

      <DeleteItemModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        selectedItemId={
          selectedFlatRows.length > 0
            ? selectedFlatRows[0].original.question_id
            : ''
        }
        handleRemoveFromTable={handleRemoveFromTable}
        apiDeleteMethod={deleteInterviewQuestionById}
        auditId={null}
      />

      {questionCategories.length > 0 && (
        <EditQuestionModal
          show={editModalShow}
          onHide={() => setEditModalShow(false)}
          questionCategories={questionCategories}
          selectedQuestion={
            selectedFlatRows.length > 0
              ? apiData.filter(
                  (item) =>
                    item.question_id ===
                    selectedFlatRows[0].original.question_id
                )[0]
              : undefined
          }
          handleUpdateQuestion={handleUpdateQuestion}
        />
      )}
      {questionCategories.length > 0 && (
        <AddQuestionModal
          show={addModalShow}
          onHide={() => setAddModalShow(false)}
          questionCategories={questionCategories}
          handleAddQuestion={handleAddQuestion}
          handleAddCategory={handleAddCategory}
        />
      )}
    </Fragment>
  );
};

export default QuestionsTable;
