import {
  SET_LOADING,
  FETCH_OBSERVATIONS_INIT,
  FETCH_OBSERVATIONS_REFRESH_COMMIT,
  FETCH_OBSERVATIONS_REFRESH_ROLLBACK,
  FETCH_OBSERVATION_DETAILS_INIT,
  FETCH_OBSERVATION_DETAILS_REFRESH,
  FETCH_OBSERVATION_DETAILS_REFRESH_COMMIT,
  FETCH_OBSERVATION_DETAILS_REFRESH_ROLLBACK,
  UPDATE_OBSERVATION,
  UPDATE_OBSERVATION_COMMIT,
  CREATE_OBSERVATION,
  CREATE_OBSERVATION_COMMIT,
  DELETE_OBSERVATION,
} from '../types/observationsTypes';
import { sortByEntityId } from '../../utils/sorting';

const initialState = {
  observations: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case FETCH_OBSERVATIONS_INIT:
      return {
        ...state,
        observations: [
          ...state.observations,
          ...action.payload.map((observation) => {
            return {
              ...observation,
              link_questions: observation.link_questions || [],
              link_photos: observation.link_photos || [],
              link_documents: observation.link_documents || [],
              tags: observation.tags || [],
            };
          }),
        ].sort(sortByEntityId),
        loading: false,
      };
    case FETCH_OBSERVATIONS_REFRESH_COMMIT:
      const observationsFromOtherAudits = state.observations.filter(
        (observation) => observation.audit_id !== action.meta.audit_id
      );
      const updatedObservations = action.payload.data.map((observation) => {
        const existingObservation = state.observations.find(
          (obs) => obs.entity_id === observation.entity_id
        );
        if (existingObservation) {
          return {
            ...existingObservation,
            ...observation,
            link_questions: observation.link_questions || [],
            link_photos: observation.link_photos || [],
            link_documents: observation.link_documents || [],
            tags: observation.tags || [],
          };
        } else {
          return observation;
        }
      });
      return {
        ...state,
        observations: [
          ...observationsFromOtherAudits,
          ...updatedObservations,
        ].sort(sortByEntityId),
        loading: false,
      };
    case FETCH_OBSERVATIONS_REFRESH_ROLLBACK: {
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_OBSERVATION_DETAILS_INIT:
      return {
        ...state,
        observations: state.observations.map((observation) =>
          observation.entity_id === action.payload.entity_id
            ? {
                ...observation,
                ...action.payload,
                link_questions: action.payload.link_questions || [],
                link_photos: action.payload.link_photos || [],
                link_documents: action.payload.link_documents || [],
                tags: action.payload.tags || [],
              }
            : observation
        ),
      };
    case FETCH_OBSERVATION_DETAILS_REFRESH:
      return {
        ...state,
        observations: state.observations.map((observation) =>
          observation.entity_id === action.payload.entity_id
            ? {
                ...observation,
                loading: true,
              }
            : observation
        ),
      };
    case FETCH_OBSERVATION_DETAILS_REFRESH_COMMIT:
      return {
        ...state,
        observations: state.observations.map((observation) => {
          if (observation.entity_id === action.payload.entity_id) {
            const updatedObservation = {
              ...observation,
              ...action.payload.data,
              link_questions: action.payload.data.link_questions || [],
              link_photos: action.payload.data.link_photos || [],
              link_documents: action.payload.data.link_documents || [],
              tags: action.payload.data.tags || [],
            };
            delete updatedObservation.loading;
            return updatedObservation;
          } else {
            return observation;
          }
        }),
      };
    case FETCH_OBSERVATION_DETAILS_REFRESH_ROLLBACK:
      return {
        ...state,
        observations: state.observations.map((observation) => {
          if (observation.entity_id === action.payload.entity_id) {
            const { loading, ...updatedObservation } = observation;
            return updatedObservation;
          } else {
            return observation;
          }
        }),
      };
    case UPDATE_OBSERVATION:
      return {
        ...state,
        observations: state.observations.map((observation) =>
          observation.entity_id === action.payload.entity_id
            ? {
                ...observation,
                ...action.payload.data,
              }
            : observation
        ),
      };
    case UPDATE_OBSERVATION_COMMIT:
      return {
        ...state,
        observations: state.observations.map((observation) =>
          observation.entity_id === action.payload.data.entity_id
            ? {
                ...observation,
                ...action.payload.data,
              }
            : observation
        ),
      };
    case CREATE_OBSERVATION:
      return {
        ...state,
        observations: [...state.observations, action.payload.data].sort(
          sortByEntityId
        ),
      };
    case CREATE_OBSERVATION_COMMIT:
      return {
        ...state,
        observations: state.observations
          .map((observation) => {
            if (observation.entity_id === action.meta.temp_entity_id) {
              const { entity_id, ...obsData } = observation;
              return {
                ...obsData,
                ...action.payload.data,
                link_photos: obsData.link_photos,
                link_questions: obsData.link_questions,
                link_documents: obsData.link_documents,
              };
            } else {
              return observation;
            }
          })
          .sort(sortByEntityId),
      };
    case DELETE_OBSERVATION:
      return {
        ...state,
        observations: state.observations.filter(
          (observation) => observation.entity_id !== action.payload.entity_id
        ),
      };
    default:
      return state;
  }
};
