import React from 'react';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const InfoPersonInput = ({ person, personType, index }) => {
  return (
    <OverlayTrigger
      placement='top'
      overlay={
        <Tooltip id={`tooltip-${personType}-${index}`}>
          Function: {person.Function}
          <br />
          E-mail: {person['E-mail']}
          <br />
          GID: {person.GID}
        </Tooltip>
      }
    >
      <Form.Control
        readOnly
        type='text'
        className={index > 0 ? 'mt-2' : ''}
        value={`${person.FirstName} ${person.LastName}`}
        placeholder='-'
        size='sm'
      />
    </OverlayTrigger>
  );
};

export default InfoPersonInput;
