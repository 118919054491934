import React, { Fragment, useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import TableFiltered from '../table/TableFiltered';
import { useSelector } from 'react-redux';

const LinkObservations = ({
  linked,
  onAddLinkedObservation,
  onRemoveLinkedObservation,
}) => {
  const { auditId } = useParams();
  const { observations, auditObservations } = useSelector((state) => ({
    observations: state.observations.observations,
    auditObservations: state.observations.observations.filter(
      (observation) => observation.audit_id === parseInt(auditId)
    ),
  }));
  const [show, setShow] = useState(false);
  const [selectedFlatRows, setSelectedFlatRows] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'observation_type',
        disableFilters: true,
      },
      {
        Header: 'OS Aspect Category',
        accessor: 'aspect_category',
        disableFilters: true,
      },
      {
        Header: 'Title',
        accessor: 'title',
        disableFilters: true,
      },
    ],
    []
  );

  const handleAddLinkedObservation = () => {
    const linkedObs = selectedFlatRows[0].original.entity_id.substring(
      selectedFlatRows[0].original.entity_id.lastIndexOf('#'),
      selectedFlatRows[0].original.entity_id.length
    );
    // Check for duplicates
    if (!linked.some((el) => el === linkedObs)) {
      onAddLinkedObservation(linkedObs);
    }
  };

  const handleRemoveLinkedObservation = (linkedObs) => {
    onRemoveLinkedObservation(linkedObs);
  };

  const data = React.useMemo(
    () =>
      observations.filter(
        (observation) => observation.audit_id === parseInt(auditId)
      ),
    [observations, auditId]
  );

  const handleCloseModal = () => {
    setShow(false);
    // toggleAllRowsSelected(false);
  };

  const handleChangeSelection = useCallback((row) => {
    setSelectedFlatRows(row);
  }, []);

  return (
    <Fragment>
      <div>
        <h6 className='d-inline-block'>Linked observations:</h6>
        <FontAwesomeIcon
          icon='plus-circle'
          className='pointer ml-2 align-middle'
          onClick={() => setShow(true)}
        />
        {linked.length > 0 && (
          <div className='mb-2'>
            {linked.map((item, index) => {
              const obs = auditObservations.find(
                (el) => el.entity_id === `#OBSERVATION${item}`
              );
              if (obs) {
                return (
                  <Fragment key={index}>
                    {index > 0 && <span className='mx-2'>|</span>}
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id={`tooltip-${index}`}>
                          OS Aspect category: {obs.aspect_category}
                          <br />
                          Title: {obs.title}
                        </Tooltip>
                      }
                    >
                      <Link
                        to={`/audit/${auditId}/observations/${
                          item.split('#')[1]
                        }`}
                      >
                        <FontAwesomeIcon
                          icon='search'
                          size='lg'
                          color='#2387aa'
                        />
                      </Link>
                    </OverlayTrigger>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className='pointer ml-2 text-danger'
                      onClick={() => handleRemoveLinkedObservation(item)}
                    />
                  </Fragment>
                );
              } else return '';
            })}
          </div>
        )}
      </div>
      <Modal
        show={show}
        onHide={handleCloseModal}
        size='xl'
        aria-labelledby='observations-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title id='observations-modal'>Select observation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <TableFiltered
              data={data}
              columns={columns}
              onChangeSelection={handleChangeSelection}
              showFilters={false}
            />
          </Row>
          {auditObservations.length > 0 && (
            <Row>
              <Col>
                <div className='text-right'>
                  <Button
                    variant='primary'
                    disabled={selectedFlatRows.length !== 1}
                    onClick={handleAddLinkedObservation}
                  >
                    Link selected element
                  </Button>
                </div>
              </Col>
            </Row>
          )}
          <Row className='mt-2'>
            <Col>
              <h6>Linked observations:</h6>
              {linked.length > 0 &&
                linked.map((item, index) => {
                  const obs = auditObservations.find(
                    (el) => el.entity_id === `#OBSERVATION${item}`
                  );
                  if (obs) {
                    return (
                      <p key={index}>
                        <strong>OS Aspect category</strong>:{' '}
                        {obs.aspect_category} | <strong>Title</strong>:{' '}
                        {obs.title}
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className='pointer ml-2 text-danger'
                          onClick={() => handleRemoveLinkedObservation(item)}
                        />
                      </p>
                    );
                  } else return '';
                })}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

LinkObservations.defaultProps = {
  linked: [],
};

export default LinkObservations;
