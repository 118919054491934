import {
  SET_LOADING,
  FETCH_DOCUMENTS_INIT,
  FETCH_DOCUMENTS_REFRESH_COMMIT,
  FETCH_DOCUMENTS_REFRESH_ROLLBACK,
  DELETE_DOCUMENT,
  CREATE_DOCUMENT,
  UPDATE_DOCUMENT,
} from '../types/documentsTypes';
import { sortDocuments } from '../../utils/sorting';

const initialState = {
  documents: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case FETCH_DOCUMENTS_INIT:
      return {
        ...state,
        documents: [
          ...state.documents,
          ...action.payload.reduce((acc, doc) => {
            // Check for duplicates (permanent documents are fetched for all audits)
            if (
              !state.documents.find((d) => d.document_id === doc.document_id)
            ) {
              acc.push({
                ...doc,
                link_questions: doc.link_questions || [],
                link_photos: doc.link_photos || [],
                link_observations: doc.link_observations || [],
                tags: doc.tags || [],
              });
            }
            return acc;
          }, []),
        ].sort(sortDocuments),
      };
    case FETCH_DOCUMENTS_REFRESH_COMMIT:
      const documentsFromOtherAuditsAndNonPermanent = state.documents.filter(
        (document) =>
          document.audit_id !== action.meta.audit_id && document.audit_id !== 0
      );
      const updatedDocuments = action.payload.data.map((document) => {
        const existingDocument = state.documents.find(
          (doc) => doc.document_id === document.document_id
        );
        if (existingDocument) {
          return {
            ...existingDocument,
            ...document,
            link_questions: document.link_questions || [],
            link_photos: document.link_photos || [],
            link_observations: document.link_observations || [],
            tags: document.tags || [],
          };
        } else {
          return document;
        }
      });
      return {
        ...state,
        documents: [
          ...documentsFromOtherAuditsAndNonPermanent,
          ...updatedDocuments,
        ].sort(sortDocuments),
        loading: false,
      };
    case FETCH_DOCUMENTS_REFRESH_ROLLBACK: {
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_DOCUMENT:
      return {
        ...state,
        documents: state.documents.filter(
          (document) => document.document_id !== action.payload.document_id
        ),
      };
    case CREATE_DOCUMENT:
      return {
        ...state,
        documents: [...state.documents, action.payload.data].sort(
          sortDocuments
        ),
      };
    case UPDATE_DOCUMENT:
      return {
        ...state,
        documents: state.documents.map((document) =>
          document.document_id === action.payload.data.document_id
            ? {
                ...document,
                ...action.payload.data,
              }
            : document
        ),
      };
    default:
      return state;
  }
};
