export const SET_LOADING = 'gps/setLoading';
export const FETCH_GPS_INIT = 'fetchGpsInit';
export const FETCH_GPS_REFRESH_COMMIT = 'fetchGpsRefreshCommit';
export const FETCH_GPS_REFRESH_ROLLBACK = 'fetchGpsRefreshRollback';
export const UPDATE_GP = 'updateGp';
export const UPDATE_GP_COMMIT = 'updateGpCommit';
export const CREATE_GP = 'createGp';
export const CREATE_GP_COMMIT = 'createGpCommit';
export const DELETE_GP = 'deleteGp';
export const DELETE_GP_COMMIT = 'deleteGpCommit';
