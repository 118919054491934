import React from 'react';
import {
  GET_PRESIGNED_URL_COMMIT,
  DELETE_PHOTO,
  SET_LOADING,
} from '../types/photosTypes';
import * as localForage from 'localforage';
import axios from 'axios';
import axiosInst from '../../http/axiosConfig';
import { fetchSessionImages } from '../../http/requests';
import { updatePhotoMeta } from '../actions/photosActions';
import { getPhotoKey } from '../../utils/photos';
import { toast } from 'react-toastify';

export const photoUploadMiddleware = ({ getState, dispatch }) => {
  return function (next) {
    return async function (action) {
      if (action.type === GET_PRESIGNED_URL_COMMIT) {
        const presignedData = action.payload.data;
        const photoObj = action.meta.photoObj;
        const file = await localForage.getItem(getPhotoKey(photoObj));
        const formData = new FormData();
        Object.keys(presignedData.fields).forEach((key) => {
          formData.append(key, presignedData.fields[key]);
        });
        formData.append('file', file);
        const request = {
          method: 'POST',
          url: presignedData.url,
          data: formData,
        };
        const photoMetaFromStore = getState().photos.photos.find(
          (photo) => photo.photo_id === photoObj.photo_id
        );
        axios(request)
          .then(async (res) => {
            const sessionPhoto = await getSessionPhotos(photoObj);
            if (sessionPhoto) {
              const imageObj = {
                description: photoMetaFromStore.description
                  ? encodeURI(photoMetaFromStore.description)
                  : '',
                edited_manually: photoMetaFromStore.edited_manually || false,
                tags: photoMetaFromStore.tags || [],
                link_observations: photoMetaFromStore.link_observations || [],
                link_questions: photoMetaFromStore.link_questions || [],
                link_documents: photoMetaFromStore.link_documents || [],
                upload_file_name: photoMetaFromStore.upload_file_name,
                session_id: photoMetaFromStore.session_id,
                category: photoMetaFromStore.category,
                photo_url: sessionPhoto.photo_url,
                confirmed: true,
              };

              dispatch(
                updatePhotoMeta({
                  auditId: photoObj.audit_id,
                  photoId: photoObj.photo_id,
                  imageData: imageObj,
                })
              );
            } else {
              dispatch({
                type: DELETE_PHOTO,
                payload: {
                  photo_id: photoMetaFromStore.photo_id,
                },
              });
              // Toggle LOADING to force photo gallery refresh
              dispatch({
                type: SET_LOADING,
                payload: true,
              });
              await timer(500);
              dispatch({
                type: SET_LOADING,
                payload: false,
              });
              toast.error(
                <div>
                  Unable to synchronize{' '}
                  <strong>{photoMetaFromStore.upload_file_name}</strong>. Please
                  try to upload it again.
                </div>,
                { autoClose: false }
              );
            }
          })
          .catch((error) => {
            toast.error(
              <div>
                Unable to synchronize{' '}
                <strong>{photoMetaFromStore.upload_file_name}</strong>. Please
                try to upload it again.
              </div>,
              { autoClose: false }
            );
          });
      }
      return next(action);
    };
  };
};

const getSessionPhotos = async (photo) => {
  try {
    for (let i = 0; i < 20; i++) {
      await timer(1000);
      const sessionPhoto = await axiosInst(
        fetchSessionImages({
          auditId: photo.audit_id,
          sessionId: photo.session_id,
        })
      );

      if (sessionPhoto.data.length === 0) {
        continue;
      }

      return sessionPhoto.data[0];
    }
  } catch (error) {
    return false;
  }
};

// Returns a Promise that resolves after "ms" Milliseconds
const timer = (ms) => new Promise((res) => setTimeout(res, ms));
