export const isItemLinked = (itemsArr) => {
  if (itemsArr.length) {
    return itemsArr.some((item) => {
      return (
        (item.link_questions && item.link_questions.length > 0) ||
        (item.link_observations && item.link_observations.length > 0) ||
        (item.link_documents && item.link_documents.length > 0) ||
        (item.link_photos && item.link_photos.length > 0)
      );
    });
  }
};

export const addObservationLink = (item, observationLinkId) => {
  if (item.link_observations.indexOf(observationLinkId) > -1) {
    return item;
  } else {
    return {
      ...item,
      link_observations: [...item.link_observations, observationLinkId],
    };
  }
};

export const removeObservationLink = (item, observationLinkId) => {
  return {
    ...item,
    link_observations: item.link_observations.filter(
      (obs) => obs !== observationLinkId
    ),
  };
};

export const addPhotoLink = (item, photoLinkId) => {
  if (item.link_photos.indexOf(photoLinkId) > -1) {
    return item;
  } else {
    return {
      ...item,
      link_photos: [...item.link_photos, photoLinkId],
    };
  }
};

export const removePhotoLink = (item, photoLinkId) => {
  return {
    ...item,
    link_photos: item.link_photos.filter((photo) => photo !== photoLinkId),
  };
};

export const removePhotosLinks = (item, photoLinksIdsList) => {
  // Remove links to all photos from 'photoLinksIdsList' array
  return {
    ...item,
    link_photos: item.link_photos.filter(
      (photo) => photoLinksIdsList.indexOf(photo) === -1
    ),
  };
};

export const addQuestionLink = (item, questionLinkId) => {
  if (item.link_questions.indexOf(questionLinkId) > -1) {
    return item;
  } else {
    return {
      ...item,
      link_questions: [...item.link_questions, questionLinkId],
    };
  }
};

export const removeQuestionLink = (item, questionLinkId) => {
  return {
    ...item,
    link_questions: item.link_questions.filter(
      (question) => question !== questionLinkId
    ),
  };
};

export const removeInterviewLink = (item, interviewLinkId) => {
  // Remove links to all questions from given interview
  return {
    ...item,
    link_questions: item.link_questions.filter(
      (question) => question.indexOf(interviewLinkId) === -1
    ),
  };
};

export const addDocumentLink = (item, documentLinkId) => {
  if (item.link_documents.indexOf(documentLinkId) > -1) {
    return item;
  } else {
    return {
      ...item,
      link_documents: [...item.link_documents, documentLinkId],
    };
  }
};

export const removeDocumentLink = (item, documentLinkId) => {
  return {
    ...item,
    link_documents: item.link_documents.filter((doc) => doc !== documentLinkId),
  };
};

export const replaceInArray = ({ array, find, replace }) => {
  const arr = array || [];
  return arr.map((item) => {
    return item.replace(new RegExp(find, 'g'), replace);
  });
};

export const containsTempIds = (array) => {
  return array.some((item) => item.indexOf('temp') > -1);
};
