import React, { Fragment } from 'react';

const PageName = ({ name, isAuditExported }) => {
  return (
    <Fragment>
      <div>
        <h2>
          {name}
          {isAuditExported && (
            <span className='text-success'> - Audit closed</span>
          )}
        </h2>
      </div>
      <hr />
    </Fragment>
  );
};

PageName.defaultProps = {
  isAuditExported: false,
};

export default PageName;
