import { DELETE_PHOTO, DELETE_PHOTOS_ARRAY } from '../types/photosTypes';
import * as localForage from 'localforage';
import { keyToPhotoId } from '../../utils/photos';

export const photoDeleteMiddleware = ({ getState, dispatch }) => {
  return function (next) {
    return async function (action) {
      if (action.type === DELETE_PHOTO || action.type === DELETE_PHOTOS_ARRAY) {
        let deletedPhotosIds;
        const { photo_id, photoIdsList } = action.payload;
        if (photo_id) {
          deletedPhotosIds = [photo_id];
        }
        if (photoIdsList) {
          deletedPhotosIds = photoIdsList;
        }
        const localForageKeys = await localForage.keys();
        const keysToBeRemoved = localForageKeys.filter((key) => {
          return deletedPhotosIds.indexOf(keyToPhotoId(key)) > -1;
        });
        keysToBeRemoved.forEach((key) => {
          localForage.removeItem(key);
        });
      }
      return next(action);
    };
  };
};
