export const SET_LOADING = 'observations/setLoading';
export const FETCH_OBSERVATIONS_INIT = 'fetchObservationsInit';
export const FETCH_OBSERVATIONS_REFRESH_COMMIT =
  'fetchObservationsRefreshCommit';
export const FETCH_OBSERVATIONS_REFRESH_ROLLBACK =
  'fetchObservationsRefreshRollback';
export const FETCH_OBSERVATION_DETAILS_INIT = 'fetchObservationDetailsInit';
export const FETCH_OBSERVATION_DETAILS_REFRESH =
  'fetchObservationDetailsRefresh';
export const FETCH_OBSERVATION_DETAILS_REFRESH_COMMIT =
  'fetchObservationDetailsRefreshCommit';
export const FETCH_OBSERVATION_DETAILS_REFRESH_ROLLBACK =
  'fetchObservationDetailsRefreshRollback';
export const UPDATE_OBSERVATION = 'updateObservation';
export const UPDATE_OBSERVATION_COMMIT = 'updateObservationCommit';
export const CREATE_OBSERVATION = 'createObservation';
export const CREATE_OBSERVATION_COMMIT = 'createObservationCommit';
export const DELETE_OBSERVATION = 'deleteObservation';
export const DELETE_OBSERVATION_COMMIT = 'deleteObservationCommit';
