import React, { useEffect, Fragment } from 'react';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useRowSelect,
} from 'react-table';
import GlobalFilter from './GlobalFilter';
import { useAppContext } from '../../../utils/auth/context';

const TableFiltered = ({
  data,
  columns,
  showFilters,
  isAuditsTable,
  onChangeSelection,
}) => {
  const { authUser } = useAppContext();
  const tableInstance = useTable(
    { autoResetSelectedRows: false, columns, data },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useRowSelect
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    toggleAllRowsSelected,
    prepareRow,
    state,
    selectedFlatRows,
    setGlobalFilter,
  } = tableInstance;

  useEffect(() => {
    onChangeSelection(selectedFlatRows);
    // eslint-disable-next-line
  }, [selectedFlatRows]);

  useEffect(() => {
    toggleAllRowsSelected(false);
    // eslint-disable-next-line
  }, [data]);

  return (
    <Fragment>
      {showFilters && (
        <Col sm={2}>
          <GlobalFilter
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          {headerGroups.map((headerGroup) => {
            return headerGroup.headers.map((column) => (
              <Form.Group key={column.id}>
                {column.canFilter ? column.render('Filter') : null}
              </Form.Group>
            ));
          })}
        </Col>
      )}
      <Col sm={showFilters ? 10 : 12}>
        <div className='table-wrapper mb-4'>
          <Table {...getTableProps()} striped bordered hover size='sm'>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon
                              icon={faSortDown}
                              size='xs'
                              className='ml-2'
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faSortUp}
                              size='xs'
                              className='ml-2'
                            />
                          )
                        ) : (
                          <FontAwesomeIcon
                            icon={faSort}
                            size='xs'
                            className='ml-2'
                          />
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps({
                      className: `${row.isSelected ? 'active' : ''} ${
                        isAuditsTable && !row.original.authorized
                          ? 'row-disabled'
                          : ''
                      } ${
                        isAuditsTable && row.original.audit_above_limit
                          ? 'row-above-limit'
                          : ''
                      } ${
                        isAuditsTable &&
                        row.original.hasOwnProperty('sync_status') &&
                        row.original.sync_status !== 'archived'
                          ? 'row-exporting'
                          : ''
                      } ${
                        isAuditsTable &&
                        row.original.hasOwnProperty('sync_status') &&
                        row.original.sync_status === 'archived'
                          ? 'row-archived'
                          : ''
                      } ${
                        isAuditsTable &&
                        row.original.hasOwnProperty('sync_status') &&
                        row.original.sync_status === 'archived' &&
                        authUser &&
                        authUser.isAdmin
                          ? 'row-admin-enabled'
                          : ''
                      }`,
                      onClick: () => {
                        toggleAllRowsSelected(false);
                        row.toggleRowSelected();
                      },
                    })}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Col>
    </Fragment>
  );
};

TableFiltered.defaultProps = {
  isAuditsTable: false,
};

export default TableFiltered;
