import { useSelector } from 'react-redux';
import { filterInterviewsAgainstTypeOfSite } from '../filtering';
import { getPhotoKey } from '../photos';

export default function useOverviewAndReportData(auditId) {
  const auditData = useSelector((state) => {
    // Common
    const auditMetadata = state.audits.audits.find(
      (audit) => audit.audit_id === parseInt(auditId)
    );
    const auditSiteType = auditMetadata.type_of_site || '';
    const auditInterviews = state.interviews.interviews
      .filter((interview) => interview.audit_id === parseInt(auditId))
      .filter(filterInterviewsAgainstTypeOfSite(auditSiteType));
    const auditObservations = state.observations.observations.filter(
      (observation) =>
        observation.audit_id === parseInt(auditId) && observation.in_report
    );
    const interviewsInReport = auditInterviews
      .filter((interview) => interview.in_report === true)
      .map((interview) => {
        return {
          ...interview,
          questions: interview.questions.filter(
            (question) => question.in_report === true
          ),
        };
      });
    const interviewsQuestionsInReport = interviewsInReport.reduce(
      (acc, interview) => {
        return [...acc, ...interview.questions];
      },
      []
    );

    // Overview
    const questionsOverallAndAnswered = auditInterviews.reduce(
      (acc, interview) => {
        return {
          overall: acc.overall + parseInt(interview.questions_count),
          answered: acc.answered + parseInt(interview.questions_answered),
        };
      },
      {
        overall: 0,
        answered: 0,
      }
    );
    let significantMSO = interviewsQuestionsInReport.filter(
      (question) => question.is_significant.toLowerCase() === 'significant'
    ).length;
    const minorMSO = interviewsQuestionsInReport.filter(
      (question) => question.is_significant.toLowerCase() === 'minor'
    ).length;
    // Count each bundle of observations in one interview as one significant MSO (management system observation)
    const bundledMSO = interviewsInReport.reduce((acc, interview) => {
      const bundlesInInterview = new Set(
        interview.questions
          .filter((question) => question.is_significant.startsWith('bundle'))
          .map((question) => question.is_significant)
      ).size;
      return acc + bundlesInInterview;
    }, 0);
    significantMSO = significantMSO + bundledMSO;

    const ctsObservations = auditObservations.filter(
      (observation) => observation.observation_type.toLowerCase() === 'critical'
    ).length;
    const minorObservations = auditObservations.filter(
      (observation) => observation.observation_type.toLowerCase() === 'minor'
    ).length;

    const criticalCount = significantMSO + ctsObservations;
    const minorCount = minorMSO + minorObservations;

    const employeeCount = auditMetadata.employee_count;
    const contractorCount = auditMetadata.contractor_count;
    const employeesAndContractors =
      !Number.isInteger(employeeCount) || !Number.isInteger(contractorCount)
        ? 'Employees and/or contractors count missing'
        : employeeCount + contractorCount;

    // Report
    const observationsInReport = auditObservations.map((observation) => {
      return {
        ...observation,
        link_photos: observation.link_photos.map((photoId) => {
          return {
            photo_id: photoId,
            photo_key: getPhotoKey(
              state.photos.photos.find((photo) => photo.photo_id === photoId)
            ),
          };
        }),
      };
    });
    const goodPracticesInReport = state.goodpractices.gps
      .filter(
        (goodPractice) =>
          goodPractice.audit_id === parseInt(auditId) && goodPractice.in_report
      )
      .map((goodPractice) => {
        return {
          ...goodPractice,
          link_photos: goodPractice.link_photos.map((photoId) => {
            return {
              photo_id: photoId,
              photo_key: getPhotoKey(
                state.photos.photos.find((photo) => photo.photo_id === photoId)
              ),
            };
          }),
        };
      });

    return {
      questionsAnsweredPercent:
        questionsOverallAndAnswered.overall === 0
          ? 0
          : Math.round(
              (questionsOverallAndAnswered.answered /
                questionsOverallAndAnswered.overall) *
                100
            ),
      interviewsStarted: auditInterviews.length,
      significantMSO,
      minorMSO,
      ctsObservations,
      minorObservations,
      riskFactor: calculateRisk(
        employeesAndContractors,
        criticalCount,
        minorCount
      ),
      employeesAndContractors,
      auditMetadata,
      observationsInReport,
      goodPracticesInReport,
      interviewsInReport,
    };
  });
  return auditData;
}

const calculateRisk = (employeesCnt, criticalCnt, minorCnt) => {
  if (!Number.isInteger(employeesCnt)) {
    return 'n/a';
  }
  const significanceMultiplier = 0.5;
  const minorMultiplier = 0.1;
  let risk;

  if (employeesCnt < 51) {
    risk =
      (criticalCnt * significanceMultiplier + minorCnt * minorMultiplier) * 2;
  } else if (employeesCnt < 251) {
    risk =
      (criticalCnt * significanceMultiplier + minorCnt * minorMultiplier) *
      1.75;
  } else if (employeesCnt < 501) {
    risk =
      (criticalCnt * significanceMultiplier + minorCnt * minorMultiplier) * 1.5;
  } else {
    risk =
      (criticalCnt * significanceMultiplier + minorCnt * minorMultiplier) *
      1.25;
  }
  if (risk > 10) risk = 10;
  return Math.round(risk * 100) / 100;
};
