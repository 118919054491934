const countryList = [
  'United Arab Emirates',
  'South Africa',
  'Japan',
  'United Kingdom',
  'Singapore',
  'Canada',
  'France',
  'Mexico',
  'Switzerland',
  'Turkey',
  'India',
  'United States',
  'Australia',
  'Republic of Korea',
  'Russian Federation',
  'Spain',
  'Sweden',
  'Argentina',
  'Austria',
  'Brazil',
  'Italy',
  'Netherlands',
  'China',
  'Germany',
  'Montenegro',
  'Tanzania',
  'Albania',
  'Armenia',
  'Azerbaijan',
  'Bosnia and Herzegovina',
  'Georgia',
  'Moldova',
  'Kosovo',
  'Macedonia',
  'Slovakia',
  'Ukraine',
  'Czechia',
  'Kazakhstan',
  'Norway',
  'Poland',
  'Romania',
  'Slovenia',
  'Denmark',
  'Finland',
  'Croatia',
  'Hungary',
  'Israel',
  'Bulgaria',
  'Serbia',
  'Macao',
  'Hong Kong',
  'Taiwan',
  'Zambia',
  'Saudi Arabia',
  'Morocco',
  'Côte d’Ivoire',
  'Algeria',
  'Tunisia',
  'Ghana',
  'Kenya',
  'Mozambique',
  'Bahrain',
  'Egypt',
  'Pakistan',
  'Afghanistan',
  'Lebanon',
  'Country Argentina',
  'Philippines',
  'Thailand',
  'Vietnam',
  'Uzbekistan',
  'Libya',
  'Honduras',
  'Panama',
  'Jordan',
  'Belgium',
  'Malaysia',
  'Turkmenistan',
  'Indonesia',
  'Costa Rica',
  'Dominican Republic',
  'El Salvador',
  'Guatemala',
  'Oman',
  'Peru',
  'Portugal',
  'Greece',
  'Ireland',
  'Kuwait',
  'Colombia',
  'Iraq',
  'Chile',
  'Ecuador',
  'New Zealand',
  'Brunei Darussalam',
  'Cambodia',
  'Myanmar',
  'Luxembourg',
  'Estonia',
  'Latvia',
  'Lithuania',
];

export default countryList;
