import React, { Fragment } from 'react';
import Form from 'react-bootstrap/Form';

const QuestionSection3 = ({ state, questions, onChange }) => {
  const handleChange = (e) => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    onChange(e.target.name, value);
  };

  return (
    <Fragment>
      <h5>Employee Qualification</h5>
      <Form.Group controlId='s3q1'>
        <Form.Label>{questions.s3q1}</Form.Label>
        <Form.Control
          size='sm'
          as='select'
          name='s3q1'
          value={state.s3q1 || ''}
          onChange={handleChange}
        >
          <option value=''>-</option>
          <option value='yes'>Yes</option>
          <option value='no'>No</option>
          <option value='na'>N/A</option>
        </Form.Control>
      </Form.Group>

      {state.s3q1 === 'yes' && (
        <Form.Group controlId='s3q2'>
          <Form.Label>{questions.s3q2}</Form.Label>
          <Form.Control
            size='sm'
            as='select'
            name='s3q2'
            value={state.s3q2 || ''}
            onChange={handleChange}
          >
            <option value=''>-</option>
            <option value='yes'>Yes</option>
            <option value='no'>No</option>
          </Form.Control>
        </Form.Group>
      )}

      {state.s3q1 === 'yes' && state.s3q2 === 'no' && (
        <Form.Group controlId='s3q3'>
          <Form.Label>{questions.s3q3}</Form.Label>
          <Form.Control
            size='sm'
            as='select'
            name='s3q3'
            value={state.s3q3 || ''}
            onChange={handleChange}
          >
            <option value=''>-</option>
            <option value='Site does not control the qualification/certification'>
              Site does not control the qualification/certification
            </option>
            <option value='Qualification/certification is expired'>
              Qualification/certification is expired
            </option>
            <option value='Other'>Other</option>
          </Form.Control>
        </Form.Group>
      )}
      {state.s3q1 === 'yes' && state.s3q2 === 'no' && state.s3q3 === 'Other' && (
        <Form.Group controlId='s3q3Other'>
          <Form.Label>{questions.s3q3Other}</Form.Label>
          <Form.Control
            as='textarea'
            rows='3'
            name='s3q3Other'
            value={state.s3q3Other || ''}
            onChange={handleChange}
          />
        </Form.Group>
      )}
    </Fragment>
  );
};

export default QuestionSection3;
