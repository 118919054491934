import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import { useDispatch } from 'react-redux';

const DispatchDeleteItemModal = ({
  show,
  onHide,
  selectedItemId,
  deleteAction,
  auditId,
  isLinked,
  handleRemoveFromTable,
  selectedElementAuditId,
  isAuditTable,
}) => {
  const dispatch = useDispatch();

  const handleDeleteItem = () => {
    let requestPayload = selectedItemId;
    if (auditId !== null) {
      requestPayload = [auditId, selectedItemId];
    }
    if (auditId !== null && selectedElementAuditId !== null) {
      // Used mainly for deleting documents
      requestPayload = [auditId, selectedItemId, selectedElementAuditId];
    }
    dispatch(deleteAction(requestPayload));
    if (handleRemoveFromTable) {
      handleRemoveFromTable(selectedItemId);
    }
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLinked && (
          <p>
            Selected element(s) is/are linked to other elements in this audit.
          </p>
        )}
        {isAuditTable && (
          <Alert variant='danger'>
            Before proceeding, please ensure that all audit contents have been
            archived in CF A Workspace.
          </Alert>
        )}
        Are you sure you want to <strong>Delete</strong> selected element(s)?
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>
          Cancel
        </Button>
        <Button variant='danger' onClick={handleDeleteItem}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DispatchDeleteItemModal.defaultProps = {
  selectedItemId: '',
  isLinked: false,
  selectedElementAuditId: null,
  isAuditTable: false,
};

export default DispatchDeleteItemModal;
