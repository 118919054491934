import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export default function useAuditInChina() {
  // Check if audit is located in China
  // true if ARECountry == 'CN', 'cn' or is empty (missing data - audit might be in China)
  const { auditId } = useParams();
  return useSelector((state) =>
    state.audits.audits
      .find((audit) => audit.audit_id === parseInt(auditId))
      .ares.some(
        (are) => are.ARECountry.toLowerCase() === 'cn' || !are.ARECountry
      )
  );
}
