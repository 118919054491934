import React, { useState } from 'react';
import axiosInst from '../../../http/axiosConfig';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DeleteItemModal = ({
  show,
  onHide,
  selectedItemId,
  handleRemoveFromTable,
  apiDeleteMethod,
  auditId,
  isLinked,
  selectedElementAuditId,
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleDeleteItem = () => {
    let requestPayload = selectedItemId;
    if (auditId !== null) {
      requestPayload = [auditId, selectedItemId];
    }
    if (auditId !== null && selectedElementAuditId !== null) {
      // Used mainly for deleting documents
      requestPayload = [auditId, selectedItemId, selectedElementAuditId];
    }
    setButtonLoading(true);
    axiosInst(apiDeleteMethod(requestPayload))
      .then((response) => {
        handleRemoveFromTable(selectedItemId);
        onHide();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  return (
    <Modal show={show} onHide={onHide} backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLinked && (
          <p>
            Selected element(s) is/are linked to other elements in this audit.
          </p>
        )}
        Are you sure you want to <strong>Delete</strong> selected element(s)?
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>
          Cancel
        </Button>
        <Button variant='danger' onClick={handleDeleteItem}>
          {buttonLoading ? (
            <FontAwesomeIcon icon='circle-notch' spin className='mr-2' />
          ) : (
            ''
          )}
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteItemModal.defaultProps = {
  selectedItemId: '',
  isLinked: false,
  selectedElementAuditId: null,
};

export default DeleteItemModal;
