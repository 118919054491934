export const SET_LOADING = 'photos/setLoading';
export const FETCH_PHOTOS_INIT = 'fetchPhotosInit';
export const FETCH_PHOTOS_REFRESH_COMMIT = 'fetchPhotosRefreshCommit';
export const FETCH_PHOTOS_REFRESH_ROLLBACK = 'fetchPhotosRefreshRollback';
export const UPDATE_PHOTO_META = 'updatePhotoMeta';
export const UPDATE_PHOTO_META_COMMIT = 'updatePhotoMetaCommit';
export const GET_PRESIGNED_URL = 'getPresignedUrl';
export const GET_PRESIGNED_URL_COMMIT = 'getPresignedUrlCommit';
export const ADD_PHOTO = 'addPhoto';
export const DELETE_PHOTO = 'deletePhoto';
export const DELETE_PHOTO_COMMIT = 'deletePhotoCommit';
export const DELETE_PHOTOS_ARRAY = 'deletePhotosArray';
export const DELETE_PHOTOS_ARRAY_COMMIT = 'deletePhotosArrayCommit';
