import React from 'react';
import axios from 'axios';
import config from '../utils/auth/awsAmplifyConfig';
import { toast } from 'react-toastify';
import { store } from '../store/store';

// Options for toast displayed when a request fails
const toastOptions = {
  // autoClose: false,
};
let requestsCounter = 0;

// Creating axios instance with custom config that applies to every request
// See: https://github.com/axios/axios#config-defaults
const instance = axios.create({
  baseURL: config.apiGateway.URL,
});

instance.interceptors.request.use((request) => {
  requestsCounter++;
  if (requestsCounter === 1) {
    store.dispatch({
      type: 'toggleRequestPending',
      payload: true,
    });
  }
  const token = localStorage.getItem('idToken');
  if (token) {
    request.headers['Authorization'] = token;
  }
  request.headers['Clientid'] = config.cognito.APP_CLIENT_ID;
  return request;
});

instance.interceptors.response.use(
  (response) => {
    requestsCounter--;
    if (requestsCounter === 0) {
      store.dispatch({
        type: 'toggleRequestPending',
        payload: false,
      });
    }
    return response;
  },
  (error) => {
    requestsCounter--;
    if (requestsCounter === 0) {
      store.dispatch({
        type: 'toggleRequestPending',
        payload: false,
      });
    }
    if (typeof error.response === 'undefined') {
      toast.error(
        'A network error occurred. This could be a CORS, internet connection or expired token issue. If possible - log out and log in again to the application.',
        toastOptions
      );
    }
    if (error.response.status === 401) {
      toast.error(
        <div>
          <strong>401 - Unauthorized</strong>
          <br />
          You are unauthorized to view the content of beSafe. Please contact CFA
          team for access.
        </div>,
        toastOptions
      );
      // throw new Error('401 - Unauthorized access');
    } else if (
      error.response.status === 400 &&
      error.response.data.message.indexOf('closed') > -1
    ) {
      toast.error(error.response.data.message, toastOptions);
    } else {
      toast.error(error.message, toastOptions);
    }
    return Promise.reject(error);
    // throw error;
  }
);

export default instance;
