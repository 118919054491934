import React, { Fragment, useState, useEffect } from 'react';
import { Link, useParams, useLocation, Prompt } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import DocumentForm from './DocumentForm';
import Tags from '../../shared/tags/Tags';
import LinkObservations from '../../shared/linking/LinkObservations';
import LinkQuestions from '../../shared/linking/LinkQuestions';
import LinkPhotos from '../../shared/linking/LinkPhotos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { updateDocument } from '../../../store/actions/documentsActions';
import { convertDate } from '../../../utils/convert';
import { getFileType } from '../../../utils/photos';
import {
  viewSelectedDocument,
  downloadSelectedDocument,
} from '../../../utils/documents';

const EditDocument = () => {
  const { auditId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { docData, isOnline } = useSelector((state) =>
    // Document id is passed via location.state
    // (not url because of its format <timestamp>#<file_name>.<file_extension>)
    ({
      docData: state.documents.documents.find(
        (document) => document.document_id === location.state.documentId
      ),
      isOnline: state.offline.online,
    })
  );
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [formState, setFormState] = useState({
    title: '',
    category: '',
    valid_from: '',
    valid_to: '',
    country: '',
    permanent: false,
  });
  const [tags, setTags] = useState([]);
  const [linkedQuestions, setLinkedQuestions] = useState([]);
  const [linkedObservations, setLinkedObservations] = useState([]);
  const [linkedPhotos, setLinkedPhotos] = useState([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [dataSaved, setDataSaved] = useState(false);

  useEffect(() => {
    setFormState({
      title: docData.title || '',
      category: docData.category || '',
      valid_from: docData.valid_from ? new Date(docData.valid_from) : '',
      valid_to: docData.valid_to ? new Date(docData.valid_to) : '',
      country: docData.country || '',
      permanent: docData.permanent || false,
    });
    setTags(docData.tags || []);
    setLinkedQuestions(docData.link_questions || []);
    setLinkedObservations(docData.link_observations || []);
    setLinkedPhotos(docData.link_photos || []);
    // eslint-disable-next-line
  }, []);

  const handleFormChange = (e) => {
    setDataChanged(true);
    setDataSaved(false);
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormState({
      ...formState,
      [e.target.name]: value,
    });
  };

  const handleValidFromChange = (momentObj) => {
    setDataChanged(true);
    setDataSaved(false);
    setFormState({
      ...formState,
      valid_from: momentObj._d,
    });
  };

  const handleValidToChange = (momentObj) => {
    setDataChanged(true);
    setDataSaved(false);
    setFormState({
      ...formState,
      valid_to: momentObj._d,
    });
  };

  const validateForm = (formState) => {
    if (!formState.title) {
      return 'Title field is required.';
    }
    if (!formState.category) {
      return 'Category field is required.';
    }
    // if (!formState.valid_from) {
    //   return 'Valid from field is required.';
    // }
    // if (!formState.valid_to) {
    //   return 'Valid to field is required.';
    // }
    if (formState.category === 'Country specific' && !formState.country) {
      return 'Country field is required with Country specific category.';
    }
    return null;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const errorMsg = validateForm(formState);
    if (errorMsg) {
      setError(errorMsg);
      return;
    } else {
      setError(null);
      const documentMetaData = {
        ...formState,
        valid_from: convertDate(formState.valid_from),
        valid_to: convertDate(formState.valid_to),
        tags: tags,
        link_photos: linkedPhotos,
        link_questions: linkedQuestions,
        link_observations: linkedObservations,
        confirmed: docData.confirmed,
      };
      setSaving(true);
      dispatch(
        updateDocument({
          auditId: auditId,
          documentAuditId: location.state.originalAuditId,
          documentId: location.state.documentId,
          documentMetaData,
        })
      )
        .then(() => {
          setDataChanged(false);
          setDataSaved(true);
          toast.success('Document updated');
        })
        .catch((error) => console.log(error))
        .finally(() => setSaving(false));
    }
  };

  const handleAddLinkedObservation = (linkedObs) => {
    setDataChanged(true);
    setDataSaved(false);
    setLinkedObservations((linkedObservations) => [
      ...linkedObservations,
      linkedObs,
    ]);
  };

  const handleRemoveLinkedObservation = (linkedObs) => {
    setDataChanged(true);
    setDataSaved(false);
    setLinkedObservations(
      linkedObservations.filter((obs) => obs !== linkedObs)
    );
  };

  const handleAddLinkedQuestion = (linkedQuestion) => {
    setDataChanged(true);
    setDataSaved(false);
    setLinkedQuestions((linkedQuestions) => [
      ...linkedQuestions,
      linkedQuestion,
    ]);
  };

  const handleRemoveLinkedQuestion = (linkedQuestion) => {
    setDataChanged(true);
    setDataSaved(false);
    setLinkedQuestions(
      linkedQuestions.filter((question) => question !== linkedQuestion)
    );
  };

  const handleAddLinkedPhoto = (linkedPhotosArray) => {
    setDataChanged(true);
    setDataSaved(false);
    setLinkedPhotos([...new Set([...linkedPhotos, ...linkedPhotosArray])]);
  };

  const handleRemoveLinkedPhoto = (linkedPhoto) => {
    setDataChanged(true);
    setDataSaved(false);
    setLinkedPhotos(linkedPhotos.filter((photo) => photo !== linkedPhoto));
  };

  const handleAddTag = (newTag) => {
    setDataChanged(true);
    setDataSaved(false);
    setTags((tags) => [...tags, newTag]);
  };

  const handleRemoveTag = (tagName) => {
    setDataChanged(true);
    setDataSaved(false);
    setTags(tags.filter((tag) => tag !== tagName));
  };

  return (
    <Fragment>
      {!isOnline && (
        <Alert variant='info'>
          In offline mode documents functionalities are limited - you can't edit
          documents.
        </Alert>
      )}
      <div className='py-3 w-75 mx-auto'>
        <Prompt
          when={dataChanged}
          message='You have unsaved changes. Are you sure you want to leave?'
        />
        <Row>
          <Col>
            <Form onSubmit={handleFormSubmit}>
              <DocumentForm
                formData={formState}
                onFormChange={handleFormChange}
                onValidFromChange={handleValidFromChange}
                onValidToChange={handleValidToChange}
                pageType='edit'
              />
              <p className='text-danger'>{error}</p>
              <Button
                variant={
                  dataChanged ? 'warning' : dataSaved ? 'success' : 'primary'
                }
                type='submit'
                className='mr-2'
                disabled={!isOnline}
              >
                {saving && (
                  <FontAwesomeIcon icon='circle-notch' spin className='mr-2' />
                )}
                Save changes
              </Button>
              <Link to={`/audit/${auditId}/documents/select-document`}>
                <Button variant='secondary'>Back</Button>
              </Link>
              <div className='mt-2'>
                <Button
                  variant='primary'
                  className='mr-2'
                  disabled={
                    !isOnline ||
                    getFileType(location.state.documentId).toLowerCase() !==
                      'pdf'
                  }
                  onClick={() =>
                    viewSelectedDocument({
                      documentUrl: docData.doc_url,
                    })
                  }
                >
                  View <small>(only PDF)</small>
                </Button>
                <Button
                  variant='primary'
                  className=''
                  disabled={!isOnline}
                  onClick={() =>
                    downloadSelectedDocument({
                      documentUrl: docData.doc_url,
                      documentName: location.state.documentId.split('#')[1],
                    })
                  }
                >
                  Download
                </Button>
              </div>
            </Form>
          </Col>
          <Col>
            <p>Linked elements:</p>
            <LinkObservations
              linked={linkedObservations}
              onAddLinkedObservation={handleAddLinkedObservation}
              onRemoveLinkedObservation={handleRemoveLinkedObservation}
            />
            <LinkQuestions
              linked={linkedQuestions}
              onAddLinkedQuestion={handleAddLinkedQuestion}
              onRemoveLinkedQuestion={handleRemoveLinkedQuestion}
            />
            <LinkPhotos
              linked={linkedPhotos}
              onAddLinkedPhoto={handleAddLinkedPhoto}
              onRemoveLinkedPhoto={handleRemoveLinkedPhoto}
            />
            <Tags
              tags={tags}
              onAddTag={handleAddTag}
              onRemoveTag={handleRemoveTag}
            />
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default EditDocument;
