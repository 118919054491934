import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectColumnFilter from '../shared/table/SelectColumnFilter';
import TableFiltered from '../shared/table/TableFiltered';
import DispatchDeleteItemModal from '../shared/modals/DispatchDeleteItemModal';

import { useSelector, useDispatch } from 'react-redux';
import { fetchAudits, deleteAudit } from '../../store/actions/auditsActions';
import { RESET_APP_STATE } from '../../store/types/auditsTypes';
import { RESET_STATE } from '@redux-offline/redux-offline/lib/constants';
import * as localForage from 'localforage';
import { toast } from 'react-toastify';
import { useAppContext } from '../../utils/auth/context';

const AuditSelectionTable = () => {
  const { authUser } = useAppContext();
  const dispatch = useDispatch();
  const { apiData, loading, isOnline, requestsPending } = useSelector(
    (state) => ({
      apiData: state.audits.audits,
      loading: state.audits.loading,
      isOnline: state.offline.online,
      requestsPending: state.requestsPending,
    })
  );
  const [selectedFlatRows, setSelectedFlatRows] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (isOnline) {
      dispatch(fetchAudits());
    }
    // eslint-disable-next-line
  }, []);

  const showTableFilters = true;

  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'Site name',
        accessor: 'site_name',
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'ARE',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          return original.ares
            ? original.ares.map((are) => are.ARENumber || '').join()
            : '';
        },
      },
      {
        Header: 'Country',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          return original.ares
            ? original.ares.map((are) => are.ARECountry || '').join()
            : '';
        },
      },
      {
        Header: 'Location',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          return original.ares
            ? original.ares.map((are) => are.ARELocation || '').join()
            : '';
        },
      },
      {
        Header: 'Type of site',
        accessor: 'type_of_site',
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'FY',
        accessor: 'fiscal_year',
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
    ],
    []
  );

  // Sort audits by creation_timestamp ASC and make newest ones (index > 9) inaccessible
  const data = React.useMemo(() => apiData, [apiData]);

  const handleChangeSelection = useCallback((row) => {
    setSelectedFlatRows(row);
  }, []);

  const handleResetAppState = () => {
    dispatch({ type: RESET_STATE });
    dispatch({ type: RESET_APP_STATE });
    localForage
      .clear()
      .then(() => {
        dispatch(fetchAudits());
      })
      .catch((err) => {
        toast.error('Unable to clear browser data. Please try again.');
      });
  };

  return (
    <Fragment>
      {loading && (
        <Row>
          <Col>
            <div className='text-center mb-3'>
              <FontAwesomeIcon icon='circle-notch' spin size='lg' />
            </div>
          </Col>
        </Row>
      )}
      {apiData.length > 10 && (
        <Alert variant='warning'>
          Please note: list below contains more than 10 audits. For security
          reasons you can't edit audits that exceed that limit. To access them,
          export older audits' data first.
        </Alert>
      )}
      {apiData.some(
        (audit) =>
          audit.hasOwnProperty('sync_status') &&
          audit.sync_status !== 'archived'
      ) && (
        <Alert variant='success'>
          Please note: audits marked in green are currently being exported to CF
          A Workspace and are no longer accessible in beSafe. Once the archiving
          is complete they will be marked in red and be available for deletion
          by Admin user.
        </Alert>
      )}
      {apiData.some(
        (audit) =>
          audit.hasOwnProperty('sync_status') &&
          audit.sync_status === 'archived'
      ) && (
        <Alert variant='danger'>
          Please note: audits marked in red have already been archived and can
          be deleted only by Admin user.
        </Alert>
      )}
      <Row>
        <TableFiltered
          data={data}
          columns={columns}
          onChangeSelection={handleChangeSelection}
          showFilters={showTableFilters}
          isAuditsTable={true}
        />
      </Row>
      <Row>
        <Col sm={showTableFilters ? { offset: 2, span: 10 } : 12}>
          {!loading && apiData.length > 0 && (
            <div className='d-flex justify-content-between'>
              <div>
                <Button
                  variant='primary'
                  disabled={requestsPending || !isOnline}
                  onClick={handleResetAppState}
                >
                  {requestsPending && (
                    <FontAwesomeIcon
                      icon='circle-notch'
                      spin
                      className='mr-2'
                    />
                  )}
                  Refresh application data
                </Button>
                <OverlayTrigger
                  placement='top'
                  overlay={
                    <Tooltip id='refresh_data_tooltip'>
                      Removes audits' data (incl. photos) from browser storage
                      and downloads new data.
                      <br />
                      NOTE: Can't trigger refresh while data is downloaded.
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    size='xs'
                    className='ml-2'
                  />
                </OverlayTrigger>
              </div>
              <div>
                {authUser && authUser.isAdmin && (
                  <Button
                    variant='danger'
                    className='mr-2'
                    disabled={
                      selectedFlatRows.length !== 1 ||
                      (selectedFlatRows[0] &&
                        selectedFlatRows[0].original.sync_status !== 'archived')
                    }
                    onClick={() => setModalShow(true)}
                  >
                    Delete archived audit
                  </Button>
                )}
                <Link
                  to={`/audit/${
                    selectedFlatRows[0]
                      ? selectedFlatRows[0].original.audit_id
                      : ''
                  }/overview`}
                >
                  <Button
                    variant='primary'
                    disabled={
                      selectedFlatRows.length !== 1 ||
                      (selectedFlatRows[0] &&
                        !selectedFlatRows[0].original.authorized)
                    }
                  >
                    Work on selected audit
                  </Button>
                </Link>
              </div>
            </div>
          )}
        </Col>
      </Row>

      <DispatchDeleteItemModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        selectedItemId={
          selectedFlatRows.length > 0
            ? selectedFlatRows[0].original.audit_id
            : ''
        }
        deleteAction={deleteAudit}
        auditId={null}
        isAuditTable={true}
      />
    </Fragment>
  );
};

export default AuditSelectionTable;
