import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import 'react-datetime/css/react-datetime.css';
import axios from 'axios';
import axiosInst from '../../../http/axiosConfig';
import { deleteDocumentById } from '../../../http/requests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import Tags from '../../shared/tags/Tags';
import LinkObservations from '../../shared/linking/LinkObservations';
import LinkQuestions from '../../shared/linking/LinkQuestions';
import LinkPhotos from '../../shared/linking/LinkPhotos';
import DocumentForm from './DocumentForm';
import { useSelector, useDispatch } from 'react-redux';
import { createDocument } from '../../../store/actions/documentsActions';
import { DELETE_DOCUMENT } from '../../../store/types/documentsTypes';
import { convertDate, convertUploadedFileName } from '../../../utils/convert';
import useAuditExported from '../../../utils/customHooks/useAuditExported';
import { toast } from 'react-toastify';

import styles from './UploadDocument.module.scss';

const UploadDocument = () => {
  const { auditId } = useParams();
  const dispatch = useDispatch();
  const isOnline = useSelector((state) => state.offline.online);
  const isAuditExported = useAuditExported();
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, setError] = useState(null);
  const [formState, setFormState] = useState({
    title: '',
    category: '',
    valid_from: '',
    valid_to: '',
    country: '',
    permanent: false,
    confirmed: false,
  });
  const [questionTags, setQuestionTags] = useState([]);
  const [linkedQuestions, setLinkedQuestions] = useState([]);
  const [linkedObservations, setLinkedObservations] = useState([]);
  const [linkedPhotos, setLinkedPhotos] = useState([]);
  const fileLimit = 50;

  const fileInput = useRef(null);

  useEffect(() => {
    if (selectedFiles.length > 1) {
      setFormState({
        ...formState,
        title: '',
      });
      setQuestionTags([]);
      setLinkedQuestions([]);
      setLinkedObservations([]);
      setLinkedPhotos([]);
    }
    // eslint-disable-next-line
  }, [selectedFiles]);

  const handleFormChange = (e) => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormState({
      ...formState,
      [e.target.name]: value,
    });
  };

  const handleValidFromChange = (momentObj) => {
    setFormState({
      ...formState,
      valid_from: momentObj._d,
    });
  };

  const handleValidToChange = (momentObj) => {
    setFormState({
      ...formState,
      valid_to: momentObj._d,
    });
  };

  const handleFile = (files) => {
    // Replace spaces, hashes and diacritics in file name
    const filesArray = Array.from(files)
      .map((file) => {
        return new File([file], convertUploadedFileName(file.name), {
          type: file.type,
        });
      })
      .filter(validateFile);
    if (selectedFiles.length + filesArray.length <= fileLimit) {
      setSelectedFiles((prevArray) => [...prevArray, ...filesArray]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    // Prevent the browser from opening the image
    e.preventDefault();
    e.stopPropagation();

    let files = e.dataTransfer.files;
    if (files.length) {
      handleFile(files);
    }
  };

  const removeFile = (fileName) => {
    setSelectedFiles(selectedFiles.filter((file) => file.name !== fileName));
    fileInput.current.value = '';
  };

  const clearForm = () => {
    setFormState({
      title: '',
      category: '',
      valid_from: '',
      valid_to: '',
      country: '',
      permanent: false,
      confirmed: false,
    });
    setQuestionTags([]);
    setLinkedQuestions([]);
    setLinkedObservations([]);
    setLinkedPhotos([]);
  };

  const handleAddTag = (newTag) => {
    setQuestionTags((questionTags) => [...questionTags, newTag]);
  };

  const handleRemoveTag = (tagName) => {
    setQuestionTags(questionTags.filter((tag) => tag !== tagName));
  };

  const validateFile = (file) => {
    // const validTypes = ['application/pdf'];
    // if (validTypes.indexOf(file.type) === -1) {
    //   return false;
    // }
    // Check for duplicated file names
    if (selectedFiles.some((selectedFile) => selectedFile.name === file.name)) {
      return false;
    }
    return true;
  };

  const validateForm = (formState) => {
    if (selectedFiles.length === 0) {
      return 'Please select a file.';
    }
    if (!formState.category) {
      return 'Category field is required.';
    }
    // if (!formState.valid_from) {
    //   return 'Valid from field is required.';
    // }
    // if (!formState.valid_to) {
    //   return 'Valid to field is required.';
    // }
    if (formState.category === 'Country specific' && !formState.country) {
      return 'Country field is required with Country specific category.';
    }
    return null;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const errorMsg = validateForm(formState);
    if (errorMsg) {
      setError(errorMsg);
      return;
    } else {
      setError(null);
      uploadDocuments();
    }
  };

  const uploadDocuments = async () => {
    const timestamp = Date.now();
    const documentAuditId = formState.permanent ? '0' : auditId;
    const documentMetaData = {
      ...formState,
      valid_from: convertDate(formState.valid_from),
      valid_to: convertDate(formState.valid_to),
      tags: questionTags,
      link_photos: linkedPhotos,
      link_questions: linkedQuestions,
      link_observations: linkedObservations,
    };
    setLoading(true);

    try {
      const fileUploadParams = await axiosInst.get(
        `/audits/${auditId}/doc_upload_url/${timestamp}`,
        {
          params: {
            docAuditId: documentAuditId,
          },
        }
      );

      const response = await Promise.allSettled(
        selectedFiles.map((file) => {
          const documentId = `${timestamp}#${file.name}`;
          documentMetaData.title =
            formState.title.trim() ||
            file.name.substring(0, file.name.lastIndexOf('.'));
          return dispatch(
            createDocument({
              auditId: auditId,
              documentAuditId,
              documentId,
              documentMetaData,
              fileName: file.name,
            })
          )
            .then(() => {
              return uploadFileToS3({
                file,
                presignedData: fileUploadParams.data,
                documentId,
                documentAuditId,
              });
            })
            .catch((err) => err);
        })
      );

      const uploadedFiles = response.filter((item) => item.value.isUploaded);
      const notUploadedFiles = response
        .filter((item) => !item.value.isUploaded)
        .map((item) => item.value.name);
      setSelectedFiles(
        selectedFiles.filter((item) => notUploadedFiles.indexOf(item.name) > -1)
      );
      setLoading(false);
      if (notUploadedFiles.length > 0) {
        toast.error(
          `Following files were NOT uploaded: ${notUploadedFiles.join(
            ', '
          )}. Please try again later.`,
          { autoClose: false }
        );
      } else {
        clearForm();
      }
      if (uploadedFiles.length) {
        setModalShow(true);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const uploadFileToS3 = ({
    file,
    presignedData,
    documentId,
    documentAuditId,
  }) => {
    const formData = new FormData();
    Object.keys(presignedData.fields).forEach((key) => {
      formData.append(key, presignedData.fields[key]);
    });
    formData.append('file', file);
    const request = {
      method: 'POST',
      url: presignedData.url,
      data: formData,
    };
    return axios(request)
      .then((response) => {
        return {
          name: file.name,
          isUploaded: true,
        };
      })
      .catch((error) => {
        dispatch({
          type: DELETE_DOCUMENT,
          payload: {
            document_id: documentId,
          },
        });
        // Deleting not fully uploaded document from db - due to GET /documents not removing them instantly
        axiosInst(deleteDocumentById([auditId, documentId, documentAuditId]));
        return {
          name: file.name,
          isUploaded: false,
        };
      });
  };

  const fileSize = (size) => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleAddLinkedObservation = (linkedObs) => {
    setLinkedObservations((linkedObservations) => [
      ...linkedObservations,
      linkedObs,
    ]);
  };

  const handleRemoveLinkedObservation = (linkedObs) => {
    setLinkedObservations(
      linkedObservations.filter((obs) => obs !== linkedObs)
    );
  };

  const handleAddLinkedQuestion = (linkedQuestion) => {
    setLinkedQuestions((linkedQuestions) => [
      ...linkedQuestions,
      linkedQuestion,
    ]);
  };

  const handleRemoveLinkedQuestion = (linkedQuestion) => {
    setLinkedQuestions(
      linkedQuestions.filter((question) => question !== linkedQuestion)
    );
  };

  const handleAddLinkedPhoto = (linkedPhotosArray) => {
    setLinkedPhotos([...new Set([...linkedPhotos, ...linkedPhotosArray])]);
  };

  const handleRemoveLinkedPhoto = (linkedPhoto) => {
    setLinkedPhotos(linkedPhotos.filter((photo) => photo !== linkedPhoto));
  };

  return (
    <div className='py-3'>
      {!isOnline && (
        <Alert variant='info'>
          In offline mode documents functionalities are limited - you can't add
          new documents.
        </Alert>
      )}
      <Row>
        <Col>
          <div
            className={styles.drop_zone}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={() => fileInput.current.click()}
          >
            <p>Click to browse files or drag and drop here...</p>
            <p>Max. {fileLimit} files allowed</p>
            <input
              type='file'
              ref={fileInput}
              hidden
              multiple
              onChange={(e) => handleFile(e.target.files)}
            />
          </div>
          <div className={`${styles.selected_files} mt-3`}>
            {selectedFiles.map((file, i) => (
              <div className={styles.selected_file} key={i}>
                <FontAwesomeIcon
                  icon={faFileAlt}
                  className='mr-2'
                  size='2x'
                  color='#2387aa'
                />
                <div className='text-break'>{file.name}</div>
                <div className='mx-2'>({fileSize(file.size)})</div>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className='pointer ml-auto text-danger'
                  onClick={() => removeFile(file.name)}
                />
              </div>
            ))}
          </div>
          {selectedFiles.length > 0 && (
            <div className='d-flex justify-content-end'>
              <small className='text-muted'>
                {selectedFiles.length}/{fileLimit} files
              </small>
            </div>
          )}
          <hr />
          {selectedFiles.length < 2 && (
            <div>
              <p>Linked elements:</p>
              <LinkObservations
                linked={linkedObservations}
                onAddLinkedObservation={handleAddLinkedObservation}
                onRemoveLinkedObservation={handleRemoveLinkedObservation}
              />
              <LinkQuestions
                linked={linkedQuestions}
                onAddLinkedQuestion={handleAddLinkedQuestion}
                onRemoveLinkedQuestion={handleRemoveLinkedQuestion}
              />
              <LinkPhotos
                linked={linkedPhotos}
                onAddLinkedPhoto={handleAddLinkedPhoto}
                onRemoveLinkedPhoto={handleRemoveLinkedPhoto}
              />
              <Tags
                tags={questionTags}
                onAddTag={handleAddTag}
                onRemoveTag={handleRemoveTag}
              />
            </div>
          )}
        </Col>
        <Col sm={{ offset: 1 }}>
          <Form onSubmit={handleFormSubmit}>
            <DocumentForm
              formData={formState}
              onFormChange={handleFormChange}
              onValidFromChange={handleValidFromChange}
              onValidToChange={handleValidToChange}
              pageType='new'
              filesCount={selectedFiles.length}
            />
            <Form.Group controlId='confirmed'>
              <Form.Check
                type='checkbox'
                label='Document contains no sensitive data *'
                name='confirmed'
                checked={formState.confirmed}
                onChange={handleFormChange}
              />
            </Form.Group>
            <p className='text-danger'>{error}</p>
            <Button
              variant='primary'
              type='submit'
              disabled={
                !isOnline ||
                selectedFiles.length === 0 ||
                !formState.confirmed ||
                isAuditExported
              }
            >
              {loading && (
                <FontAwesomeIcon icon='circle-notch' spin className='mr-2' />
              )}
              Upload document
            </Button>
          </Form>
        </Col>
      </Row>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Documents uploaded</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          New documents were successfully uploaded. Proceed to the{' '}
          <strong>List of documents</strong> or close the window to upload more
          documents.
        </Modal.Body>
        <Modal.Footer>
          <Link to={`/audit/${auditId}/documents/select-document`}>
            <Button variant='primary'>List of documents</Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UploadDocument;
