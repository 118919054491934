import React, { Fragment, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchCategories,
  fetchQuestions,
} from '../../../store/actions/questionsActions';
import { createInterview } from '../../../store/actions/interviewsActions';
import useAuditExported from '../../../utils/customHooks/useAuditExported';
import { sortInterviewCategories } from '../../../utils/sorting';
import { filterInterviewCategoriesAgainstTypeOfSite } from '../../../utils/filtering';
import { useAppContext } from '../../../utils/auth/context';

const StartNewInterview = () => {
  const { auditId } = useParams();
  const { authUser } = useAppContext();
  const dispatch = useDispatch();
  const { auditSiteType, categories, loading, isOnline } = useSelector(
    (state) => ({
      auditSiteType:
        state.audits.audits.find(
          (audit) => audit.audit_id === parseInt(auditId)
        ).type_of_site || '',
      categories: state.questions.categories.categories,
      loading:
        state.questions.categories.loading || state.questions.questions.loading,
      isOnline: state.offline.online,
    })
  );
  const isAuditExported = useAuditExported();
  const [categoriesProcessed, setCategoriesProcessed] = useState([]);
  const [error, setError] = useState(null);
  const [state, setState] = useState({
    questions_category_letter: '',
    auditor: authUser?.username || '',
    auditee: '',
    auditee_position: '',
  });
  // const [newAuditId, setNewAuditId] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (isOnline) {
      dispatch(fetchCategories());
      dispatch(fetchQuestions());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCategoriesProcessed(
      categories
        .map(filterInterviewCategoriesAgainstTypeOfSite(auditSiteType))
        .sort(sortInterviewCategories)
    );
    // eslint-disable-next-line
  }, [auditSiteType, categories]);

  const handleCloseModal = () => {
    setModalShow(false);
    // setNewAuditId(null);
  };
  const handleShowModal = () => setModalShow(true);

  const clearForm = () => {
    setState({
      questions_category_letter: '',
      auditor: authUser?.username || '',
      auditee: '',
      auditee_position: '',
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const errorMsg = validate(state);
    if (errorMsg) {
      setError(errorMsg);
      return;
    } else {
      setError(null);
      dispatch(createInterview({ auditId, interviewObj: state }));
      clearForm();
      handleShowModal();
      // setButtonLoading(true);
      // axiosInst(createNewInterview({ auditId, interviewObj: state }))
      //   .then((response) => {
      //     setNewAuditId(response.data.entity_id.split('#INTERVIEW#')[1]);
      //     clearForm();
      //     handleShowModal();
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   })
      //   .finally(() => {
      //     setButtonLoading(false);
      //   });
    }
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const validate = (state) => {
    if (!state.questions_category_letter) {
      return 'Category field is required.';
    }
    if (!state.auditor.trim()) {
      return 'Interviewer field is required.';
    }
    if (!state.auditee.trim()) {
      return 'Interview partner(s) field is required.';
    }
    return null;
  };

  return (
    <Fragment>
      <div className='w-75 mx-auto mt-5'>
        {loading && (
          <div className='text-center mb-3'>
            <FontAwesomeIcon icon='circle-notch' spin size='lg' />
          </div>
        )}
        <Form onSubmit={handleFormSubmit}>
          <Form.Group as={Row} controlId='questions_category_letter'>
            <Form.Label column sm={3}>
              Category *
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip id='form-tooltip-questions_category_letter'>
                    Depending on the type of audit site some categories are
                    disabled.
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  size='xs'
                  className='ml-2'
                />
              </OverlayTrigger>
            </Form.Label>
            <Col sm={9} className='d-flex'>
              {auditSiteType ? (
                <Form.Control
                  as='select'
                  name='questions_category_letter'
                  value={state.questions_category_letter}
                  onChange={handleChange}
                >
                  <option value=''>-</option>
                  {categoriesProcessed.map((item, index) => (
                    <option
                      value={item.category_letter}
                      key={index}
                      disabled={item.disabled}
                    >
                      {item.category_letter} - {item.question_category}
                    </option>
                  ))}
                </Form.Control>
              ) : (
                <span className='text-danger align-self-center'>
                  To select a category please enter Type of Site in Info tab
                  first.
                </span>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId='auditor'>
            <Form.Label column sm={3}>
              Interviewer *
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type='text'
                name='auditor'
                value={state.auditor}
                onChange={handleChange}
                placeholder='Enter interviewer'
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='auditee'>
            <Form.Label column sm={3}>
              Interview partner(s) *
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip id='form-tooltip-partners'>
                    You can enter one or more persons, teams or department
                    names. For a more precise assignment of interview answers to
                    persons, please use the comment section.
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  size='xs'
                  className='ml-2'
                />
              </OverlayTrigger>
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type='text'
                name='auditee'
                value={state.auditee}
                onChange={handleChange}
                placeholder='Enter interview partner(s)'
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='auditee_position'>
            <Form.Label column sm={3}>
              Interview partner position
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type='text'
                name='auditee_position'
                value={state.auditee_position}
                onChange={handleChange}
                placeholder='Enter interview partner position (optional)'
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mt-3'>
            <Col sm={{ span: 9, offset: 3 }}>
              <p className='text-danger'>{error}</p>
              <Button
                variant='primary'
                type='submit'
                disabled={isAuditExported}
              >
                {buttonLoading ? (
                  <FontAwesomeIcon icon='circle-notch' spin className='mr-2' />
                ) : (
                  ''
                )}
                Create interview
              </Button>
              <Link to={`/audit/${auditId}/interviews`} className='ml-3'>
                <Button variant='secondary'>Cancel</Button>
              </Link>
            </Col>
          </Form.Group>
        </Form>
      </div>

      <Modal
        show={modalShow}
        onHide={handleCloseModal}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Interview created</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          New interview was successfully created. Proceed to the{' '}
          <strong>List of interviews</strong> or close the window to create
          another interview.
        </Modal.Body>
        <Modal.Footer>
          <Link to={`/audit/${auditId}/interviews/select-interview`}>
            <Button variant='primary'>List of interviews</Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default StartNewInterview;
