import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import countryList from '../../../utils/countryList';
import { useSelector } from 'react-redux';
import { sortInterviewCategories } from '../../../utils/sorting';
import { filterInterviewCategoriesAgainstTypeOfSite } from '../../../utils/filtering';

const DocumentForm = ({
  formData: { title, category, valid_from, valid_to, country, permanent },
  onFormChange,
  onValidFromChange,
  onValidToChange,
  pageType,
  filesCount,
}) => {
  const { auditId } = useParams();
  const countryNames = countryList.sort();
  const validToRef = useRef(null);
  const validFromRef = useRef(null);
  const { auditSiteType, categories } = useSelector((state) => ({
    auditSiteType:
      state.audits.audits.find((audit) => audit.audit_id === parseInt(auditId))
        .type_of_site || '',
    categories: state.questions.categories.categories,
  }));
  const [categoriesProcessed, setCategoriesProcessed] = useState([]);

  useEffect(() => {
    setCategoriesProcessed(
      categories
        .map(filterInterviewCategoriesAgainstTypeOfSite(auditSiteType))
        .sort(sortInterviewCategories)
    );
    // eslint-disable-next-line
  }, [auditSiteType, categories]);

  useEffect(() => {
    if (valid_from === '') {
      validFromRef.current.state.inputValue = '';
    }
  }, [valid_from]);

  useEffect(() => {
    if (valid_to === '') {
      validToRef.current.state.inputValue = '';
    }
  }, [valid_to]);

  const handleFormChange = (e) => {
    onFormChange(e);
  };

  const handleValidFromChange = (momentObj) => {
    onValidFromChange(momentObj);
  };

  const handleValidToChange = (momentObj) => {
    onValidToChange(momentObj);
  };

  const datePickerInputProps = {
    className: 'form-control form-control-sm',
  };

  const checkValidTo = (current) => {
    return current.isAfter(valid_from);
  };

  return (
    <Fragment>
      <Form.Group controlId='title'>
        <Form.Label>Title {pageType === 'edit' ? '*' : ''}</Form.Label>
        <Form.Control
          type='text'
          size='sm'
          name='title'
          value={title}
          disabled={filesCount > 1}
          onChange={handleFormChange}
        />
        {pageType === 'new' && (
          <Form.Text className='text-muted'>
            If <strong>Title</strong> is not specified, file name will be used.
          </Form.Text>
        )}
      </Form.Group>
      <Form.Group controlId='category'>
        <Form.Label>Category *</Form.Label>
        <Form.Control
          size='sm'
          as='select'
          name='category'
          value={category}
          onChange={handleFormChange}
        >
          <option value=''>-</option>
          <optgroup label='Interview categories'>
            {categoriesProcessed.map((item, index) => (
              <option
                value={`${item.category_letter} - ${item.question_category}`}
                key={index}
                disabled={item.disabled}
              >
                {item.category_letter} - {item.question_category}
              </option>
            ))}
          </optgroup>
          <optgroup label='Other categories'>
            <option value='Country specific'>Country specific</option>
            <option value='Global'>Global</option>
            <option value='beSafe help'>beSafe help</option>
            <option value='Other'>Other</option>
          </optgroup>
        </Form.Control>
      </Form.Group>
      <Form.Group controlId='valid_from'>
        <Form.Label>Valid from</Form.Label>
        <Datetime
          name='valid_from'
          ref={validFromRef}
          inputProps={datePickerInputProps}
          value={valid_from}
          onChange={handleValidFromChange}
          closeOnSelect={true}
          dateFormat='YYYY/MM/DD'
          timeFormat={false}
        />
      </Form.Group>
      <Form.Group controlId='valid_to'>
        <Form.Label>Valid to</Form.Label>
        <Datetime
          name='valid_to'
          ref={validToRef}
          isValidDate={checkValidTo}
          inputProps={datePickerInputProps}
          value={valid_to}
          onChange={handleValidToChange}
          closeOnSelect={true}
          dateFormat='YYYY/MM/DD'
          timeFormat={false}
        />
        <Form.Text className='text-muted'>
          <strong>Valid to</strong> date must be after{' '}
          <strong>Valid from</strong>.
        </Form.Text>
      </Form.Group>
      <Form.Group controlId='country'>
        <Form.Label>Country</Form.Label>
        <Form.Control
          size='sm'
          as='select'
          name='country'
          value={country}
          onChange={handleFormChange}
        >
          <option value=''>-</option>
          {countryNames.map((country, i) => (
            <option value={country} key={i}>
              {country}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId='permanent'>
        <Form.Check
          type='checkbox'
          label='Permanent document'
          name='permanent'
          checked={permanent}
          onChange={handleFormChange}
        />
      </Form.Group>
    </Fragment>
  );
};

DocumentForm.defaultProps = {
  filesCount: 0,
};

export default DocumentForm;
