import {
  SET_LOADING,
  FETCH_DOCUMENTS_INIT,
  FETCH_DOCUMENTS_REFRESH_COMMIT,
  FETCH_DOCUMENTS_REFRESH_ROLLBACK,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_COMMIT,
  CREATE_DOCUMENT,
  UPDATE_DOCUMENT,
} from '../types/documentsTypes';
import axiosInst from '../../http/axiosConfig';
import {
  fetchDocumentsList,
  deleteDocumentById,
  createDocumentObject,
  updateDocumentObject,
} from '../../http/requests';
import { toast } from 'react-toastify';

export const fetchDocumentsInit = (auditId) => {
  return (dispatch) => {
    axiosInst(fetchDocumentsList(auditId))
      .then((response) => {
        dispatch({
          type: FETCH_DOCUMENTS_INIT,
          payload: response.data,
        });
      })
      .catch((error) => {
        //
      });
  };
};

export const fetchDocumentsRefresh = (auditId) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      payload: true,
      meta: {
        offline: {
          effect: fetchDocumentsList(auditId),
          commit: {
            type: FETCH_DOCUMENTS_REFRESH_COMMIT,
            meta: {
              audit_id: parseInt(auditId),
            },
          },
          rollback: {
            type: FETCH_DOCUMENTS_REFRESH_ROLLBACK,
          },
        },
      },
    });
  };
};

export const deleteDocument = ([auditId, documentId, documentAuditId]) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_DOCUMENT,
      payload: {
        document_id: documentId,
      },
      meta: {
        offline: {
          effect: deleteDocumentById([auditId, documentId, documentAuditId]),
          commit: {
            type: DELETE_DOCUMENT_COMMIT,
            meta: {
              then: () => () => {
                toast.success('Document deleted');
              },
            },
          },
        },
      },
    });
  };
};

export const createDocument = ({
  auditId,
  documentAuditId,
  documentId,
  documentMetaData,
  fileName,
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInst(
        createDocumentObject({
          auditId,
          documentAuditId,
          documentId,
          documentMetaData,
        })
      )
        .then((res) => {
          dispatch({
            type: CREATE_DOCUMENT,
            payload: {
              data: res.data,
            },
          });
          resolve();
        })
        .catch((err) => {
          reject({
            name: fileName,
            isUploaded: false,
          });
        });
    });
  };
};

export const updateDocument = ({
  auditId,
  documentAuditId,
  documentId,
  documentMetaData,
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInst(
        updateDocumentObject({
          auditId,
          documentAuditId,
          documentId,
          documentMetaData,
        })
      )
        .then((res) => {
          dispatch({
            type: UPDATE_DOCUMENT,
            payload: {
              data: res.data,
            },
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};
