import React, { Fragment } from 'react';
import Form from 'react-bootstrap/Form';

const QuestionSection1 = ({ state, questions, onChange }) => {
  const handleChange = (e) => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    onChange(e.target.name, value);
  };

  return (
    <Fragment>
      <h5>Risk Assessment</h5>
      <Form.Group controlId='s1q1'>
        <Form.Label>{questions.s1q1}</Form.Label>
        <Form.Control
          size='sm'
          as='select'
          name='s1q1'
          value={state.s1q1 || ''}
          onChange={handleChange}
        >
          <option value=''>-</option>
          <option value='yes'>Yes</option>
          <option value='no'>No</option>
        </Form.Control>
      </Form.Group>

      {state.s1q1 === 'yes' && (
        <Form.Group controlId='s1q2'>
          <Form.Label>{questions.s1q2}</Form.Label>
          <Form.Control
            size='sm'
            as='select'
            name='s1q2'
            value={state.s1q2 || ''}
            onChange={handleChange}
          >
            <option value=''>-</option>
            <option value='yes'>Yes</option>
            <option value='no'>No</option>
          </Form.Control>
        </Form.Group>
      )}

      {state.s1q1 === 'yes' && state.s1q2 === 'yes' && (
        <Form.Group controlId='s1q3v1'>
          <Form.Label>{questions.s1q3v1}</Form.Label>
          <Form.Control
            size='sm'
            as='select'
            name='s1q3v1'
            value={state.s1q3v1 || ''}
            onChange={handleChange}
          >
            <option value=''>-</option>
            <option value='Breach of the defined control measures by the employee'>
              Breach of the defined control measures by the employee
            </option>
            <option value='Risk assessment was not communicated to the employee'>
              Risk assessment was not communicated to the employee
            </option>
            <option value='Failure in the implementation of the defined control measures'>
              Failure in the implementation of the defined control measures
            </option>
            <option value='Other'>Other</option>
          </Form.Control>
        </Form.Group>
      )}
      {state.s1q1 === 'yes' &&
        state.s1q2 === 'yes' &&
        state.s1q3v1 === 'Other' && (
          <Form.Group controlId='s1q3v1Other'>
            <Form.Label>{questions.s1q3v1Other}</Form.Label>
            <Form.Control
              as='textarea'
              rows='3'
              name='s1q3v1Other'
              value={state.s1q3v1Other || ''}
              onChange={handleChange}
            />
          </Form.Group>
        )}

      {state.s1q1 === 'yes' && state.s1q2 === 'no' && (
        <Fragment>
          <Form.Label>What was the reason behind?</Form.Label>
          <Form.Group controlId='s1q3v2c1'>
            <Form.Check
              type='checkbox'
              label={questions.s1q3v2c1}
              name='s1q3v2c1'
              checked={state.s1q3v2c1 || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId='s1q3v2c2'>
            <Form.Check
              type='checkbox'
              label={questions.s1q3v2c2}
              name='s1q3v2c2'
              checked={state.s1q3v2c2 || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId='s1q3v2c3'>
            <Form.Check
              type='checkbox'
              label={questions.s1q3v2c3}
              name='s1q3v2c3'
              checked={state.s1q3v2c3 || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId='s1q3v2c4'>
            <Form.Check
              type='checkbox'
              label={questions.s1q3v2c4}
              name='s1q3v2c4'
              checked={state.s1q3v2c4 || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId='s1q3v2c5'>
            <Form.Check
              type='checkbox'
              label={questions.s1q3v2c5}
              name='s1q3v2c5'
              checked={state.s1q3v2c5 || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId='s1q3v2c6'>
            <Form.Check
              type='checkbox'
              label={questions.s1q3v2c6}
              name='s1q3v2c6'
              checked={state.s1q3v2c6 || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId='s1q3v2cOther'>
            <Form.Check
              type='checkbox'
              label={questions.s1q3v2cOther}
              name='s1q3v2cOther'
              checked={state.s1q3v2cOther || ''}
              onChange={handleChange}
            />
          </Form.Group>
        </Fragment>
      )}

      {state.s1q1 === 'yes' && state.s1q2 === 'no' && state.s1q3v2cOther && (
        <Form.Group controlId='s1q3v2Other'>
          <Form.Label>{questions.s1q3v2Other}</Form.Label>
          <Form.Control
            as='textarea'
            rows='3'
            name='s1q3v2Other'
            value={state.s1q3v2Other || ''}
            onChange={handleChange}
          />
        </Form.Group>
      )}
    </Fragment>
  );
};

export default QuestionSection1;
