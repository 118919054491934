const dev = {
  apiGateway: {
    REGION: 'eu-central-1',
    URL: 'https://zgmzwa9s73.execute-api.eu-central-1.amazonaws.com/prod',
  },
  cognito: {
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_mQ5NNKzid',
    APP_CLIENT_ID: 'v30e4756rson1mk7g62p8l8u3',
    IDENTITY_POOL_ID: 'eu-central-1:3520f2d4-1966-4e4f-92f3-1cd4720af031',
    HOSTED_UI_URL:
      'https://dev-bsave.auth.eu-central-1.amazoncognito.com/login?client_id=v30e4756rson1mk7g62p8l8u3&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://zgmzwa9s73.execute-api.eu-central-1.amazonaws.com/prod/authentication',
  },
};

const test = {
  apiGateway: {
    REGION: 'eu-central-1',
    URL: 'https://0xvo4d2amc.execute-api.eu-central-1.amazonaws.com/prod',
  },
  cognito: {
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_lmxWQdksl',
    APP_CLIENT_ID: '6qil7m51v26bilh9dnc82j1ga5',
    IDENTITY_POOL_ID: 'eu-central-1:3520f2d4-1966-4e4f-92f3-1cd4720af031',
    HOSTED_UI_URL:
      'https://test-bsave.auth.eu-central-1.amazoncognito.com/login?client_id=6qil7m51v26bilh9dnc82j1ga5&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://0xvo4d2amc.execute-api.eu-central-1.amazonaws.com/prod/authentication',
  },
};

const prod = {
  apiGateway: {
    REGION: 'eu-central-1',
    URL: 'https://nk7t9yb7sd.execute-api.eu-central-1.amazonaws.com/prod',
  },
  cognito: {
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_NcWZ5Jgaj',
    APP_CLIENT_ID: '1vi6jk5qmso5vae6blai2r725n',
    IDENTITY_POOL_ID: 'eu-central-1:7fd18b3a-b5a5-44b0-b16a-3d984a5d5085',
    HOSTED_UI_URL:
      'https://prod-bsave.auth.eu-central-1.amazoncognito.com/login?client_id=1vi6jk5qmso5vae6blai2r725n&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://nk7t9yb7sd.execute-api.eu-central-1.amazonaws.com/prod/authentication',
  },
};

const config =
  process.env.REACT_APP_ENV === 'test'
    ? test
    : process.env.REACT_APP_ENV === 'prod'
    ? prod
    : dev;

export default config;
