import {
  FETCH_AUDIT_SUMMARY_INIT,
  FETCH_AUDIT_SUMMARY_INIT_COMMIT,
  FETCH_AUDIT_SUMMARY_REFRESH,
  FETCH_AUDIT_SUMMARY_REFRESH_COMMIT,
} from '../types/auditsSummaryTypes';

const initialState = {
  audits: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AUDIT_SUMMARY_INIT:
      return {
        ...state,
        audits: [
          ...state.audits,
          {
            audit_id: action.payload,
            summary: [],
            loading: true,
          },
        ],
      };
    case FETCH_AUDIT_SUMMARY_INIT_COMMIT:
      return {
        ...state,
        audits: state.audits.map((audit) =>
          audit.audit_id === action.payload.audit_id
            ? {
                ...audit,
                summary: action.payload.data.summary,
                loading: false,
              }
            : audit
        ),
      };
    case FETCH_AUDIT_SUMMARY_REFRESH:
      return {
        ...state,
        audits: state.audits.map((audit) =>
          audit.audit_id === action.payload
            ? {
                ...audit,
                loading: true,
              }
            : audit
        ),
      };
    case FETCH_AUDIT_SUMMARY_REFRESH_COMMIT:
      return {
        ...state,
        audits: state.audits.map((audit) =>
          audit.audit_id === action.meta.audit_id
            ? {
                ...audit,
                summary: action.payload.data.summary,
                loading: false,
              }
            : audit
        ),
      };
    default:
      return state;
  }
};
