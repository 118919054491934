import React, { Fragment } from 'react';
import PageName from '../layout/PageName';
import NavTiles from '../shared/nav-tiles/NavTiles';
import { useAppContext } from '../../utils/auth/context';
import Alert from 'react-bootstrap/Alert';
import { useSelector } from 'react-redux';

const Home = () => {
  const { authUser } = useAppContext();
  const isOnline = useSelector((state) => state.offline.online);

  const navTilesData = [
    {
      tileIcon: 'play-circle',
      tileLabel: 'Continue with ongoing audits',
      tooltipContent: 'Continue with ongoing audits',
      linkPath: '/select-audit',
    },
    ...(authUser && authUser.isAdmin
      ? [
          {
            tileIcon: 'file-alt',
            tileLabel: 'Edit interview catalogue',
            tooltipContent: `Edit interview catalogue - only in online mode`,
            linkPath: '/edit-questions',
            isOfflineDisabled: true,
          },
        ]
      : []),
  ];

  return (
    <Fragment>
      <PageName name='Home' />
      {!isOnline && authUser && authUser.isAdmin && (
        <Alert variant='info'>
          In offline mode some functionalities are limited - you can't edit
          interview catalogue.
        </Alert>
      )}
      <div className='mt-5'>
        <NavTiles navTilesData={navTilesData} />
      </div>
    </Fragment>
  );
};

export default Home;
