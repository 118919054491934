import latinize from './latinizeExtend';

// Convert full date string (e.g. "Thu Mar 25 2021 00:00:00 GMT+0100 (Central European Standard Time)")
// to YYYY/MM/DD
export const convertDate = (dateStr) => {
  if (dateStr) {
    const dateObj = new Date(dateStr);
    return `${dateObj.getFullYear()}/${
      dateObj.getMonth() + 1 < 10
        ? '0' + (dateObj.getMonth() + 1)
        : dateObj.getMonth() + 1
    }/${dateObj.getDate() < 10 ? '0' + dateObj.getDate() : dateObj.getDate()}`;
  } else {
    return '';
  }
};

// Replace diacritics to latin characters using 'latinize'
// allow only numbers, latin characters, .-_
// and replace all other (using regex negation ^) with '_'
export const convertUploadedFileName = (fileName) => {
  return latinize(fileName).replace(/[^a-zA-Z0-9_\-.]/g, '_');
};

// Convert HTML markup to plain text
// by placing html inside temporary div
// and then extracting only text with .innerText
export const convertHTMLToText = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.innerText;
};
