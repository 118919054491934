import React from 'react';
import NavTileItem from './NavTileItem';
import PropTypes from 'prop-types';

import './NavTiles.scss';

const NavTiles = ({ navTilesData }) => {
  return (
    <div className='nav-tile-container'>
      {navTilesData.map((item, index) => (
        <NavTileItem
          key={index}
          icon={item.tileIcon}
          label={item.tileLabel}
          tooltipContent={item.tooltipContent}
          linkPath={item.linkPath}
          id={index}
          isOfflineDisabled={item.isOfflineDisabled || false}
        />
      ))}
    </div>
  );
};

NavTiles.propTypes = {
  navTilesData: PropTypes.array.isRequired,
};

export default NavTiles;
