export const SET_LOADING = 'interviews/setLoading';
export const FETCH_INTERVIEWS_INIT = 'fetchInterviewsInit';
export const FETCH_INTERVIEWS_REFRESH_COMMIT = 'fetchInterviewsRefreshCommit';
export const FETCH_INTERVIEWS_REFRESH_ROLLBACK =
  'fetchInterviewsRefreshRollback';
export const FETCH_INTERVIEW_DETAILS_INIT = 'fetchInterviewDetailsInit';
export const FETCH_INTERVIEW_DETAILS_REFRESH = 'fetchInterviewDetailsRefresh';
export const FETCH_INTERVIEW_DETAILS_REFRESH_COMMIT =
  'fetchInterviewDetailsRefreshCommit';
export const FETCH_INTERVIEW_DETAILS_REFRESH_ROLLBACK =
  'fetchInterviewDetailsRefreshRollback';
export const UPDATE_INTERVIEW_ANSWER = 'updateInterviewAnswer';
export const UPDATE_INTERVIEW_ANSWER_COMMIT = 'updateInterviewAnswerCommit';
export const CREATE_INTERVIEW = 'createInterview';
export const CREATE_INTERVIEW_COMMIT = 'createInterviewCommit';
export const DELETE_INTERVIEW = 'deleteInterview';
export const DELETE_INTERVIEW_COMMIT = 'deleteInterviewCommit';
