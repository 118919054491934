import React from 'react';
import Form from 'react-bootstrap/Form';

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <Form.Group>
      <Form.Control
        size='sm'
        type='text'
        value={globalFilter || ''}
        onChange={(e) => {
          setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search All ...`}
      />
    </Form.Group>
  );
};

export default GlobalFilter;
