import React, { Fragment, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComments,
  faExclamationTriangle,
  faTachometerAlt,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAuditSummaryRefresh } from '../../../store/actions/auditsSummaryActions';
import useOverviewAndReportData from '../../../utils/customHooks/useOverviewAndReportData';

import './AuditKPIs.scss';

const AuditKPIs = () => {
  const { auditId } = useParams();
  const { apiSummary, loading, isOnline } = useSelector((state) => {
    const auditSummary = state.auditsSummary.audits.find(
      (audit) => audit.audit_id === parseInt(auditId)
    );
    return {
      apiSummary: auditSummary?.summary || [],
      loading: auditSummary?.loading || false,
      isOnline: state.offline.online,
    };
  });
  const [summaryData, setSummaryData] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isOnline) {
      dispatch(fetchAuditSummaryRefresh(auditId));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isOnline) {
      setSummaryData(apiSummary);
    } else {
      setSummaryData(storeSummary);
    }
    // eslint-disable-next-line
  }, [loading]);

  const {
    questionsAnsweredPercent,
    interviewsStarted,
    significantMSO,
    minorMSO,
    ctsObservations,
    minorObservations,
    riskFactor,
    employeesAndContractors,
  } = useOverviewAndReportData(auditId);

  const storeSummary = [
    {
      title: 'Interviews',
      kpis: [
        {
          type: 'percent',
          value: questionsAnsweredPercent,
          text: 'of all interview questions answered',
        },
        {
          type: 'number',
          value: interviewsStarted,
          text: 'Interview(s) started',
        },
      ],
    },
    {
      title: 'Management System Observations',
      kpis: [
        {
          type: 'number',
          value: significantMSO,
          text: 'Significant Management System Observation',
        },
        {
          type: 'number',
          value: minorMSO,
          text: 'Minor Management System Observation',
        },
      ],
    },
    {
      title: 'Site Observations',
      kpis: [
        { type: 'number', value: ctsObservations, text: 'CTS observation' },
        {
          type: 'number',
          value: minorObservations,
          text: 'Minor Site Observation',
        },
      ],
    },
    {
      title: 'Risk rating',
      kpis: [
        { type: 'float', value: riskFactor, text: 'Current risk rating' },
        {
          type: 'number',
          value: employeesAndContractors,
          text: 'Employees and contractors on site',
        },
      ],
    },
  ];

  const kpiIcons = {
    kpi1_1: faComments,
    kpi1_2: faComments,
    kpi2_1: faExclamationTriangle,
    kpi2_2: 'search',
    kpi3_1: faExclamationTriangle,
    kpi3_2: 'search',
    kpi4_1: faTachometerAlt,
    kpi4_2: faUsers,
  };

  // Links to respective tabs/pages; query params for additional prefiltering
  const kpiLinks = {
    kpi1_1: '#',
    kpi1_2: '#',
    kpi2_1: `/audit/${auditId}/interviews/select-interview?mso=significant`,
    kpi2_2: `/audit/${auditId}/interviews/select-interview?mso=minor`,
    kpi3_1: '#',
    kpi3_2: '#',
    kpi4_1: '#',
    kpi4_2: '#',
  };

  return (
    <Fragment>
      {loading && (
        <div className='text-center mb-3'>
          <FontAwesomeIcon icon='circle-notch' spin size='lg' />
        </div>
      )}
      {summaryData.length > 0 && (
        <Row>
          {summaryData.map((section, i) => (
            <Col sm={3} className='border-right' key={i}>
              <h5 className='kpi-column-header'>{section.title}</h5>
              {section.kpis.map((kpi, j) => (
                <Link
                  to={kpiLinks[`kpi${i + 1}_${j + 1}`]}
                  className='text-reset text-decoration-none'
                  key={`kpi${i + 1}_${j + 1}`}
                >
                  <div className='kpi-item border rounded'>
                    <FontAwesomeIcon
                      icon={kpiIcons[`kpi${i + 1}_${j + 1}`]}
                      size='5x'
                    />
                    {!isNaN(kpi.value) ? (
                      <div className='kpi-item-number'>
                        {kpi.type === 'percent'
                          ? `${Math.round(kpi.value)}%`
                          : kpi.value}
                      </div>
                    ) : (
                      <p className='text-danger'>
                        Enter no. of employees and contractors in Info tab
                      </p>
                    )}
                    <div className='kpi-item-title'>{kpi.text}</div>
                  </div>
                </Link>
              ))}
            </Col>
          ))}
        </Row>
      )}
      {!loading && summaryData.length === 0 && (
        <p className='text-center'>Audit overview data is not available.</p>
      )}
    </Fragment>
  );
};

export default AuditKPIs;
