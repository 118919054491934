import { createStore, applyMiddleware } from 'redux';
// import storage from 'redux-persist/lib/storage';
import * as localForage from 'localforage';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import rootreducer from './reducer';
import { createOffline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import offlineChain from 'redux-offline-chain';
import axiosInst from '../http/axiosConfig';
import customQueue from './offlineQueue';
import { photoUploadMiddleware } from './middlewares/photoUploadMiddleware';
import { photoUpdateMiddleware } from './middlewares/photoUpdateMiddleware';
import { photoDeleteMiddleware } from './middlewares/photoDeleteMiddleware';
import { photoRefreshMiddleware } from './middlewares/photoRefreshMiddleware';
import { interviewsRefreshMiddleware } from './middlewares/interviewsRefreshMiddleware';
import { observationsRefreshMiddleware } from './middlewares/observationsRefreshMiddleware';
import { exportAuditMiddleware } from './middlewares/exportAuditMiddleware';

const persistConfig = {
  key: 'beSafe',
  storage: localForage, // using localForage instead of localStorage
};

const effect = (effect, _action) => axiosInst(effect);
const discard = (error, _action, _retries) => {
  const { request, response } = error;
  if (!request) throw error; // There was an error creating the request
  if (!response) return false; // There was no response
  return 400 <= response.status && response.status < 500;
};
const detectNetwork = (callback) => {
  let currentStatus = false;
  let newStatus;
  window.addEventListener('offline', () => {
    callback(false);
    currentStatus = false;
  });
  callback(currentStatus);
  const url = new URL(window.location.origin);
  setInterval(
    (function poll() {
      url.searchParams.set(
        'rand',
        (Date.now() * Math.random()).toString(36).substring(2, 15)
      );
      fetch(url.toString(), {
        cache: 'no-cache',
        method: 'HEAD',
      })
        .then((response) => {
          if (response.ok) {
            newStatus = true;
          } else {
            newStatus = false;
          }
          if (newStatus !== currentStatus) {
            callback(newStatus);
            currentStatus = newStatus;
          }
        })
        .catch((err) => {
          newStatus = false;
          if (newStatus !== currentStatus) {
            callback(newStatus);
            currentStatus = newStatus;
          }
        });
      return poll;
    })(),
    5000
  );
};

const {
  middleware: offlineMiddleware,
  enhanceReducer: offlineEnhanceReducer,
  enhanceStore: offlineEnhanceStore,
} = createOffline({
  ...offlineConfig,
  persist: false,
  detectNetwork,
  effect,
  discard,
  queue: customQueue,
});

const persistedReducer = persistReducer(
  persistConfig,
  offlineEnhanceReducer(rootreducer)
);

const middleware = [
  thunk,
  photoUploadMiddleware,
  photoUpdateMiddleware,
  photoDeleteMiddleware,
  photoRefreshMiddleware,
  interviewsRefreshMiddleware,
  observationsRefreshMiddleware,
  exportAuditMiddleware,
];

const store = createStore(
  persistedReducer,
  composeWithDevTools(
    offlineEnhanceStore,
    applyMiddleware(...middleware, offlineMiddleware, offlineChain)
  )
);
const persistor = persistStore(store);

export { persistor, store };

// Source: https://gist.github.com/jarvisluong/f14872b9c7ed00bc2afc89c4622e3b55
