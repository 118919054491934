import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_COMMIT,
  FETCH_QUESTIONS,
  FETCH_QUESTIONS_COMMIT,
} from '../types/questionsTypes';

const initialState = {
  categories: {
    categories: [],
    loading: false,
  },
  questions: {
    questions: [],
    loading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: true,
        },
      };
    case FETCH_CATEGORIES_COMMIT:
      return {
        ...state,
        categories: {
          categories: action.payload.data,
          loading: false,
        },
      };
    case FETCH_QUESTIONS:
      return {
        ...state,
        questions: {
          ...state.questions,
          loading: true,
        },
      };
    case FETCH_QUESTIONS_COMMIT:
      return {
        ...state,
        questions: {
          questions: action.payload.data,
          loading: false,
        },
      };
    default:
      return state;
  }
};
