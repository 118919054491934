import React, { Fragment, useEffect, useState, useRef } from 'react';
import {
  Link,
  useLocation,
  useParams,
  useRouteMatch,
  Prompt,
} from 'react-router-dom';
import ImageEditor from '@toast-ui/react-image-editor';
import 'tui-image-editor/dist/tui-image-editor.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosInst from '../../../http/axiosConfig';
import { fetchSessionImages } from '../../../http/requests';
import Tags from '../../shared/tags/Tags';
import DispatchDeleteItemModal from '../../shared/modals/DispatchDeleteItemModal';
import LinkObservations from '../../shared/linking/LinkObservations';
import LinkQuestions from '../../shared/linking/LinkQuestions';
import LinkDocuments from '../../shared/linking/LinkDocuments';
import {
  readPhotosFromDB,
  getFileType,
  getPhotoKey,
  photosCategories,
} from '../../../utils/photos';
import { isItemLinked } from '../../../utils/linking';
import { useSelector, useDispatch } from 'react-redux';
import {
  updatePhotoMeta,
  addPhoto,
  getPresignedUrl,
  deletePhoto,
} from '../../../store/actions/photosActions';
import * as localForage from 'localforage';

import './EditPhoto.scss';

const EditPhoto = () => {
  const { auditId } = useParams();
  const dispatch = useDispatch();
  // match - used to check if we were redirected from
  // photo upload page or photo gallery page
  const match = useRouteMatch('/audit/:auditId/photo-upload/photo-edit');
  const location = useLocation();
  const { pickedPhotos } = useSelector((state) => {
    // Get metadata from redux store for photos with photo_ids
    // passed from photo gallery (in location.state)
    const photos =
      match === null
        ? state.photos.photos.filter(
            (photo) => location.state.indexOf(photo.photo_id) > -1
          )
        : null;
    return {
      pickedPhotos: photos,
    };
  });
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('sessionId');
  const fileCount = parseInt(queryParams.get('fileCount'));
  const offlinePath = queryParams.get('offline') === 'true';
  const photosCategory = queryParams.get('category');
  let pollInterval;
  let imageListRef = useRef(null);
  let pageTypeRef = useRef(null);
  const imageEditor = useRef(null);

  const [loading, setLoading] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [currentImageConfirmed, setCurrentImageConfirmed] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [flowEndModalShow, setFlowEndModalShow] = useState(false);
  const [pageType, setPageType] = useState('');
  const [photoBlobs, setPhotoBlobs] = useState({});
  const [currentImageLoaded, setCurrentImageLoaded] = useState(false);

  useEffect(() => {
    if (match !== null) {
      // Case - redirected from photo upload page
      setPageType('upload');
      pageTypeRef.current = 'upload';
      if (!offlinePath) {
        // Start polling for blurred images
        startPoll();
      } else {
        // Read photos from location.state
        const d = new Date();
        // Align timestamp to AWS (UTC) time
        const timestamp = d.getTime() + d.getTimezoneOffset() * 60 * 1000;
        const photos = location.state.map((file, index) => {
          return {
            preview_url: URL.createObjectURL(file),
            file,
            photo_id: `${timestamp + index}#${file.name}`,
            audit_id: parseInt(auditId),
            upload_file_name: file.name,
            creation_timestamp: timestamp,
            update_timestamp: timestamp,
            session_id: timestamp + index,
            description: '',
            tags: [],
            link_observations: [],
            link_questions: [],
            link_documents: [],
            edited_manually: false,
            category: photosCategory,
          };
        });
        setImageList(photos);
        setCurrentImageIndex(0);
      }
    } else {
      // Case - redirected from photo gallery page
      async function readBlobs(photoArray) {
        const photoBlobsFromDB = await readPhotosFromDB(photoArray);
        setPhotoBlobs(photoBlobsFromDB);
      }
      readBlobs(pickedPhotos);
      setPageType('gallery');
      pageTypeRef.current = 'gallery';
      // Add photos to component state and decode the 'description' field
      setImageList(
        pickedPhotos.map((image) => {
          return {
            ...image,
            description: image.description ? decodeURI(image.description) : '',
            tags: image.tags || [],
            link_observations: image.link_observations || [],
            link_questions: image.link_questions || [],
            link_documents: image.link_documents || [],
          };
        })
      );
      setCurrentImageIndex(0);
    }

    // Trigger function when user leaves the page
    return () => {
      if (pageTypeRef.current === 'upload') {
        clearInterval(pollInterval);
        handleLeavePage();
      }
    };
    // eslint-disable-next-line
  }, []);

  const handleLeavePage = () => {
    // Check if there are unconfirmed photos remaining
    if (imageListRef.current && imageListRef.current.length > 0) {
      // Case 'Yes' - call a delete method to remove them from S3 bucket
      imageListRef.current.forEach(({ photo_id }) =>
        dispatch(deletePhoto([auditId, photo_id]))
      );
    }
  };

  const handleRemoveImageFromUI = () => {
    // Uncheck and disable the confirmation checkbox
    setCurrentImageConfirmed(false);
    setCurrentImageLoaded(false);
    // Remove image from UI
    const updatedImageList = imageList.filter(
      (item, index) => index !== currentImageIndex
    );
    setImageList(updatedImageList);
    imageListRef.current = updatedImageList;

    // Remove image id from location.state
    location.state = updatedImageList.map((item) => item.photo_id);

    if (updatedImageList.length) {
      setCurrentImageIndex(0);
    } else if (updatedImageList.length === 0 && pageType === 'upload') {
      setFlowEndModalShow(true);
    }
  };

  const getImageBlob = (image) => {
    return fetch(image.photo_url)
      .then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.blob();
      })
      .then((blob) => {
        return {
          ...image,
          preview_url: URL.createObjectURL(blob),
          file: new File([blob], image.upload_file_name, {
            type: `image/${getFileType(image.upload_file_name)}`,
          }),
        };
      })
      .catch((err) => {
        return image;
      });
  };

  const startPoll = () => {
    setLoading(true);
    pollInterval = setInterval(() => {
      axiosInst(fetchSessionImages({ auditId, sessionId }))
        .then((response) => {
          if (response.data.length === fileCount) {
            clearInterval(pollInterval);
            const notConfirmedImages = response.data
              .filter((item) => !item.confirmed)
              .map((item) => {
                return {
                  ...item,
                  description: '',
                  tags: [],
                  link_observations: [],
                  link_questions: [],
                  link_documents: [],
                };
              });
            // Fetching image to set 'preview_url' and 'file' in case of later network connection loss
            Promise.all(notConfirmedImages.map((image) => getImageBlob(image)))
              .then((res) => {
                setLoading(false);
                setImageList(res);
                setCurrentImageIndex(0);
                imageListRef.current = res;
              })
              .catch((err) => {
                setLoading(false);
                setImageList(notConfirmedImages);
                setCurrentImageIndex(0);
                imageListRef.current = notConfirmedImages;
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 3000);
  };

  const selectImage = (index) => {
    if (index !== currentImageIndex) {
      setCurrentImageLoaded(false);
    }
    setCurrentImageIndex(index);
    setCurrentImageConfirmed(false);
  };

  const handleSaveImageChanges = () => {
    const imageEditorInst = imageEditor.current.imageEditorInst; // Image Editor object
    // Convert image editor output from base64 to File
    const data = imageEditorInst.toDataURL(); // data - base64 string
    fetch(data)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File(
          [blob],
          imageList[currentImageIndex].upload_file_name,
          {
            type: `image/${getFileType(
              imageList[currentImageIndex].upload_file_name
            )}`,
          }
        );
        const updatedPreview = URL.createObjectURL(blob);
        setImageList(
          imageList.map((image) => {
            if (image.photo_id === imageList[currentImageIndex].photo_id) {
              return {
                ...image,
                edited_manually: true,
                reedited: true,
                preview_url: updatedPreview,
                file: file,
                photoChanged: true,
              };
            } else {
              return image;
            }
          })
        );
        setEditMode(false);
      });
  };

  const getPhotoObjForUpdate = (photo) => {
    return {
      description: photo.description ? encodeURI(photo.description) : '',
      edited_manually: photo.edited_manually || false,
      tags: photo.tags || [],
      link_observations: photo.link_observations || [],
      link_questions: photo.link_questions || [],
      link_documents: photo.link_documents || [],
      category: photo.category,
      confirmed: true,
    };
  };

  const getSelectedImageBlob = () => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const img = document.getElementById('selected-image');
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      context.drawImage(img, 0, 0);
      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        'image/jpeg',
        0.95
      );
    });
  };

  const handleSaveSelectedImage = async () => {
    const photo = imageList[currentImageIndex];
    const {
      preview_url,
      file,
      reedited,
      photoChanged,
      photoMetaChanged,
      thumbnail_url,
      upload_url,
      ...photoObj
    } = photo;
    if (offlinePath) {
      localForage.setItem(getPhotoKey(photoObj), file).then((res) => {
        // Add photo to redux store
        dispatch(addPhoto({ ...photoObj, confirmed: true }));
        // Dispatch action to get presigned url data for file upload
        dispatch(
          getPresignedUrl({
            auditId,
            timestamp: photoObj.session_id,
            category: photoObj.category,
            photoObj,
          })
        );
        handleRemoveImageFromUI();
      });
      return;
    }
    if (pageType === 'upload' && !offlinePath) {
      let fileToStore;
      if (file) {
        fileToStore = file;
      } else {
        const blob = await getSelectedImageBlob();
        fileToStore = new File([blob], photoObj.upload_file_name, {
          type: `image/${getFileType(photoObj.upload_file_name)}`,
        });
      }
      await localForage.setItem(getPhotoKey(photoObj), fileToStore);
      dispatch(addPhoto({ ...photoObj, confirmed: true }));
      if (imageList[currentImageIndex].edited_manually) {
        dispatch(
          getPresignedUrl({
            auditId,
            timestamp: photoObj.session_id,
            category: photoObj.category,
            photoObj,
          })
        );
        handleRemoveImageFromUI();
      } else {
        updateCurrentImageMeta();
      }
      return;
    }
    if (pageType === 'gallery') {
      if (imageList[currentImageIndex].reedited) {
        // Replace file in localForage under the same key
        const currentFileKey = getPhotoKey(photoObj);
        const currentFile = await localForage.getItem(currentFileKey);
        if (currentFile) {
          await localForage.removeItem(currentFileKey);
          await localForage.setItem(currentFileKey, file);
          dispatch(
            getPresignedUrl({
              auditId,
              timestamp: photoObj.session_id,
              category: photoObj.category,
              photoObj,
            })
          );
          if (imageList[currentImageIndex].photoMetaChanged) {
            // If photo meta changed and network is offline
            // update photo meta in redux store before 'getPresignedUrl' is synced
            const imageObj = getPhotoObjForUpdate(photoObj);
            dispatch(
              updatePhotoMeta({
                auditId,
                photoId: photoObj.photo_id,
                imageData: imageObj,
              })
            );
          }
          handleRemoveImageFromUI();
        }
      } else {
        updateCurrentImageMeta();
      }
    }
  };

  const updateCurrentImageMeta = () => {
    const imageObj = getPhotoObjForUpdate(imageList[currentImageIndex]);

    dispatch(
      updatePhotoMeta({
        auditId,
        photoId: imageList[currentImageIndex].photo_id,
        imageData: imageObj,
      })
    );
    handleRemoveImageFromUI();
    setSaving(false);
  };

  const handleImageMetaChange = (e) => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setImageList(
      imageList.map((item, index) => {
        if (index === currentImageIndex) {
          return {
            ...item,
            [e.target.name]: value,
            photoMetaChanged: true,
          };
        } else {
          return item;
        }
      })
    );
  };

  const handleAddTag = (newTag) => {
    setImageList(
      imageList.map((item, index) => {
        if (index === currentImageIndex) {
          return {
            ...item,
            tags: item.tags.concat([newTag]),
            photoMetaChanged: true,
          };
        } else {
          return item;
        }
      })
    );
  };

  const handleRemoveTag = (tagName) => {
    setImageList(
      imageList.map((item, index) => {
        if (index === currentImageIndex) {
          return {
            ...item,
            tags: item.tags.filter((tag) => tag !== tagName),
            photoMetaChanged: true,
          };
        } else {
          return item;
        }
      })
    );
  };

  const handleAddLinkedQuestion = (linkedQuestion) => {
    setImageList(
      imageList.map((item, index) => {
        if (index === currentImageIndex) {
          return {
            ...item,
            link_questions: item.link_questions.concat([linkedQuestion]),
            photoMetaChanged: true,
          };
        } else {
          return item;
        }
      })
    );
  };

  const handleRemoveLinkedQuestion = (linkedQuestion) => {
    setImageList(
      imageList.map((item, index) => {
        if (index === currentImageIndex) {
          return {
            ...item,
            link_questions: item.link_questions.filter(
              (question) => question !== linkedQuestion
            ),
            photoMetaChanged: true,
          };
        } else {
          return item;
        }
      })
    );
  };

  const handleAddLinkedDocument = (linkedDoc) => {
    setImageList(
      imageList.map((item, index) => {
        if (index === currentImageIndex) {
          return {
            ...item,
            link_documents: item.link_documents.concat([linkedDoc]),
            photoMetaChanged: true,
          };
        } else {
          return item;
        }
      })
    );
  };

  const handleRemoveLinkedDocument = (linkedDoc) => {
    setImageList(
      imageList.map((item, index) => {
        if (index === currentImageIndex) {
          return {
            ...item,
            link_documents: item.link_documents.filter(
              (doc) => doc !== linkedDoc
            ),
            photoMetaChanged: true,
          };
        } else {
          return item;
        }
      })
    );
  };

  const handleAddLinkedObservation = (linkedObs) => {
    setImageList(
      imageList.map((item, index) => {
        if (index === currentImageIndex) {
          return {
            ...item,
            link_observations: item.link_observations.concat([linkedObs]),
            photoMetaChanged: true,
          };
        } else {
          return item;
        }
      })
    );
  };

  const handleRemoveLinkedObservation = (linkedObs) => {
    setImageList(
      imageList.map((item, index) => {
        if (index === currentImageIndex) {
          return {
            ...item,
            link_observations: item.link_observations.filter(
              (doc) => doc !== linkedObs
            ),
            photoMetaChanged: true,
          };
        } else {
          return item;
        }
      })
    );
  };

  return (
    <Fragment>
      {pageType === 'upload' && (
        <Prompt
          when={imageList.length > 0}
          message='Please confirm if remaining photos were anonymized (edit them manually if required). If you leave this page now, unconfirmed photos will be deleted. Are you sure you want to leave?'
        />
      )}
      {pageType === 'gallery' && (
        <Prompt
          when={imageList.some(
            (image) => image.photoChanged || image.photoMetaChanged
          )}
          message='You have unsaved changes. Are you sure you want to leave?'
        />
      )}
      {!editMode ? (
        <Row>
          <Col sm={6}>
            {loading && !imageList.length ? (
              <Fragment>
                <div className='text-center mb-4'>
                  <FontAwesomeIcon icon='circle-notch' spin size='lg' />
                </div>
                <div className='image-list mb-4'>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div className='current-image-placeholder'></div>
              </Fragment>
            ) : !loading && imageList.length > 0 ? (
              <Fragment>
                <p>Select image:</p>
                <div className='image-list mb-4'>
                  {imageList.map((img, index) => (
                    <img
                      key={index}
                      src={
                        img.preview_url
                          ? img.preview_url
                          : offlinePath || pageType === 'gallery'
                          ? photoBlobs[img.photo_id]
                          : img.photo_url
                      }
                      alt=''
                      className='pointer'
                      onClick={() => selectImage(index)}
                    />
                  ))}
                </div>
                {imageList[currentImageIndex] && (
                  <Fragment>
                    <div>
                      <img
                        id='selected-image'
                        crossOrigin='anonymous'
                        src={
                          imageList[currentImageIndex].preview_url
                            ? imageList[currentImageIndex].preview_url
                            : offlinePath || pageType === 'gallery'
                            ? photoBlobs[imageList[currentImageIndex].photo_id]
                            : imageList[currentImageIndex].photo_url
                        }
                        alt=''
                        onLoad={() => setCurrentImageLoaded(true)}
                      />
                    </div>
                    <Button
                      variant='primary'
                      className='mt-3'
                      onClick={() => setEditMode(true)}
                    >
                      Edit selected image
                    </Button>
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <p>No images left.</p>
            )}
          </Col>
          <Col sm={3}>
            <div>
              <p>Linked elements:</p>
              <LinkObservations
                linked={
                  imageList[currentImageIndex] &&
                  imageList[currentImageIndex].link_observations
                    ? imageList[currentImageIndex].link_observations
                    : []
                }
                onAddLinkedObservation={handleAddLinkedObservation}
                onRemoveLinkedObservation={handleRemoveLinkedObservation}
              />
              <LinkQuestions
                linked={
                  imageList[currentImageIndex] &&
                  imageList[currentImageIndex].link_questions
                    ? imageList[currentImageIndex].link_questions
                    : []
                }
                onAddLinkedQuestion={handleAddLinkedQuestion}
                onRemoveLinkedQuestion={handleRemoveLinkedQuestion}
              />
              <LinkDocuments
                linked={
                  imageList[currentImageIndex] &&
                  imageList[currentImageIndex].link_documents
                    ? imageList[currentImageIndex].link_documents
                    : []
                }
                onAddLinkedDocument={handleAddLinkedDocument}
                onRemoveLinkedDocument={handleRemoveLinkedDocument}
              />
              <Tags
                tags={
                  imageList[currentImageIndex] &&
                  imageList[currentImageIndex].tags
                    ? imageList[currentImageIndex].tags
                    : []
                }
                onAddTag={handleAddTag}
                onRemoveTag={handleRemoveTag}
              />
            </div>
            <Form.Group controlId='photos_category'>
              <Form.Label>Photos Category *</Form.Label>
              <Form.Control
                size='sm'
                as='select'
                name='category'
                value={
                  imageList[currentImageIndex]
                    ? imageList[currentImageIndex].category
                    : ''
                }
                onChange={handleImageMetaChange}
              >
                <option value='' disabled>
                  -
                </option>
                {photosCategories &&
                  photosCategories.map((category, i) => (
                    <option value={category.value} key={i}>
                      {category.label}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <div className='d-flex flex-column justify-content-between h-100'>
              <Form>
                <Form.Group controlId='description'>
                  <Form.Label>Description:</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows='6'
                    name='description'
                    value={
                      imageList[currentImageIndex]
                        ? imageList[currentImageIndex].description
                        : ''
                    }
                    onChange={handleImageMetaChange}
                  />
                </Form.Group>
              </Form>
              {!loading && imageList.length ? (
                <div>
                  {pageType === 'gallery' && (
                    <Link to={`/audit/${auditId}/photo-gallery`}>
                      <Button variant='secondary' block className='mb-3'>
                        Back to Photo Gallery
                      </Button>
                    </Link>
                  )}
                  <Button
                    variant='danger'
                    block
                    className='mb-3'
                    onClick={() => setDeleteModalShow(true)}
                  >
                    Delete selected image
                  </Button>
                  {pageType === 'upload' ? (
                    <Fragment>
                      <Form.Group controlId='confirmImageAnonymized'>
                        <Form.Check
                          type='checkbox'
                          label='Face and personality not recognizable *'
                          name='isAnonymized'
                          checked={currentImageConfirmed}
                          disabled={!currentImageLoaded}
                          onChange={() =>
                            setCurrentImageConfirmed(!currentImageConfirmed)
                          }
                        />
                      </Form.Group>
                      <Button
                        variant='primary'
                        disabled={
                          !currentImageConfirmed ||
                          !imageList[currentImageIndex].category
                        }
                        block
                        onClick={handleSaveSelectedImage}
                      >
                        {saving && (
                          <FontAwesomeIcon
                            icon='circle-notch'
                            spin
                            className='mr-2'
                          />
                        )}
                        Confirm and save selected image
                      </Button>
                    </Fragment>
                  ) : (
                    <Button
                      variant={
                        imageList[currentImageIndex] &&
                        (imageList[currentImageIndex].photoChanged ||
                          imageList[currentImageIndex].photoMetaChanged)
                          ? 'warning'
                          : 'primary'
                      }
                      disabled={!imageList[currentImageIndex].category}
                      block
                      onClick={handleSaveSelectedImage}
                    >
                      {saving && (
                        <FontAwesomeIcon
                          icon='circle-notch'
                          spin
                          className='mr-2'
                        />
                      )}
                      Save selected image
                    </Button>
                  )}
                </div>
              ) : !loading && imageList.length === 0 ? (
                <div>
                  <Link to={`/audit/${auditId}/photo-upload`}>
                    <Button variant='primary' block className='mb-2'>
                      Upload more photos
                    </Button>
                  </Link>
                  <Link to={`/audit/${auditId}/photo-gallery`}>
                    <Button variant='primary' block>
                      Go to Photo Gallery
                    </Button>
                  </Link>
                </div>
              ) : (
                ''
              )}
            </div>
          </Col>
        </Row>
      ) : (
        <div>
          <ImageEditor
            includeUI={{
              loadImage: {
                path: imageList[currentImageIndex].preview_url
                  ? imageList[currentImageIndex].preview_url
                  : offlinePath || pageType === 'gallery'
                  ? photoBlobs[imageList[currentImageIndex].photo_id]
                  : imageList[currentImageIndex].photo_url,
                name: imageList[currentImageIndex].photo_id,
              },
              // theme: blackTheme, // or whiteTheme
              menu: ['draw', 'shape', 'icon', 'rotate', 'text'],
              initMenu: 'draw',
              uiSize: {
                width: '100%',
                height: '700px',
              },
              menuBarPosition: 'bottom',
            }}
            cssMaxHeight={500}
            cssMaxWidth={700}
            selectionStyle={{
              cornerSize: 10,
              cornerColor: '#ffffff',
              cornerStrokeColor: '#000000',
              rotatingPointOffset: 40,
            }}
            usageStatistics={false}
            ref={imageEditor}
          />

          <Button
            variant='secondary'
            className='mt-2 mr-2'
            onClick={() => setEditMode(false)}
          >
            Cancel
          </Button>
          <Button
            variant='primary'
            className='mt-2 mr-2'
            onClick={handleSaveImageChanges}
          >
            Save changes
          </Button>
        </div>
      )}

      <DispatchDeleteItemModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        selectedItemId={
          imageList[currentImageIndex]
            ? imageList[currentImageIndex].photo_id
            : ''
        }
        isLinked={
          imageList[currentImageIndex]
            ? isItemLinked([imageList[currentImageIndex]])
            : []
        }
        handleRemoveFromTable={handleRemoveImageFromUI}
        deleteAction={deletePhoto}
        auditId={auditId}
      />

      <Modal
        show={flowEndModalShow}
        onHide={() => setFlowEndModalShow(false)}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Photos processed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          All uploaded photos were processed. You can now browse them in{' '}
          <strong>Photo Gallery</strong> or <strong>Upload more photos</strong>.
        </Modal.Body>
        <Modal.Footer>
          <Link to={`/audit/${auditId}/photo-gallery`}>
            <Button variant='primary'>Photo Gallery</Button>
          </Link>
          <Link to={`/audit/${auditId}/photo-upload`}>
            <Button variant='primary'>Upload Photos</Button>
          </Link>
        </Modal.Footer>
      </Modal>

      {/* <div className='toast-wrapper'>
        <Toast
          onClose={() => setToastShow(false)}
          show={toastShow}
          delay={4000}
          autohide
          className='toast-success'
        >
          <Toast.Header>
            <strong className='mr-auto'>beSafe</strong>
          </Toast.Header>
          <Toast.Body>Photo was successfully saved.</Toast.Body>
        </Toast>
      </div> */}
    </Fragment>
  );
};

export default EditPhoto;
