import { toTimestamp } from './convertId';

export const sortByEntityId = (a, b) => {
  return toTimestamp(a.entity_id) - toTimestamp(b.entity_id);
};

export const sortPhotos = (a, b) => {
  return parseInt(a.session_id) - parseInt(b.session_id);
};

export const sortMPAsByMPAId = (a, b) => {
  return parseInt(a.mpa_id) - parseInt(b.mpa_id);
};

export const sortDocuments = (a, b) => {
  return a.document_id - b.document_id;
};

export const sortInterviewQuestions = (a, b) => {
  return a.question_id.localeCompare(b.question_id, 'en', {
    numeric: true,
  });
};

export const sortInterviewCategories = (a, b) => {
  return a.category_letter > b.category_letter
    ? 1
    : a.category_letter < b.category_letter
    ? -1
    : 0;
};
