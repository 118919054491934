import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DispatchDeleteItemModal from '../../shared/modals/DispatchDeleteItemModal';
import TableFiltered from '../../shared/table/TableFiltered';
import SelectColumnFilter from '../../shared/table/SelectColumnFilter';
import { isItemLinked } from '../../../utils/linking';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchObservationsRefresh,
  deleteObservation,
} from '../../../store/actions/observationsActions';
import { toUrlId } from '../../../utils/convertId';

const SelectObservation = () => {
  const { auditId } = useParams();
  const dispatch = useDispatch();
  const { apiData, loading, isOnline, observationRequestsPending } =
    useSelector((state) => ({
      apiData: state.observations.observations,
      loading: state.observations.loading,
      isOnline: state.offline.online,
      observationRequestsPending: state.observations.observations.some(
        (observation) => observation.hasOwnProperty('loading')
      ),
    }));
  const [modalShow, setModalShow] = useState(false);
  const [selectedFlatRows, setSelectedFlatRows] = useState([]);

  const showTableFilters = true;

  useEffect(() => {
    if (isOnline) {
      dispatch(fetchObservationsRefresh(auditId));
    }
    // eslint-disable-next-line
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        accessor: 'oindex',
        disableFilters: true,
      },
      {
        Header: 'In report',
        accessor: 'in_report',
        disableFilters: true,
        Cell: ({ value }) => {
          return value ? 'Yes' : 'No';
        },
      },
      {
        Header: 'Type',
        accessor: 'observation_type',
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        Header: 'OS Aspect Category',
        accessor: 'aspect_category',
        disableFilters: true,
      },
      {
        Header: 'Description',
        accessor: 'description',
        disableFilters: true,
        Cell: ({ value }) => {
          const lengthLimit = 170;
          const decodedValue = decodeURI(value);
          return decodedValue.length > lengthLimit
            ? decodedValue.slice(0, lengthLimit).trim() + '...'
            : decodedValue.slice(0, lengthLimit);
        },
      },
      {
        Header: 'Area',
        accessor: 'area',
        disableFilters: true,
      },
    ],
    []
  );

  const data = React.useMemo(
    () =>
      apiData.filter(
        (observation) => observation.audit_id === parseInt(auditId)
      ),
    [apiData, auditId]
  );

  const handleChangeSelection = useCallback((row) => {
    setSelectedFlatRows(row);
  }, []);

  return (
    <Fragment>
      {(loading || observationRequestsPending) && (
        <Row>
          <Col>
            <div className='text-center mb-3'>
              <FontAwesomeIcon icon='circle-notch' spin size='lg' />
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <TableFiltered
          data={data}
          columns={columns}
          onChangeSelection={handleChangeSelection}
          showFilters={showTableFilters}
        />
      </Row>
      <Row>
        <Col sm={showTableFilters ? { offset: 3, span: 9 } : 12}>
          {!loading && !observationRequestsPending && apiData.length > 0 && (
            <div className='text-right'>
              <Button
                variant='danger'
                className='mr-2'
                disabled={selectedFlatRows.length !== 1}
                onClick={() => setModalShow(true)}
              >
                Delete
              </Button>
              <Link
                to={`/audit/${auditId}/observations/${
                  selectedFlatRows[0]
                    ? toUrlId(selectedFlatRows[0].original.entity_id)
                    : 0
                }`}
              >
                <Button
                  variant='primary'
                  disabled={selectedFlatRows.length !== 1}
                >
                  Edit
                </Button>
              </Link>
            </div>
          )}
        </Col>
      </Row>

      <DispatchDeleteItemModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        selectedItemId={
          selectedFlatRows.length > 0
            ? toUrlId(selectedFlatRows[0].original.entity_id)
            : ''
        }
        isLinked={
          selectedFlatRows.length > 0
            ? isItemLinked([selectedFlatRows[0].original])
            : false
        }
        deleteAction={deleteObservation}
        auditId={auditId}
      />
    </Fragment>
  );
};

export default SelectObservation;
