import React, { Fragment } from 'react';
import Form from 'react-bootstrap/Form';

const QuestionSection2 = ({ state, questions, onChange }) => {
  const handleChange = (e) => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    onChange(e.target.name, value);
  };

  return (
    <Fragment>
      <h5>Work Permit</h5>
      <Form.Group controlId='s2q1'>
        <Form.Label>{questions.s2q1}</Form.Label>
        <Form.Control
          size='sm'
          as='select'
          name='s2q1'
          value={state.s2q1 || ''}
          onChange={handleChange}
        >
          <option value=''>-</option>
          <option value='yes'>Yes</option>
          <option value='no'>No</option>
          <option value='na'>N/A</option>
        </Form.Control>
      </Form.Group>

      {state.s2q1 === 'yes' && (
        <Form.Group controlId='s2q2'>
          <Form.Label>{questions.s2q2}</Form.Label>
          <Form.Control
            size='sm'
            as='select'
            name='s2q2'
            value={state.s2q2 || ''}
            onChange={handleChange}
          >
            <option value=''>-</option>
            <option value='yes'>Yes</option>
            <option value='no'>No</option>
          </Form.Control>
        </Form.Group>
      )}

      {state.s2q1 === 'yes' && state.s2q2 === 'yes' && (
        <Form.Group controlId='s2q3'>
          <Form.Label>{questions.s2q3}</Form.Label>
          <Form.Control
            size='sm'
            as='select'
            name='s2q3'
            value={state.s2q3 || ''}
            onChange={handleChange}
          >
            <option value=''>-</option>
            <option value='yes'>Yes</option>
            <option value='no'>No</option>
          </Form.Control>
        </Form.Group>
      )}
      {state.s2q1 === 'yes' && state.s2q2 === 'yes' && state.s2q3 === 'yes' && (
        <Form.Group controlId='s2q4v1'>
          <Form.Label>{questions.s2q4v1}</Form.Label>
          <Form.Control
            size='sm'
            as='select'
            name='s2q4v1'
            value={state.s2q4v1 || ''}
            onChange={handleChange}
          >
            <option value=''>-</option>
            <option value='Work permit was not communicated to the employee'>
              Work permit was not communicated to the employee
            </option>
            <option value='Defined control measures were not followed'>
              Defined control measures were not followed
            </option>
            <option value='Other'>Other</option>
          </Form.Control>
        </Form.Group>
      )}

      {state.s2q1 === 'yes' &&
        state.s2q2 === 'yes' &&
        state.s2q3 === 'yes' &&
        state.s2q4v1 === 'Other' && (
          <Form.Group controlId='s2q4v1Other'>
            <Form.Label>{questions.s2q4v1Other}</Form.Label>
            <Form.Control
              as='textarea'
              rows='3'
              name='s2q4v1Other'
              value={state.s2q4v1Other || ''}
              onChange={handleChange}
            />
          </Form.Group>
        )}

      {state.s2q1 === 'yes' && state.s2q2 === 'yes' && state.s2q3 === 'no' && (
        <Fragment>
          <Form.Label>What was the reason behind?</Form.Label>
          <Form.Group controlId='s2q4v2c1'>
            <Form.Check
              type='checkbox'
              label={questions.s2q4v2c1}
              name='s2q4v2c1'
              checked={state.s2q4v2c1 || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId='s2q4v2c2'>
            <Form.Check
              type='checkbox'
              label={questions.s2q4v2c2}
              name='s2q4v2c2'
              checked={state.s2q4v2c2 || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId='s2q4v2c3'>
            <Form.Check
              type='checkbox'
              label={questions.s2q4v2c3}
              name='s2q4v2c3'
              checked={state.s2q4v2c3 || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId='s2q4v2c4'>
            <Form.Check
              type='checkbox'
              label={questions.s2q4v2c4}
              name='s2q4v2c4'
              checked={state.s2q4v2c4 || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId='s2q4v2c5'>
            <Form.Check
              type='checkbox'
              label={questions.s2q4v2c5}
              name='s2q4v2c5'
              checked={state.s2q4v2c5 || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId='s2q4v2cOther'>
            <Form.Check
              type='checkbox'
              label={questions.s2q4v2cOther}
              name='s2q4v2cOther'
              checked={state.s2q4v2cOther || ''}
              onChange={handleChange}
            />
          </Form.Group>
        </Fragment>
      )}

      {state.s2q1 === 'yes' &&
        state.s2q2 === 'yes' &&
        state.s2q3 === 'no' &&
        state.s2q4v2cOther && (
          <Form.Group controlId='s2q4v2Other'>
            <Form.Label>{questions.s2q4v2Other}</Form.Label>
            <Form.Control
              as='textarea'
              rows='3'
              name='s2q4v2Other'
              value={state.s2q4v2Other || ''}
              onChange={handleChange}
            />
          </Form.Group>
        )}
    </Fragment>
  );
};

export default QuestionSection2;
