import React, { Fragment } from 'react';
import PageName from '../layout/PageName';
import Nav from 'react-bootstrap/Nav';
import { NavLink, Switch, useParams } from 'react-router-dom';
import AuthRoute from '../auth/AuthRoute';
import OverviewTab from '../auditDetails/OverviewTab';
import InterviewsTab from '../auditDetails/InterviewsTab';
import ObservationsTab from '../auditDetails/ObservationsTab';
import PhotoUploadTab from '../auditDetails/PhotoUploadTab';
import PhotoGalleryTab from '../auditDetails/PhotoGalleryTab';
import ReportingTab from '../auditDetails/ReportingTab';
import InfoTab from '../auditDetails/InfoTab';

import DocumentsTab from '../auditDetails/DocumentsTab';
import SelectDocument from '../auditDetails/documents/SelectDocument';
import UploadDocument from '../auditDetails/documents/UploadDocument';
import EditDocument from '../auditDetails/documents/EditDocument';

import StartNewInterview from '../auditDetails/interviews/StartNewInterview';
import SelectInterview from '../auditDetails/interviews/SelectInterview';
import InterviewQuestions from '../auditDetails/interviews/InterviewQuestions';

import ObservationForm from '../auditDetails/observations/ObservationForm';
import SelectObservation from '../auditDetails/observations/SelectObservation';
import GoodPracticeForm from '../auditDetails/observations/GoodPracticeForm';
import SelectGoodPractice from '../auditDetails/observations/SelectGoodPractice';

import EditPhoto from '../auditDetails/photoUpload/EditPhoto';

import useAuditExported from '../../utils/customHooks/useAuditExported';

import './AuditDetails.scss';

const AuditDetails = () => {
  const { auditId } = useParams();
  const isAuditExported = useAuditExported();

  return (
    <Fragment>
      <PageName name='Audit Details' isAuditExported={isAuditExported} />
      <Nav fill>
        <NavLink
          to={`/audit/${auditId}/overview`}
          className='nav-link'
          activeClassName='akt'
        >
          Overview
        </NavLink>
        <NavLink
          to={`/audit/${auditId}/interviews`}
          className='nav-link'
          activeClassName='akt'
        >
          Interviews
        </NavLink>
        <NavLink
          to={`/audit/${auditId}/observations`}
          className='nav-link'
          activeClassName='akt'
        >
          Observations
        </NavLink>
        <NavLink
          to={`/audit/${auditId}/photo-upload`}
          className='nav-link'
          activeClassName='akt'
        >
          Photo Upload
        </NavLink>
        <NavLink
          to={`/audit/${auditId}/photo-gallery`}
          className='nav-link'
          activeClassName='akt'
        >
          Photo Gallery
        </NavLink>
        <NavLink
          to={`/audit/${auditId}/reporting`}
          className='nav-link'
          activeClassName='akt'
        >
          Reporting
        </NavLink>
        <NavLink
          to={`/audit/${auditId}/documents`}
          className='nav-link'
          activeClassName='akt'
        >
          Documents
        </NavLink>
        <NavLink
          to={`/audit/${auditId}/info`}
          className='nav-link'
          activeClassName='akt'
        >
          Info
        </NavLink>
      </Nav>

      <div className='py-3'>
        <Switch>
          <AuthRoute path='/audit/:auditId/overview'>
            <OverviewTab />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/interviews/new-interview'>
            <StartNewInterview />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/interviews/select-interview'>
            <SelectInterview />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/interviews/:interviewId'>
            <InterviewQuestions />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/interviews'>
            <InterviewsTab />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/observations/new-observation'>
            <ObservationForm />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/observations/select-observation'>
            <SelectObservation />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/observations/new-good-practice'>
            <GoodPracticeForm />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/observations/select-good-practice'>
            <SelectGoodPractice />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/observations/gp/:gpId'>
            <GoodPracticeForm />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/observations/:observationId'>
            <ObservationForm />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/observations'>
            <ObservationsTab />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/photo-upload/photo-edit'>
            <EditPhoto />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/photo-upload'>
            <PhotoUploadTab />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/photo-gallery/photo-edit'>
            <EditPhoto />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/photo-gallery'>
            <PhotoGalleryTab />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/reporting'>
            <ReportingTab />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/documents/new-document'>
            <UploadDocument />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/documents/select-document'>
            <SelectDocument />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/documents/edit'>
            <EditDocument />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/documents'>
            <DocumentsTab />
          </AuthRoute>
          <AuthRoute path='/audit/:auditId/info'>
            <InfoTab />
          </AuthRoute>
        </Switch>
      </div>
    </Fragment>
  );
};

export default AuditDetails;
