import React from 'react';
import { Route } from 'react-router-dom';
import { useAppContext } from '../../utils/auth/context';
import config from '../../utils/auth/awsAmplifyConfig';

const AuthRoute = ({ children, ...rest }) => {
  const { isAuthenticated } = useAppContext();

  if (isAuthenticated) {
    return <Route {...rest}>{children}</Route>;
  } else {
    document.location.href = config.cognito.HOSTED_UI_URL;
  }
};

export default AuthRoute;
