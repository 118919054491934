import {
  UPDATE_OBSERVATION,
  CREATE_OBSERVATION,
  CREATE_OBSERVATION_COMMIT,
  DELETE_OBSERVATION,
} from '../types/observationsTypes';
import {
  UPDATE_PHOTO_META,
  ADD_PHOTO,
  DELETE_PHOTO,
  DELETE_PHOTOS_ARRAY,
} from '../types/photosTypes';
import {
  UPDATE_INTERVIEW_ANSWER,
  CREATE_INTERVIEW_COMMIT,
  DELETE_INTERVIEW,
} from '../types/interviewsTypes';
import {
  UPDATE_DOCUMENT,
  CREATE_DOCUMENT,
  DELETE_DOCUMENT,
} from '../types/documentsTypes';
import { EXPORT_AUDIT, RESET_APP_STATE } from '../types/auditsTypes';
import {
  addObservationLink,
  removeObservationLink,
  addPhotoLink,
  removePhotoLink,
  removePhotosLinks,
  addQuestionLink,
  removeQuestionLink,
  removeInterviewLink,
  addDocumentLink,
  removeDocumentLink,
  replaceInArray,
} from '../../utils/linking';
import { toLinkId } from '../../utils/convertId';

export default (draft, action) => {
  // You can access all state 'slices' here
  // Adding reverse links
  // if entity B was linked to entity A, A has to be linked to B
  switch (action.type) {
    case CREATE_OBSERVATION:
    case UPDATE_OBSERVATION: {
      const observationLinkId = toLinkId(action.payload.entity_id); // format '#O<timestamp>'
      const {
        link_photos: linkPhotos,
        link_questions: linkQuestions,
        link_documents: linkDocuments,
      } = action.payload.data;

      return {
        ...draft,
        interviews: {
          ...draft.interviews,
          interviews: draft.interviews.interviews
            .map((interview) => {
              return {
                ...interview,
                questions: interview.questions.map((question) => {
                  return removeObservationLink(question, observationLinkId);
                }),
              };
            })
            .map((interview) => {
              return {
                ...interview,
                questions: interview.questions.map((question) => {
                  if (linkQuestions.indexOf(question.entity_question) > -1) {
                    return addObservationLink(question, observationLinkId);
                  }
                  return question;
                }),
              };
            }),
        },
        photos: {
          ...draft.photos,
          photos: draft.photos.photos
            .map((photo) => {
              return removeObservationLink(photo, observationLinkId);
            })
            .map((photo) => {
              if (linkPhotos.indexOf(photo.photo_id) > -1) {
                return addObservationLink(photo, observationLinkId);
              }
              return photo;
            }),
        },
        documents: {
          ...draft.documents,
          documents: draft.documents.documents
            .map((document) => {
              return removeObservationLink(document, observationLinkId);
            })
            .map((document) => {
              if (linkDocuments.indexOf(document.document_id) > -1) {
                return addObservationLink(document, observationLinkId);
              }
              return document;
            }),
        },
      };
    }
    case ADD_PHOTO:
    case UPDATE_PHOTO_META: {
      const photoLinkId = action.payload.photo_id;
      const {
        link_observations: linkObservations,
        link_questions: linkQuestions,
        link_documents: linkDocuments,
      } = action.payload.imageData;

      return {
        ...draft,
        interviews: {
          ...draft.interviews,
          interviews: draft.interviews.interviews
            .map((interview) => {
              return {
                ...interview,
                questions: interview.questions.map((question) => {
                  return removePhotoLink(question, photoLinkId);
                }),
              };
            })
            .map((interview) => {
              return {
                ...interview,
                questions: interview.questions.map((question) => {
                  if (linkQuestions.indexOf(question.entity_question) > -1) {
                    return addPhotoLink(question, photoLinkId);
                  }
                  return question;
                }),
              };
            }),
        },
        observations: {
          ...draft.observations,
          observations: draft.observations.observations
            .map((observation) => {
              return removePhotoLink(observation, photoLinkId);
            })
            .map((observation) => {
              if (
                linkObservations.indexOf(toLinkId(observation.entity_id)) > -1
              ) {
                return addPhotoLink(observation, photoLinkId);
              }
              return observation;
            }),
        },
        documents: {
          ...draft.documents,
          documents: draft.documents.documents
            .map((document) => {
              return removePhotoLink(document, photoLinkId);
            })
            .map((document) => {
              if (linkDocuments.indexOf(document.document_id) > -1) {
                return addPhotoLink(document, photoLinkId);
              }
              return document;
            }),
        },
      };
    }
    case UPDATE_INTERVIEW_ANSWER: {
      const questionLinkId = `${toLinkId(action.payload.entity_id)}#${
        action.payload.question_id
      }`;
      const {
        link_observations: linkObservations,
        link_photos: linkPhotos,
        link_documents: linkDocuments,
      } = action.payload.data;

      return {
        ...draft,
        observations: {
          ...draft.observations,
          observations: draft.observations.observations
            .map((observation) => {
              return removeQuestionLink(observation, questionLinkId);
            })
            .map((observation) => {
              if (
                linkObservations.indexOf(toLinkId(observation.entity_id)) > -1
              ) {
                return addQuestionLink(observation, questionLinkId);
              }
              return observation;
            }),
        },
        photos: {
          ...draft.photos,
          photos: draft.photos.photos
            .map((photo) => {
              return removeQuestionLink(photo, questionLinkId);
            })
            .map((photo) => {
              if (linkPhotos.indexOf(photo.photo_id) > -1) {
                return addQuestionLink(photo, questionLinkId);
              }
              return photo;
            }),
        },
        documents: {
          ...draft.documents,
          documents: draft.documents.documents
            .map((document) => {
              return removeQuestionLink(document, questionLinkId);
            })
            .map((document) => {
              if (linkDocuments.indexOf(document.document_id) > -1) {
                return addQuestionLink(document, questionLinkId);
              }
              return document;
            }),
        },
      };
    }
    case CREATE_DOCUMENT:
    case UPDATE_DOCUMENT: {
      const documentLinkId = action.payload.data.document_id;
      const {
        link_observations: linkObservations,
        link_questions: linkQuestions,
        link_photos: linkPhotos,
      } = action.payload.data;

      return {
        ...draft,
        interviews: {
          ...draft.interviews,
          interviews: draft.interviews.interviews
            .map((interview) => {
              return {
                ...interview,
                questions: interview.questions.map((question) => {
                  return removeDocumentLink(question, documentLinkId);
                }),
              };
            })
            .map((interview) => {
              return {
                ...interview,
                questions: interview.questions.map((question) => {
                  if (linkQuestions.indexOf(question.entity_question) > -1) {
                    return addDocumentLink(question, documentLinkId);
                  }
                  return question;
                }),
              };
            }),
        },
        observations: {
          ...draft.observations,
          observations: draft.observations.observations
            .map((observation) => {
              return removeDocumentLink(observation, documentLinkId);
            })
            .map((observation) => {
              if (
                linkObservations.indexOf(toLinkId(observation.entity_id)) > -1
              ) {
                return addDocumentLink(observation, documentLinkId);
              }
              return observation;
            }),
        },
        photos: {
          ...draft.photos,
          photos: draft.photos.photos
            .map((photo) => {
              return removeDocumentLink(photo, documentLinkId);
            })
            .map((photo) => {
              if (linkPhotos.indexOf(photo.photo_id) > -1) {
                return addDocumentLink(photo, documentLinkId);
              }
              return photo;
            }),
        },
      };
    }
    case CREATE_OBSERVATION_COMMIT: {
      // Replace temporary link ids with backend-generated ones
      const tempLinkId = toLinkId(action.meta.temp_entity_id);
      const newLinkId = toLinkId(action.payload.data.entity_id);
      return {
        ...draft,
        interviews: {
          ...draft.interviews,
          interviews: draft.interviews.interviews.map((interview) => {
            return {
              ...interview,
              questions: interview.questions.map((question) => {
                return {
                  ...question,
                  link_observations: replaceInArray({
                    array: question.link_observations,
                    find: tempLinkId,
                    replace: newLinkId,
                  }),
                };
              }),
            };
          }),
        },
        photos: {
          ...draft.photos,
          photos: draft.photos.photos.map((photo) => {
            return {
              ...photo,
              link_observations: replaceInArray({
                array: photo.link_observations,
                find: tempLinkId,
                replace: newLinkId,
              }),
            };
          }),
        },
        documents: {
          ...draft.documents,
          documents: draft.documents.documents.map((document) => {
            return {
              ...document,
              link_observations: replaceInArray({
                array: document.link_observations,
                find: tempLinkId,
                replace: newLinkId,
              }),
            };
          }),
        },
      };
    }
    case CREATE_INTERVIEW_COMMIT: {
      // Replace temporary link ids with backend-generated ones
      const tempLinkId = toLinkId(action.meta.temp_entity_id);
      const newLinkId = toLinkId(action.payload.data.entity_id);
      return {
        ...draft,
        observations: {
          ...draft.observations,
          observations: draft.observations.observations.map((observation) => {
            return {
              ...observation,
              link_questions: replaceInArray({
                array: observation.link_questions,
                find: tempLinkId,
                replace: newLinkId,
              }),
            };
          }),
        },
        photos: {
          ...draft.photos,
          photos: draft.photos.photos.map((photo) => {
            return {
              ...photo,
              link_questions: replaceInArray({
                array: photo.link_questions,
                find: tempLinkId,
                replace: newLinkId,
              }),
            };
          }),
        },
        documents: {
          ...draft.documents,
          documents: draft.documents.documents.map((document) => {
            return {
              ...document,
              link_questions: replaceInArray({
                array: document.link_questions,
                find: tempLinkId,
                replace: newLinkId,
              }),
            };
          }),
        },
        goodpractices: {
          ...draft.goodpractices,
          gps: draft.goodpractices.gps.map((gp) => {
            return {
              ...gp,
              link_questions: replaceInArray({
                array: gp.link_questions,
                find: tempLinkId,
                replace: newLinkId,
              }),
            };
          }),
        },
      };
    }
    case DELETE_OBSERVATION: {
      // Remove links to deleted entity
      const observationLinkId = toLinkId(action.payload.entity_id);
      return {
        ...draft,
        interviews: {
          ...draft.interviews,
          interviews: draft.interviews.interviews.map((interview) => {
            return {
              ...interview,
              questions: interview.questions.map((question) => {
                return removeObservationLink(question, observationLinkId);
              }),
            };
          }),
        },
        photos: {
          ...draft.photos,
          photos: draft.photos.photos.map((photo) => {
            return removeObservationLink(photo, observationLinkId);
          }),
        },
        documents: {
          ...draft.documents,
          documents: draft.documents.documents.map((document) => {
            return removeObservationLink(document, observationLinkId);
          }),
        },
      };
    }
    case DELETE_PHOTO: {
      // Remove links to deleted entity
      const photoLinkId = action.payload.photo_id;
      return {
        ...draft,
        interviews: {
          ...draft.interviews,
          interviews: draft.interviews.interviews.map((interview) => {
            return {
              ...interview,
              questions: interview.questions.map((question) => {
                return removePhotoLink(question, photoLinkId);
              }),
            };
          }),
        },
        observations: {
          ...draft.observations,
          observations: draft.observations.observations.map((observation) => {
            return removePhotoLink(observation, photoLinkId);
          }),
        },
        documents: {
          ...draft.documents,
          documents: draft.documents.documents.map((document) => {
            return removePhotoLink(document, photoLinkId);
          }),
        },
        goodpractices: {
          ...draft.goodpractices,
          gps: draft.goodpractices.gps.map((gp) => {
            return removePhotoLink(gp, photoLinkId);
          }),
        },
      };
    }
    case DELETE_PHOTOS_ARRAY: {
      // Remove links to deleted array of entities
      const photoLinksIdsList = action.payload.photoIdsList;
      return {
        ...draft,
        interviews: {
          ...draft.interviews,
          interviews: draft.interviews.interviews.map((interview) => {
            return {
              ...interview,
              questions: interview.questions.map((question) => {
                return removePhotosLinks(question, photoLinksIdsList);
              }),
            };
          }),
        },
        observations: {
          ...draft.observations,
          observations: draft.observations.observations.map((observation) => {
            return removePhotosLinks(observation, photoLinksIdsList);
          }),
        },
        documents: {
          ...draft.documents,
          documents: draft.documents.documents.map((document) => {
            return removePhotosLinks(document, photoLinksIdsList);
          }),
        },
        goodpractices: {
          ...draft.goodpractices,
          gps: draft.goodpractices.gps.map((gp) => {
            return removePhotosLinks(gp, photoLinksIdsList);
          }),
        },
      };
    }
    case DELETE_INTERVIEW: {
      // Remove links to deleted entity
      const interviewLinkId = toLinkId(action.payload.entity_id);
      return {
        ...draft,
        observations: {
          ...draft.observations,
          observations: draft.observations.observations.map((observation) => {
            return removeInterviewLink(observation, interviewLinkId);
          }),
        },
        photos: {
          ...draft.photos,
          photos: draft.photos.photos.map((photo) => {
            return removeInterviewLink(photo, interviewLinkId);
          }),
        },
        documents: {
          ...draft.documents,
          documents: draft.documents.documents.map((document) => {
            return removeInterviewLink(document, interviewLinkId);
          }),
        },
        goodpractices: {
          ...draft.goodpractices,
          gps: draft.goodpractices.gps.map((gp) => {
            return removeInterviewLink(gp, interviewLinkId);
          }),
        },
      };
    }
    case DELETE_DOCUMENT: {
      // Remove links to deleted entity
      const documentLinkId = action.payload.document_id;
      return {
        ...draft,
        interviews: {
          ...draft.interviews,
          interviews: draft.interviews.interviews.map((interview) => {
            return {
              ...interview,
              questions: interview.questions.map((question) => {
                return removeDocumentLink(question, documentLinkId);
              }),
            };
          }),
        },
        observations: {
          ...draft.observations,
          observations: draft.observations.observations.map((observation) => {
            return removeDocumentLink(observation, documentLinkId);
          }),
        },
        photos: {
          ...draft.photos,
          photos: draft.photos.photos.map((photo) => {
            return removeDocumentLink(photo, documentLinkId);
          }),
        },
        goodpractices: {
          ...draft.goodpractices,
          gps: draft.goodpractices.gps.map((gp) => {
            return removeDocumentLink(gp, documentLinkId);
          }),
        },
      };
    }
    case EXPORT_AUDIT: {
      // Remove all entities belonging to exported audit
      const auditId = action.payload;
      return {
        ...draft,
        auditsSummary: {
          ...draft.auditsSummary,
          audits: draft.auditsSummary.audits.filter(
            (audit) => audit.audit_id !== auditId
          ),
        },
        observations: {
          ...draft.observations,
          observations: draft.observations.observations.filter(
            (observation) => observation.audit_id !== auditId
          ),
        },
        interviews: {
          ...draft.interviews,
          interviews: draft.interviews.interviews.filter(
            (interview) => interview.audit_id !== auditId
          ),
        },
        photos: {
          ...draft.photos,
          photos: draft.photos.photos.filter(
            (photo) => photo.audit_id !== auditId
          ),
        },
        documents: {
          ...draft.documents,
          documents: draft.documents.documents.filter(
            (document) => document.audit_id !== auditId
          ),
        },
        goodpractices: {
          ...draft.goodpractices,
          gps: draft.goodpractices.gps.filter((gp) => gp.audit_id !== auditId),
        },
      };
    }
    case RESET_APP_STATE: {
      // Clear redux store
      return {
        ...draft,
        audits: {
          audits: [],
          loading: false,
        },
        auditsSummary: {
          audits: [],
        },
        observations: {
          observations: [],
          loading: false,
        },
        interviews: {
          interviews: [],
          loading: false,
        },
        photos: {
          photos: [],
          loading: false,
        },
        documents: {
          documents: [],
          loading: false,
        },
        goodpractices: {
          gps: [],
          loading: false,
        },
        questions: {
          categories: {
            categories: [],
            loading: false,
          },
          questions: {
            questions: [],
            loading: false,
          },
        },
      };
    }
    default:
      return draft;
  }
};
