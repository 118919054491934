import React, { Fragment } from 'react';
import NavTiles from '../shared/nav-tiles/NavTiles';
import { useParams } from 'react-router-dom';

const InterviewsTab = () => {
  const { auditId } = useParams();

  const navTilesData = [
    {
      tileIcon: 'plus-circle',
      tileLabel: 'Start a new interview',
      tooltipContent: 'Start a new interview',
      linkPath: `/audit/${auditId}/interviews/new-interview`,
    },
    {
      tileIcon: 'play-circle',
      tileLabel: 'Review existing interviews',
      tooltipContent: 'Review existing interviews',
      linkPath: `/audit/${auditId}/interviews/select-interview`,
    },
  ];
  return (
    <Fragment>
      <div className='mt-5'>
        <NavTiles navTilesData={navTilesData} />
      </div>
    </Fragment>
  );
};

export default InterviewsTab;
