import React, { Fragment, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import NavigationBar from './components/layout/NavigationBar';
import BreadcrumbsBar from './components/layout/BreadcrumbsBar';
import NotFound from './components/layout/NotFound';
import Home from './components/pages/Home';
import SelectAudit from './components/pages/SelectAudit';
import AuditDetails from './components/pages/AuditDetails';
import EditInterviewCatalogue from './components/pages/EditInterviewCatalogue';
import Imprint from './components/pages/Imprint';
import Container from 'react-bootstrap/Container';

import Amplify, { Auth } from 'aws-amplify';
import config from './utils/auth/awsAmplifyConfig';
import { AppContext } from './utils/auth/context';
import AuthRoute from './components/auth/AuthRoute';
import jwt_decode from 'jwt-decode';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

// Adding globally available icons (for icon names passed as props)
// Details: https://fontawesome.com/how-to-use/on-the-web/using-with/react#using
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPlusCircle,
  faPlayCircle,
  faFileAlt,
  faCircleNotch,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
library.add(faPlusCircle, faPlayCircle, faFileAlt, faCircleNotch, faSearch);

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
});

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, []);

  const readUserFromToken = (token) => {
    const {
      given_name,
      family_name,
      'cognito:groups': groups,
    } = jwt_decode(token);
    setAuthUser({
      username: `${given_name} ${family_name}`,
      isAdmin: groups && groups.indexOf('Administrators') > -1,
    });
  };

  const onLoad = () => {
    // Checking if user has idToken (for offline mode)
    const tkn = localStorage.getItem('idToken');
    if (tkn) {
      readUserFromToken(tkn);
      setIsAuthenticated(true);
      setIsAuthenticating(false);
    } else {
      cognitoFederatedSignIn();
    }

    // Auth.currentAuthenticatedUser()
    //   .then((response) => {
    //     localStorage.setItem('idToken', response.token);
    //     readUserFromToken(response.token);
    //     setIsAuthenticated(true);
    //     setIsAuthenticating(false);
    //   })
    //   .catch((e) => {
    //     cognitoFederatedSignIn();
    //   });
  };

  const cognitoFederatedSignIn = () => {
    if (window.location.hash) {
      const hashObj = window.location.hash
        .substring(1)
        .split('&')
        .reduce((acc, curr) => {
          acc[curr.split('=')[0]] = curr.split('=')[1];
          return acc;
        }, {});

      const domain = `cognito-idp.eu-central-1.amazonaws.com/${config.cognito.USER_POOL_ID}`;

      Auth.federatedSignIn(domain, {
        token: hashObj.id_token,
        expires_at: hashObj.expires_in * 1000 + new Date().getTime(), // the expiration timestamp
      })
        .then((cred) => {
          // If success, you will get the AWS credentials
          return Auth.currentAuthenticatedUser();
        })
        .then((user) => {
          // If success, the user object you passed in Auth.federatedSignIn
          localStorage.setItem('idToken', user.token);
          readUserFromToken(user.token);
          setIsAuthenticated(true);
          setIsAuthenticating(false);
        })
        .catch((e) => {
          setIsAuthenticating(false);
        });
    } else {
      setIsAuthenticating(false);
    }
  };

  return (
    !isAuthenticating && (
      <Fragment>
        <Router>
          <AppContext.Provider
            value={{
              isAuthenticated,
              setIsAuthenticated,
              authUser,
              setAuthUser,
            }}
          >
            <NavigationBar />
            <Container fluid className='mb-4'>
              {isAuthenticated && <BreadcrumbsBar />}
              <Switch>
                <AuthRoute path='/' exact>
                  <Home />
                </AuthRoute>
                <AuthRoute path='/select-audit' exact>
                  <SelectAudit />
                </AuthRoute>
                <AuthRoute path='/audit/:auditId'>
                  <AuditDetails />
                </AuthRoute>
                <AuthRoute path='/edit-questions'>
                  <EditInterviewCatalogue />
                </AuthRoute>
                <AuthRoute path='/imprint'>
                  <Imprint />
                </AuthRoute>
                <Route>
                  <NotFound />
                </Route>
              </Switch>
            </Container>
          </AppContext.Provider>
        </Router>
        <ToastContainer pauseOnFocusLoss={false} />
      </Fragment>
    )
  );
};

export default App;
