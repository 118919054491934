import React from 'react';
import Form from 'react-bootstrap/Form';

const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id, Header },
}) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <Form.Control
      size='sm'
      as='select'
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value=''>-- {Header} --</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Form.Control>
  );
};

export default SelectColumnFilter;
