import {
  FETCH_AUDIT_SUMMARY_INIT,
  FETCH_AUDIT_SUMMARY_INIT_COMMIT,
  FETCH_AUDIT_SUMMARY_REFRESH,
  FETCH_AUDIT_SUMMARY_REFRESH_COMMIT,
} from '../types/auditsSummaryTypes';
import axiosInst from '../../http/axiosConfig';
import { fetchAuditSummary } from '../../http/requests';

export const fetchAuditSummaryInit = (auditId) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_AUDIT_SUMMARY_INIT,
      payload: parseInt(auditId),
    });
    axiosInst(fetchAuditSummary(auditId))
      .then((response) => {
        dispatch({
          type: FETCH_AUDIT_SUMMARY_INIT_COMMIT,
          payload: {
            audit_id: parseInt(auditId),
            data: response.data,
          },
        });
      })
      .catch((error) => {
        //
      });
  };
};

export const fetchAuditSummaryRefresh = (auditId) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_AUDIT_SUMMARY_REFRESH,
      payload: parseInt(auditId),
      meta: {
        offline: {
          effect: fetchAuditSummary(auditId),
          commit: {
            type: FETCH_AUDIT_SUMMARY_REFRESH_COMMIT,
            meta: {
              audit_id: parseInt(auditId),
            },
          },
        },
      },
    });
  };
};
