import React, { useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import axiosInst from '../../http/axiosConfig';
import { exportAuditData } from '../../http/requests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pptxgen from 'pptxgenjs';
import {
  setSlideMaster,
  titleSlides,
  progressSlides,
  scopeSlide,
  summarySlide,
  observationSlide,
  clearanceSlide,
  appendixSlide,
  setToc,
} from '../../utils/report/report';
import { useSelector, useDispatch } from 'react-redux';
import useOverviewAndReportData from '../../utils/customHooks/useOverviewAndReportData';
import { useAppContext } from '../../utils/auth/context';
import { exportAudit } from '../../store/actions/auditsActions';
import useAuditExported from '../../utils/customHooks/useAuditExported';

import './ReportingTab.scss';

// CSS imports for pptxjs
import '../../utils/report/pptxjs.css';
import '../../utils/report/nv.d3.min.css';

const ReportingTab = () => {
  const { auditId } = useParams();
  const { authUser } = useAppContext();
  const reportDataFromStore = (({
    interviewsInReport,
    auditMetadata,
    observationsInReport,
    goodPracticesInReport,
    riskFactor,
    significantMSO,
    minorMSO,
  }) => ({
    audit_id: parseInt(auditId),
    interviews: interviewsInReport,
    metadata: auditMetadata,
    observations: observationsInReport,
    goodpractices: goodPracticesInReport,
    risk: riskFactor,
    significantMSO,
    minorMSO,
  }))(useOverviewAndReportData(auditId));
  const isOnline = useSelector((state) => state.offline.online);
  const isAuditExported = useAuditExported();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [modal1Show, setModal1Show] = useState(false);
  const [modal2Show, setModal2Show] = useState(false);
  const [modal3Show, setModal3Show] = useState(false);
  const [exporting, setExporting] = useState(false);
  const now = new Date();
  const radios = [
    { label: '4+1 meeting', value: '4plus1meeting' },
    { label: 'Fact clearance', value: 'factClearance' },
    { label: 'Site clearance', value: 'siteClearance' },
  ];
  const [radioValue, setRadioValue] = useState(radios[0].value);
  const [confirmUploadCheckbox, setConfirmUploadCheckbox] = useState(false);
  const [confirmDeleteCheckbox, setConfirmDeleteCheckbox] = useState(false);

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const getReportDataFromApi = () => {
    return axiosInst
      .get(`/audits/${auditId}/report`)
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
      });
  };

  const generatePPTReport = async () => {
    let reportData;
    if (isOnline) {
      try {
        setLoading(true);
        reportData = await getReportDataFromApi();
      } finally {
        setLoading(false);
      }
    } else {
      reportData = reportDataFromStore;
    }
    if (reportData) {
      const reportType = radioValue;
      const pptx = await generateReportPPT(reportData, reportType);
      return pptx.writeFile({
        fileName: `beSafe ${reportType} ${now.getFullYear()}-${
          now.getMonth() + 1
        }-${now.getDate()}.pptx`,
      });
    }
  };

  const generateReportPreview = async () => {
    let reportData;
    if (isOnline) {
      try {
        setLoadingPreview(true);
        reportData = await getReportDataFromApi();
      } finally {
        setLoadingPreview(false);
      }
    } else {
      reportData = reportDataFromStore;
    }
    if (reportData) {
      const reportType = radioValue;
      const pptx = await generateReportPPT(reportData, reportType);
      pptx
        .write({ outputType: 'base64' })
        .then((data) => {
          window.$('#slide-container').pptxToHtml({
            pptxFile: data,
            slidesScale: '46',
            slideMode: false,
            keyBoardShortCut: false,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getInterviewsSummary = (interviewsGrouped, sampleData) => {
    const interviewCategoriesWithSignificantMO = interviewsGrouped.reduce(
      (acc, interview) => {
        const interviewHasSignificantMO = interview.questions.some(
          (question) =>
            question.is_significant.toLowerCase().startsWith('bundle') ||
            question.is_significant.toLowerCase() === 'significant'
        );
        if (interviewHasSignificantMO) {
          acc.push(interview.question_category);
        }
        return acc;
      },
      []
    );
    let minorMOCount = 0,
      significantMOCount = 0;
    if (
      sampleData.hasOwnProperty('significantMSO') &&
      sampleData.hasOwnProperty('minorMSO')
    ) {
      minorMOCount = sampleData.minorMSO;
      significantMOCount = sampleData.significantMSO;
    } else {
      const moCount = sampleData.interviews.reduce(
        (acc, interview) => {
          interview.questions.forEach((question) => {
            if (question.is_significant.toLowerCase() === 'minor')
              acc.minorMOCount++;
            if (question.is_significant.toLowerCase() === 'significant') {
              acc.significantMOCount++;
            }
          });
          return acc;
        },
        {
          minorMOCount: 0,
          significantMOCount: 0,
        }
      );
      // Count each bundle of observations in one interview as one significant MSO (management system observation)
      const bundledMSO = sampleData.interviews.reduce((acc, interview) => {
        const bundlesInInterview = new Set(
          interview.questions
            .filter((question) => question.is_significant.startsWith('bundle'))
            .map((question) => question.is_significant)
        ).size;
        return acc + bundlesInInterview;
      }, 0);
      moCount.significantMOCount += bundledMSO;

      minorMOCount = moCount.minorMOCount;
      significantMOCount = moCount.significantMOCount;
    }
    return {
      interviewCategoriesWithSignificantMO,
      minorMOCount,
      significantMOCount,
    };
  };

  const generateReportPPT = async (sampleData, reportType) => {
    let metadata = sampleData.metadata;
    if (!metadata.osaspect_categories_accessed)
      metadata.osaspect_categories_accessed = [];
    if (!metadata.key_facts) metadata.key_facts = [];

    let sConf = {
      progress: { enabled: true, sectionToCLabel: 'Reporting Process' },
      scope: {
        enabled: true,
        sectionToCLabel: 'Audit scope and key information',
      },
      summary: { enabled: true, sectionToCLabel: 'Executive summary' },
      observation: {
        enabled: true,
        sectionToCLabel: 'Key observations and actions agreed with management',
      },
      clearance: {
        enabled: true,
        sectionToCLabel: 'Clearance meeting, key data and distribution list',
      },
      appendix: { enabled: true, sectionToCLabel: 'Appendix' },
    };

    let reportName = 'Site clearance';
    if (reportType === '4plus1meeting') {
      reportName = '4+1 meeting';
      sConf.progress.enabled = false;
      sConf.scope.enabled = false;
      sConf.summary.enabled = false;
      sConf.clearance.enabled = false;
      sConf.appendix.enabled = false;
    } else if (reportType === 'factClearance') {
      reportName = 'Fact clearance';
      sConf.progress.enabled = false;
      sConf.scope.enabled = false;
      sConf.summary.enabled = false;
      sConf.clearance.enabled = false;
      sConf.appendix.enabled = false;
    }

    let pptx = new pptxgen();
    pptx.title = `beSafe ${reportName}`;
    pptx.subject = 'beSafe Export';
    pptx.author = authUser ? authUser.username : 'N/A';
    pptx.company = 'Siemens AG';
    pptx.department = 'CF A';
    pptx.revision = '15';

    setSlideMaster(pptx, sConf, reportType);
    titleSlides(pptx, metadata);

    if (sConf.progress.enabled) {
      sConf.progress.slide = progressSlides(pptx, metadata);
    }

    if (sConf.scope.enabled) {
      sConf.scope.slide = scopeSlide(pptx, metadata);
    }

    // Group interview questions by category
    const interviewsGrouped = sampleData.interviews.reduce((acc, interview) => {
      // Check if 'acc' already has object with a given 'question_category' value
      let index = acc.findIndex(
        (item) => item.question_category === interview.question_category
      );
      let questionsInReport = interview.questions.filter(
        (q) => q.in_report === true
      );
      if (index > -1) {
        acc[index].questions.push(...questionsInReport);
      } else {
        acc.push({
          question_category: interview.question_category,
          questions: questionsInReport,
        });
      }
      return acc;
    }, []);

    const questionCategoriesOrder = [
      'Environmental Protection',
      'Contractor Safety Management',
      'Organizational Setup',
      'Targets and Monitoring',
      'Risk Management',
      'EHS in PM@Siemens',
    ];

    interviewsGrouped.sort(
      (a, b) =>
        questionCategoriesOrder.indexOf(a.question_category) -
        questionCategoriesOrder.indexOf(b.question_category)
    );

    if (sConf.summary.enabled) {
      /*
		let rating = [
			{aspect: 'OS risk management', critical:0, significant:3, minor:5},
			{aspect: 'Mechanical safety', critical:0, significant:0, minor:0},
			{aspect: 'Fire Safety & Emergency management', critical:0, significant:0, minor:0},
			{aspect: 'Electrical safety', critical:0, significant:0, minor:6},
			{aspect: 'Energy isolation', critical:0, significant:0, minor:5},
			{aspect: 'Hazardous substances', critical:0, significant:0, minor:3},
			{aspect: 'Lifting procedures', critical:0, significant:0, minor:2},
			{aspect: 'Working@Height', critical:2, significant:0, minor:6},
			{aspect: 'Excavation Works', critical:0, significant:0, minor:0},
			{aspect: 'Confined spaces', critical:0, significant:0, minor:0}
		] */

      const groupByKey = (array, key) => {
        return array.reduce((hash, obj) => {
          if (obj[key] === undefined) return hash;
          return Object.assign(hash, {
            [obj[key]]: (hash[obj[key]] || []).concat(obj),
          });
        }, {});
      };

      let ratingAspects = groupByKey(
        sampleData.observations,
        'aspect_category'
      );
      let rating = Object.keys(ratingAspects).map((k) => {
        let a = ratingAspects[k].reduce(
          (acc, cur) => {
            if (
              cur.observation_type &&
              cur.observation_type.toLowerCase() === 'critical'
            )
              acc.critical++;
            else acc.minor++;
            return acc;
          },
          { critical: 0, minor: 0 }
        );
        return {
          aspect: k,
          critical: a.critical,
          minor: a.minor,
        };
      });
      // console.log(rating)

      const interviewsSummary = getInterviewsSummary(
        interviewsGrouped,
        sampleData
      );

      sConf.summary.slide = summarySlide(
        pptx,
        rating,
        sampleData.risk,
        interviewsSummary
      );
    }

    if (sConf.observation.enabled) {
      sConf.observation.slide = await observationSlide(
        pptx,
        reportType,
        sampleData.observations.sort((a, b) =>
          a.aspect_category.localeCompare(b.aspect_category)
        ),
        sampleData.goodpractices,
        interviewsGrouped,
        metadata
      );
    }

    if (sConf.clearance.enabled) {
      let extraData = {
        auditee: metadata.audit_champion,
        cfa: metadata.scrum_team,
        cc: metadata.cfa_followup_coordinator,
        location: 'TheCity, VA',
        date: new Date().toDateString(),
      };
      sConf.clearance.slide = clearanceSlide(pptx, metadata, extraData);
    }

    if (sConf.appendix.enabled) {
      sConf.appendix.slide = appendixSlide(pptx);
    }
    setToc(sConf);

    return pptx;
  };

  const handleConfirmModal1 = () => {
    setModal1Show(false);
    setModal2Show(true);
    setConfirmUploadCheckbox(false);
    setConfirmDeleteCheckbox(false);
  };

  const handleCloseModal1 = () => {
    setModal1Show(false);
    setConfirmUploadCheckbox(false);
    setConfirmDeleteCheckbox(false);
  };

  const redirectToHome = () => {
    setTimeout(() => {
      history.push('/');
    }, 8000);
  };

  const handleConfirmModal2 = () => {
    // setModal2Show(false);
    // setModal3Show(true);
    // redirectToHome();

    setExporting(true);
    axiosInst(exportAuditData(auditId))
      .then((response) => {
        setModal2Show(false);
        setModal3Show(true);
        redirectToHome();
        dispatch(exportAudit(auditId));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setExporting(false);
      });
  };

  return (
    <div className='py-3'>
      <Row>
        <Col sm={7}>
          <div className='report-preview border p-3'>
            <div id='slide-container'></div>
          </div>
          <Button
            className='mt-3'
            size='sm'
            onClick={() => generateReportPreview()}
            disabled={isAuditExported}
          >
            {loadingPreview && (
              <FontAwesomeIcon icon='circle-notch' spin className='mr-2' />
            )}
            Refresh preview
          </Button>
        </Col>
        <Col>
          <div className='d-flex flex-column'>
            <Link to={`/audit/${auditId}/info`} className='mb-2'>
              <Button block size='sm'>
                Edit audit information
              </Button>
            </Link>
            <Link
              to={`/audit/${auditId}/interviews/select-interview`}
              className='mb-2'
            >
              <Button block size='sm'>
                Edit interviews
              </Button>
            </Link>
            <Link
              to={`/audit/${auditId}/observations/select-observation`}
              className='mb-2'
            >
              <Button block size='sm'>
                Edit observations
              </Button>
            </Link>
            <Link to={`/audit/${auditId}/photo-gallery`} className='mb-2'>
              <Button block size='sm'>
                Edit photos
              </Button>
            </Link>
          </div>
        </Col>
        <Col>
          <div className='d-flex flex-column justify-content-between h-100'>
            <div>
              <div className='mb-2'>Report type:</div>
              <Form.Group controlId='reportType'>
                {radios.map((radio, index) => (
                  <Form.Check
                    key={index}
                    type='radio'
                    name='report-type'
                    id={radio.value}
                    label={radio.label}
                    value={radio.value}
                    checked={radioValue === radio.value}
                    onChange={handleRadioChange}
                  />
                ))}
              </Form.Group>
            </div>
            <div className='d-flex flex-column'>
              <Button
                className='mb-2'
                onClick={() => generatePPTReport()}
                disabled={isAuditExported}
              >
                {loading && (
                  <FontAwesomeIcon icon='circle-notch' spin className='mr-2' />
                )}
                Export report as PPT
              </Button>
              <Button
                variant='success'
                disabled={!isOnline || isAuditExported}
                onClick={() => setModal1Show(true)}
              >
                Finish audit and export data to CF A workspace
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <Modal
        show={modal1Show}
        onHide={handleCloseModal1}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to finish working on selected audit and export
          all data to CF A Workspace?
          <br />
          <p>
            <strong>
              Selected audit will no longer be accessible in beSafe. Once the
              archiving is complete audit will be available for deletion by
              Admin user in audits list.
            </strong>
          </p>
          <p>
            To proceed, please ensure first that you have done the following:
          </p>
          <Form.Group controlId='confirmUpload'>
            <Form.Check
              type='checkbox'
              label='I uploaded all photos and other evidential data related to the audit to beSafe, even those which were not relevant for the audit report.'
              checked={confirmUploadCheckbox}
              onChange={(e) => setConfirmUploadCheckbox(e.target.checked)}
            />
          </Form.Group>
          <Form.Group controlId='confirmDelete'>
            <Form.Check
              type='checkbox'
              label='I deleted all photos and other evidential data on all my devices (laptop, smartphone, camera, etc.).'
              checked={confirmDeleteCheckbox}
              onChange={(e) => setConfirmDeleteCheckbox(e.target.checked)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseModal1}>
            Cancel
          </Button>
          <Button
            variant='primary'
            disabled={!confirmUploadCheckbox || !confirmDeleteCheckbox}
            onClick={handleConfirmModal1}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modal2Show}
        onHide={() => setModal2Show(false)}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>2nd confirmation required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to proceed?
          <br />
          <strong>
            Selected audit will no longer be accessible in beSafe. Once the
            archiving is complete audit will be available for deletion by Admin
            user in audits list.
          </strong>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setModal2Show(false)}>
            Cancel
          </Button>
          <Button variant='primary' onClick={handleConfirmModal2}>
            {exporting && (
              <FontAwesomeIcon icon='circle-notch' spin className='mr-2' />
            )}
            Export data to CF A Workspace
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modal3Show}
        onHide={() => setModal3Show(false)}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Data export initiated</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Audit data export was successfully initiated.
          <br />
          In a few seconds you will be redirected to <strong>Home</strong> page.
        </Modal.Body>
        <Modal.Footer>
          <Link to={'/'}>
            <Button variant='primary'>Go to Home</Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReportingTab;
