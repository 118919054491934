import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-bootstrap/Media';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import './NavTileItem.scss';

const NavTile = ({
  icon,
  label,
  tooltipContent,
  linkPath,
  id,
  isOfflineDisabled,
}) => {
  const isOnline = useSelector((state) => state.offline.online);

  return (
    <div className='nav-tile-item'>
      <Link
        to={linkPath}
        onClick={
          isOfflineDisabled && !isOnline ? (e) => e.preventDefault() : undefined
        }
        className={`nav-tile-item__card ${
          isOfflineDisabled && !isOnline ? 'nav-tile-item__card--disabled' : ''
        }`}
      >
        <FontAwesomeIcon
          icon={icon}
          size='lg'
          className='align-self-center mr-3'
        />
        <Media.Body>
          <h5 className='nav-tile-item__label'>{label}</h5>
        </Media.Body>
      </Link>

      <OverlayTrigger
        key={id}
        placement='bottom'
        overlay={<Tooltip id={`tooltip-${id}`}>{tooltipContent}</Tooltip>}
      >
        <FontAwesomeIcon icon={faQuestionCircle} size='xs' className='ml-2' />
      </OverlayTrigger>
    </div>
  );
};

NavTile.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltipContent: PropTypes.string.isRequired,
  linkPath: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isOfflineDisabled: PropTypes.bool,
};

NavTile.defaultProps = {
  isOfflineDisabled: false,
};

export default NavTile;
