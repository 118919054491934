import React, { Fragment } from 'react';
import PhotoUpload from './photoUpload/PhotoUpload';

const PhotoUploadTab = () => {
  return (
    <Fragment>
      <PhotoUpload />
    </Fragment>
  );
};

export default PhotoUploadTab;
