import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const EntitySyncedModal = ({ show, onHide, entityType, entityListLink }) => {
  return (
    <Modal show={show} onHide={onHide} backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{entityType} synchronised</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {entityType} you're currently viewing was just synchronised with the
        database after restoring network connection and was assigned a{' '}
        <strong>new ID</strong>. To avoid data inconsistencies please reopen the{' '}
        {entityType} from the list.
      </Modal.Body>
      <Modal.Footer>
        <Link to={entityListLink}>
          <Button variant='primary'>List of {entityType}s</Button>
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

export default EntitySyncedModal;
