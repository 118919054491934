import React, { useState, useEffect } from 'react';
import axiosInst from '../../http/axiosConfig';
import { updateInterviewQuestion } from '../../http/requests';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EditQuestionModal = ({
  show,
  onHide,
  questionCategories,
  selectedQuestion,
  handleUpdateQuestion,
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState(questionCategories);
  const [initialCategoryName, setInitialCategoryName] = useState('');
  const [questionId, setQuestionId] = useState('');
  const [questionState, setQuestionState] = useState({
    question: '',
    tooltip: '',
    category: '',
  });

  useEffect(() => {
    if (selectedQuestion) {
      setInitialCategoryName(selectedQuestion.question_category);
      setQuestionId(selectedQuestion.question_id);
      setQuestionState({
        question: selectedQuestion.question_text,
        tooltip: selectedQuestion.tooltip,
        category: selectedQuestion.question_category,
      });
    }
  }, [selectedQuestion, show]);

  useEffect(() => {
    setCategories(questionCategories);
  }, [questionCategories]);

  const handleChange = (e) => {
    setQuestionState({
      ...questionState,
      [e.target.name]: e.target.value,
    });
  };

  const validate = (state) => {
    if (!state.category) {
      return 'Category field is required.';
    }
    if (
      state.category.toUpperCase().trim() !==
        initialCategoryName.toUpperCase().trim() &&
      categories
        .map((item) => item.question_category.toUpperCase())
        .indexOf(state.category.toUpperCase().trim()) > -1
    ) {
      return 'This category name is already used.';
    }
    if (!state.question) {
      return 'Question text field is required.';
    }
    if (!state.tooltip) {
      return 'Question tooltip field is required.';
    }
    return null;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const errorMsg = validate(questionState);
    if (errorMsg) {
      setError(errorMsg);
      return;
    } else {
      setError(null);
      setButtonLoading(true);
      axiosInst(updateInterviewQuestion({ questionId, questionState }))
        .then((response) => {
          handleUpdateQuestion(response.data, initialCategoryName);
          onHide();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setButtonLoading(false);
        });
    }
  };

  return (
    <Modal show={show} onHide={onHide} backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Edit question - {questionId}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>Changes will be applied to all existing interviews.</h6>
        <Form id='questionForm' onSubmit={handleFormSubmit}>
          <Form.Group controlId='questionCategory'>
            <Form.Label>Category *</Form.Label>
            <Form.Control
              type='text'
              name='category'
              value={questionState.category}
              onChange={handleChange}
            />
            <Form.Text className='text-muted'>
              Category name will also be updated in other questions.
            </Form.Text>
          </Form.Group>
          <Form.Group controlId='questionText'>
            <Form.Label>Question text *</Form.Label>
            <Form.Control
              type='text'
              name='question'
              value={questionState.question}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId='questionTooltip'>
            <Form.Label>Question tooltip *</Form.Label>
            <Form.Control
              type='text'
              name='tooltip'
              value={questionState.tooltip}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
        <p className='text-danger'>{error}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>
          Cancel
        </Button>
        <Button variant='primary' form='questionForm' type='submit'>
          {buttonLoading ? (
            <FontAwesomeIcon icon='circle-notch' spin className='mr-2' />
          ) : (
            ''
          )}
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// EditQuestionModal.defaultProps = {
//   selectedQuestionId: '',
// };

export default EditQuestionModal;
