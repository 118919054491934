import React from 'react';
import AuditKPIs from './overview/AuditKPIs';

const OverviewTab = () => {
  return (
    <div className='py-5'>
      <AuditKPIs />
    </div>
  );
};

export default OverviewTab;
