import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const NotFound = () => {
  return (
    <div className='py-5'>
      <h1>Page not found!</h1>
      <p>Page does not exist</p>
      <Link to='/'>
        <Button variant='primary'>Back to Home page</Button>
      </Link>
    </div>
  );
};

export default NotFound;
