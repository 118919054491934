import React, { Fragment, useEffect, useState } from 'react';
import { useParams, Prompt } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoPersonInput from './info/InfoPersonInput';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import { useTable } from 'react-table';
// Editable table acc. to: https://react-table.tanstack.com/docs/examples/editable-data
// https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/examples/editable-data
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAuditRefresh,
  updateAudit,
} from '../../store/actions/auditsActions';
import { convertDate } from '../../utils/convert';
import useAuditExported from '../../utils/customHooks/useAuditExported';

import './InfoTab.scss';

const selectStyles = {
  container: (provided) => ({
    ...provided,
    padding: 0,
    height: 'auto',
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 0,
    minHeight: 'initial',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '4px 4px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '3px 6px',
  }),
};

const InfoTab = () => {
  const { auditId } = useParams();
  const { auditInfo, loading, isOnline } = useSelector((state) => {
    const auditInfo = state.audits.audits.find(
      (audit) => audit.audit_id === parseInt(auditId)
    );
    return {
      auditInfo,
      loading: auditInfo.loading,
      isOnline: state.offline.online,
    };
  });
  const dispatch = useDispatch();
  const isAuditExported = useAuditExported();

  const [error, setError] = useState(null);
  const [auditMeta, setAuditMeta] = useState(null);
  const [savingSite, setSavingSite] = useState(false);
  const [siteData, setSiteData] = useState(null);
  const [siteDataChanged, setSiteDataChanged] = useState(false);
  const [editSiteData, setEditSiteData] = useState(false);
  const [initialSiteData, setInitialSiteData] = useState(null);
  const [savingReport, setSavingReport] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [reportDataChanged, setReportDataChanged] = useState(false);
  const [editReportData, setEditReportData] = useState(false);
  const [initialReportData, setInitialReportData] = useState(null);
  const [savingTable, setSavingTable] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [tableDataChanged, setTableDataChanged] = useState(false);
  const [editTableData, setEditTableData] = useState(false);
  const [initialTableData, setInitialTableData] = useState(null);

  const osAspectCategories = [
    { value: 'Electrical Safety', label: 'Electrical Safety' },
    { value: 'Energy Isolation', label: 'Energy Isolation' },
    { value: 'Hazardous Substances', label: 'Hazardous Substances' },
    { value: 'Mechanical Safety', label: 'Mechanical Safety' },
    { value: 'Excavation Works', label: 'Excavation Works' },
    { value: 'Confined Spaces', label: 'Confined Spaces' },
    {
      value: 'Fire Safety and Emergency Management',
      label: 'Fire Safety and Emergency Management',
    },
    { value: 'Working @ Height', label: 'Working @ Height' },
    { value: 'Lifting Procedures', label: 'Lifting Procedures' },
  ];

  const checkKeyFacts = (key_facts) => {
    if (key_facts && key_facts.length) {
      setTableData(key_facts);
      setInitialTableData(key_facts);
    } else {
      const emptyKeyFacts = [];
      for (let i = 1; i <= 3; i++) {
        emptyKeyFacts.push({
          id: i,
          fiscal_year: '',
          ltifr: '',
          lost_time_cases: '',
          trir: '',
          restricted_work_cases: '',
          medical_treatment_cases: '',
          first_aid_cases: '',
          near_miss: '',
        });
      }
      setTableData(emptyKeyFacts);
      setInitialTableData(emptyKeyFacts);
    }
  };

  useEffect(() => {
    if (isOnline) {
      dispatch(fetchAuditRefresh(auditId));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const {
      site_name,
      employee_count,
      contractor_count,
      type_of_site,
      country_ceo_requestor,
      osaspect_categories_accessed,
      annual_clearance_audience,
      annual_clearance_date,
      clearance_meeting_date,
      clearance_meeting_participant,
      clearance_meeting_participant_orgcode,
      founding_year_of_site,
      site_info,
      circular_version,
      circular_date,
      key_facts,
      ...auditMetadata
    } = auditInfo;
    setAuditMeta(auditMetadata);
    const siteInitial = {
      site_name: site_name || '',
      employee_count: employee_count === 0 ? 0 : parseInt(employee_count) || '',
      contractor_count:
        contractor_count === 0 ? 0 : parseInt(contractor_count) || '',
      type_of_site: type_of_site || '',
    };
    const reportInitial = {
      country_ceo_requestor: country_ceo_requestor || '',
      osaspect_categories_accessed: osaspect_categories_accessed || [],
      annual_clearance_audience: annual_clearance_audience || '',
      annual_clearance_date: annual_clearance_date
        ? new Date(annual_clearance_date)
        : '',
      clearance_meeting_date: clearance_meeting_date
        ? new Date(clearance_meeting_date)
        : '',
      clearance_meeting_participant: clearance_meeting_participant || '',
      clearance_meeting_participant_orgcode:
        clearance_meeting_participant_orgcode || '',
      founding_year_of_site: founding_year_of_site || '',
      site_info: site_info ? decodeURI(site_info) : '',
      circular_version: circular_version || '',
      circular_date: circular_date || '',
    };

    setSiteData(siteInitial);
    setInitialSiteData(siteInitial);

    setReportData(reportInitial);
    setInitialReportData(reportInitial);

    checkKeyFacts(key_facts);
    // eslint-disable-next-line
  }, [auditInfo]);

  const handleSiteDataChange = (e) => {
    setSiteDataChanged(true);
    setSiteData({
      ...siteData,
      [e.target.name]: e.target.value,
    });
  };

  const handleReportCategoriesChange = (e) => {
    setReportDataChanged(true);
    setReportData({
      ...reportData,
      osaspect_categories_accessed: e,
    });
  };

  const handleReportDataChange = (e) => {
    setReportDataChanged(true);
    setReportData({
      ...reportData,
      [e.target.name]: e.target.value,
    });
  };

  const handleACDChange = (momentObj) => {
    setReportDataChanged(true);
    setReportData({
      ...reportData,
      annual_clearance_date: momentObj._d,
    });
  };

  const handleCMDChange = (momentObj) => {
    setReportDataChanged(true);
    setReportData({
      ...reportData,
      clearance_meeting_date: momentObj._d,
    });
  };

  const handleCancelSiteEdit = () => {
    setSiteData(initialSiteData);
    setEditSiteData(false);
    setError(null);
    setSiteDataChanged(false);
  };

  const handleCancelReportEdit = () => {
    setReportData(initialReportData);
    setEditReportData(false);
    setReportDataChanged(false);
  };

  const handleCancelTableEdit = () => {
    setTableData(initialTableData);
    setEditTableData(false);
    setTableDataChanged(false);
  };

  const validate = (siteData) => {
    if (!siteData.type_of_site) {
      return 'Type of Site is required.';
    }
    if (!siteData.employee_count || parseInt(siteData.employee_count) < 0) {
      return 'No. of employees - enter positive number or 0.';
    }
    if (!siteData.contractor_count || parseInt(siteData.contractor_count) < 0) {
      return 'No. of contractors - enter positive number or 0.';
    }
    return null;
  };

  const handleSaveSiteData = () => {
    const errorMsg = validate(siteData);
    if (errorMsg) {
      setError(errorMsg);
      return;
    } else {
      setError(null);
      const dataObj = {
        ...siteData,
        site_name: siteData.site_name.trim(),
        employee_count: parseInt(siteData.employee_count),
        contractor_count: parseInt(siteData.contractor_count),
      };
      dispatch(updateAudit({ auditId, data: dataObj }));
      setInitialSiteData(siteData);
      setEditSiteData(false);
      setSiteDataChanged(false);
    }
  };

  const handleSaveReportData = () => {
    const dataObj = {
      ...reportData,
      country_ceo_requestor: reportData.country_ceo_requestor.trim(),
      annual_clearance_audience: reportData.annual_clearance_audience.trim(),
      clearance_meeting_participant:
        reportData.clearance_meeting_participant.trim(),
      clearance_meeting_participant_orgcode:
        reportData.clearance_meeting_participant_orgcode.trim(),
      site_info: encodeURI(reportData.site_info),
      annual_clearance_date: convertDate(reportData.annual_clearance_date),
      clearance_meeting_date: convertDate(reportData.clearance_meeting_date),
      founding_year_of_site: parseInt(reportData.founding_year_of_site),
      circular_date: reportData.circular_date.trim(),
    };
    dispatch(updateAudit({ auditId, data: dataObj }));
    setInitialReportData(reportData);
    setEditReportData(false);
    setReportDataChanged(false);
  };

  const handleSaveTableData = () => {
    const dataObj = {
      key_facts: tableData,
    };
    dispatch(updateAudit({ auditId, data: dataObj }));
    setInitialTableData(tableData);
    setEditTableData(false);
    setTableDataChanged(false);
  };

  const data = React.useMemo(() => tableData, [tableData]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Fiscal Year',
        accessor: 'fiscal_year',
      },
      {
        Header: 'LTIFR',
        accessor: 'ltifr',
      },
      {
        Header: '# Lost Time Cases',
        accessor: 'lost_time_cases',
      },
      {
        Header: 'TRIR',
        accessor: 'trir',
      },
      {
        Header: '# Restricted Work Cases',
        accessor: 'restricted_work_cases',
      },
      {
        Header: '# Medical Treatment Cases',
        accessor: 'medical_treatment_cases',
      },
      {
        Header: '# First Aid Cases',
        accessor: 'first_aid_cases',
      },
      {
        Header: '# Near Miss / unsafe conditions',
        accessor: 'near_miss',
      },
    ],
    []
  );

  const updateData = (rowIndex, columnId, value) => {
    setTableDataChanged(true);
    setTableData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  return (
    <div className='my-4'>
      {loading && (
        <div className='text-center mb-3'>
          <FontAwesomeIcon icon='circle-notch' spin size='lg' />
        </div>
      )}
      {auditMeta && (
        <Fragment>
          <Prompt
            when={siteDataChanged || reportDataChanged || tableDataChanged}
            message='You have unsaved changes. Are you sure you want to leave?'
          />
          <Row className='mb-5'>
            <Col className='border-right'>
              <h5 className='info-column-header'>General</h5>
              <Form.Group as={Row} controlId='auditId'>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  Audit ID
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    readOnly
                    type='text'
                    value={auditMeta.audit_id}
                    placeholder='-'
                    size='sm'
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='createdOn'>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  Created on
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    readOnly
                    type='text'
                    value={auditMeta.created_on}
                    placeholder='-'
                    size='sm'
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='fiscalYear'>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  Fiscal Year
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    readOnly
                    type='text'
                    value={auditMeta.fiscal_year}
                    placeholder='-'
                    size='sm'
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  ERM category
                </Form.Label>
                <Col sm={7}>
                  {auditMeta.erm_category && auditMeta.erm_category.length ? (
                    auditMeta.erm_category.map((category, i) => (
                      <Form.Control
                        key={i}
                        readOnly
                        type='text'
                        className={i > 0 ? 'mt-2' : ''}
                        value={category.ERMCategoryTitle}
                        placeholder='-'
                        size='sm'
                      />
                    ))
                  ) : (
                    <Form.Control
                      readOnly
                      type='text'
                      placeholder='-'
                      size='sm'
                    />
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='engagementType'>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  Engagement type
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    readOnly
                    type='text'
                    value={auditMeta.engagement_type}
                    placeholder='-'
                    size='sm'
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  CF A followup coordinator
                </Form.Label>
                <Col sm={7}>
                  {auditMeta.cfa_followup_coordinator &&
                  auditMeta.cfa_followup_coordinator.length ? (
                    auditMeta.cfa_followup_coordinator.map((coordinator, i) => (
                      <InfoPersonInput
                        key={i}
                        person={coordinator}
                        personType='coordinator'
                        index={i}
                      />
                    ))
                  ) : (
                    <Form.Control
                      readOnly
                      type='text'
                      placeholder='-'
                      size='sm'
                    />
                  )}
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <h5 className='info-column-header'>Team</h5>
              <Form.Group as={Row}>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  Audit champion
                  <Form.Text className='text-muted'>
                    Old name: GAT Partner
                  </Form.Text>
                </Form.Label>
                <Col sm={7}>
                  {auditMeta.audit_champion &&
                  auditMeta.audit_champion.length ? (
                    auditMeta.audit_champion.map((champion, i) => (
                      <InfoPersonInput
                        key={i}
                        person={champion}
                        personType='champion'
                        index={i}
                      />
                    ))
                  ) : (
                    <Form.Control
                      readOnly
                      type='text'
                      placeholder='-'
                      size='sm'
                    />
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  Product owner
                  <Form.Text className='text-muted'>
                    Old name: GAT Director/Manager
                  </Form.Text>
                </Form.Label>
                <Col sm={7}>
                  {auditMeta.product_owner && auditMeta.product_owner.length ? (
                    auditMeta.product_owner.map((owner, i) => (
                      <InfoPersonInput
                        key={i}
                        person={owner}
                        personType='owner'
                        index={i}
                      />
                    ))
                  ) : (
                    <Form.Control
                      readOnly
                      type='text'
                      placeholder='-'
                      size='sm'
                    />
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  SCRUM Master
                  <Form.Text className='text-muted'>
                    Old name: Engagement Manager
                  </Form.Text>
                </Form.Label>
                <Col sm={7}>
                  {auditMeta.scrum_master && auditMeta.scrum_master.length ? (
                    auditMeta.scrum_master.map((master, i) => (
                      <InfoPersonInput
                        key={i}
                        person={master}
                        personType='master'
                        index={i}
                      />
                    ))
                  ) : (
                    <Form.Control
                      readOnly
                      type='text'
                      placeholder='-'
                      size='sm'
                    />
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  SCRUM Team
                  <Form.Text className='text-muted'>
                    Old name: Auditor or SME
                  </Form.Text>
                </Form.Label>
                <Col sm={7}>
                  {auditMeta.scrum_team && auditMeta.scrum_team.length ? (
                    auditMeta.scrum_team.map((team, i) => (
                      <InfoPersonInput
                        key={i}
                        person={team}
                        personType='team'
                        index={i}
                      />
                    ))
                  ) : (
                    <Form.Control
                      readOnly
                      type='text'
                      placeholder='-'
                      size='sm'
                    />
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-5'>
            <Col className='border-right'>
              <h5 className='info-column-header'>Report</h5>
              <Form.Group as={Row} controlId='country_ceo_requestor'>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  Country CEO/Requestor
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    readOnly={!editReportData}
                    type='text'
                    name='country_ceo_requestor'
                    value={reportData.country_ceo_requestor}
                    placeholder='-'
                    size='sm'
                    onChange={handleReportDataChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='osaspect_categories_accessed'>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  OS Aspect categories assessed
                </Form.Label>
                <Col sm={7}>
                  <Select
                    options={osAspectCategories}
                    className={`form-control form-control-sm${
                      !editReportData ? ' input-disabled' : ''
                    }`}
                    styles={selectStyles}
                    isMulti={true}
                    isSearchable={false}
                    isDisabled={!editReportData}
                    placeholder='-'
                    name='osaspect_categories_accessed'
                    onChange={handleReportCategoriesChange}
                    value={reportData.osaspect_categories_accessed}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='annual_clearance_audience'>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  Annual Clearance Audience
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    readOnly={!editReportData}
                    type='text'
                    name='annual_clearance_audience'
                    value={reportData.annual_clearance_audience}
                    placeholder='-'
                    size='sm'
                    onChange={handleReportDataChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='annual_clearance_date'>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  Annual Clearance Date
                </Form.Label>
                <Col sm={7}>
                  <Datetime
                    name='annual_clearance_date'
                    inputProps={{
                      className: 'form-control form-control-sm',
                      disabled: !editReportData,
                      placeholder: '-',
                    }}
                    value={reportData.annual_clearance_date}
                    onChange={handleACDChange}
                    closeOnSelect={true}
                    dateFormat='YYYY/MM/DD'
                    timeFormat={false}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='clearance_meeting_date'>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  Clearance meeting date
                </Form.Label>
                <Col sm={7}>
                  <Datetime
                    name='clearance_meeting_date'
                    inputProps={{
                      className: 'form-control form-control-sm',
                      disabled: !editReportData,
                      placeholder: '-',
                    }}
                    value={reportData.clearance_meeting_date}
                    onChange={handleCMDChange}
                    closeOnSelect={true}
                    dateFormat='YYYY/MM/DD'
                    timeFormat={false}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='clearance_meeting_participant'>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  Clearance meeting participant
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    readOnly={!editReportData}
                    type='text'
                    name='clearance_meeting_participant'
                    value={reportData.clearance_meeting_participant}
                    placeholder='-'
                    size='sm'
                    onChange={handleReportDataChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                controlId='clearance_meeting_participant_orgcode'
              >
                <Form.Label className='col-form-label-sm' column sm={5}>
                  Org Code of clearance meeting participant
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    readOnly={!editReportData}
                    type='text'
                    name='clearance_meeting_participant_orgcode'
                    value={reportData.clearance_meeting_participant_orgcode}
                    placeholder='-'
                    size='sm'
                    onChange={handleReportDataChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='founding_year_of_site'>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  Founding year of site
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    readOnly={!editReportData}
                    type='number'
                    name='founding_year_of_site'
                    value={reportData.founding_year_of_site}
                    placeholder='-'
                    size='sm'
                    onChange={handleReportDataChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='site_info'>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  Site information text
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    readOnly={!editReportData}
                    as='textarea'
                    rows='6'
                    name='site_info'
                    value={reportData.site_info}
                    placeholder='-'
                    size='sm'
                    onChange={handleReportDataChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='circular_version'>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  Circular version
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    readOnly={!editReportData}
                    type='number'
                    min='0'
                    step='0.1'
                    name='circular_version'
                    value={reportData.circular_version}
                    placeholder='-'
                    size='sm'
                    onChange={handleReportDataChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='circular_date'>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  Circular date
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    readOnly={!editReportData}
                    type='text'
                    name='circular_date'
                    value={reportData.circular_date}
                    placeholder='-'
                    size='sm'
                    onChange={handleReportDataChange}
                  />
                </Col>
              </Form.Group>

              <Row>
                <Col className='text-right'>
                  {!editReportData ? (
                    <Button
                      variant='primary'
                      size='sm'
                      onClick={() => setEditReportData(true)}
                      disabled={isAuditExported}
                    >
                      Edit report info
                    </Button>
                  ) : (
                    <Fragment>
                      <Button
                        variant='secondary'
                        size='sm'
                        className='mr-2'
                        onClick={handleCancelReportEdit}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant={reportDataChanged ? 'warning' : 'primary'}
                        size='sm'
                        onClick={handleSaveReportData}
                      >
                        {savingReport && (
                          <FontAwesomeIcon
                            icon='circle-notch'
                            spin
                            className='mr-2'
                          />
                        )}
                        Save report info
                      </Button>
                    </Fragment>
                  )}
                </Col>
              </Row>
            </Col>
            <Col>
              <h5 className='info-column-header'>Site</h5>
              <Form.Group as={Row} controlId='typeOfSite'>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  Type of Site *
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    disabled={!editSiteData}
                    as='select'
                    name='type_of_site'
                    size='sm'
                    value={siteData.type_of_site}
                    onChange={handleSiteDataChange}
                  >
                    <option value=''>-</option>
                    <option value='Project'>Project</option>
                    <option value='Manufacturing'>Manufacturing</option>
                    <option value='Service'>Service</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='siteName'>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  Site name
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    readOnly={!editSiteData}
                    type='text'
                    name='site_name'
                    value={siteData.site_name}
                    placeholder='-'
                    size='sm'
                    onChange={handleSiteDataChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='employeesOnSite'>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  No. of employees *
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    readOnly={!editSiteData}
                    type='number'
                    min='0'
                    step='1'
                    name='employee_count'
                    value={siteData.employee_count}
                    placeholder='-'
                    size='sm'
                    onChange={handleSiteDataChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='contractorsOnSite'>
                <Form.Label className='col-form-label-sm' column sm={5}>
                  No. of contractors *
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    readOnly={!editSiteData}
                    type='number'
                    min='0'
                    step='1'
                    name='contractor_count'
                    value={siteData.contractor_count}
                    placeholder='-'
                    size='sm'
                    onChange={handleSiteDataChange}
                  />
                </Col>
              </Form.Group>
              <p className='text-danger small'>{error}</p>
              <Row>
                <Col className='text-right'>
                  {!editSiteData ? (
                    <Button
                      variant='primary'
                      size='sm'
                      onClick={() => setEditSiteData(true)}
                      disabled={isAuditExported}
                    >
                      Edit site info
                    </Button>
                  ) : (
                    <Fragment>
                      <Button
                        variant='secondary'
                        size='sm'
                        className='mr-2'
                        onClick={handleCancelSiteEdit}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant={siteDataChanged ? 'warning' : 'primary'}
                        size='sm'
                        onClick={handleSaveSiteData}
                      >
                        {savingSite && (
                          <FontAwesomeIcon
                            icon='circle-notch'
                            spin
                            className='mr-2'
                          />
                        )}
                        Save site info
                      </Button>
                    </Fragment>
                  )}
                </Col>
              </Row>
              {auditMeta.ares && auditMeta.ares.length ? (
                auditMeta.ares.map((are, i) => (
                  <div key={i}>
                    <hr />
                    <Form.Group as={Row}>
                      <Form.Label className='col-form-label-sm' column sm={5}>
                        ARE
                      </Form.Label>
                      <Col sm={7}>
                        <Form.Control
                          readOnly
                          type='text'
                          value={are.ARENumber}
                          placeholder='-'
                          size='sm'
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label className='col-form-label-sm' column sm={5}>
                        Country
                        <Form.Text className='text-muted'>
                          Physical location
                        </Form.Text>
                      </Form.Label>
                      <Col sm={7}>
                        <Form.Control
                          readOnly
                          type='text'
                          value={are.ARECountry}
                          placeholder='-'
                          size='sm'
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label className='col-form-label-sm' column sm={5}>
                        Location
                      </Form.Label>
                      <Col sm={7}>
                        <Form.Control
                          readOnly
                          type='text'
                          value={are.ARELocation}
                          placeholder='-'
                          size='sm'
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label className='col-form-label-sm' column sm={5}>
                        Fieldwork start date
                      </Form.Label>
                      <Col sm={7}>
                        <Form.Control
                          readOnly
                          type='text'
                          value={are.FieldworkStartDate}
                          placeholder='-'
                          size='sm'
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label className='col-form-label-sm' column sm={5}>
                        Fieldwork end date
                      </Form.Label>
                      <Col sm={7}>
                        <Form.Control
                          readOnly
                          type='text'
                          value={are.FieldworkEndDate}
                          placeholder='-'
                          size='sm'
                        />
                      </Col>
                    </Form.Group>
                    {are.OrgUnit && are.OrgUnit.length
                      ? are.OrgUnit.map((unit, i) => (
                          <div key={i}>
                            <Form.Group as={Row}>
                              <Form.Label
                                className='col-form-label-sm'
                                column
                                sm={5}
                              >
                                Org Unit {i + 1}
                              </Form.Label>
                              <Col sm={7}>
                                <Form.Control
                                  readOnly
                                  type='text'
                                  value={unit.Business}
                                  placeholder='-'
                                  size='sm'
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label
                                className='col-form-label-sm'
                                column
                                sm={{ offset: 1, span: 5 }}
                              >
                                Business Country
                                <Form.Text className='text-muted'>
                                  Old name: Lead country
                                </Form.Text>
                              </Form.Label>
                              <Col sm={6}>
                                <Form.Control
                                  readOnly
                                  type='text'
                                  value={unit.BusinessCountries}
                                  placeholder='-'
                                  size='sm'
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label
                                className='col-form-label-sm'
                                column
                                sm={{ offset: 1, span: 5 }}
                              >
                                Business Unit
                              </Form.Label>
                              <Col sm={6}>
                                <Form.Control
                                  readOnly
                                  type='text'
                                  value={unit.BusinessUnit}
                                  placeholder='-'
                                  size='sm'
                                />
                              </Col>
                            </Form.Group>
                          </div>
                        ))
                      : ''}
                  </div>
                ))
              ) : (
                <div>
                  <hr />
                  <p>
                    <small>No AREs defined for this audit.</small>
                  </p>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className='info-column-header'>Key Facts</h5>
              <AuditTable
                columns={columns}
                data={data}
                updateData={updateData}
                isEdit={editTableData}
              />
            </Col>
          </Row>
          <Row>
            <Col className='text-right'>
              {!editTableData ? (
                <Button
                  variant='primary'
                  size='sm'
                  onClick={() => setEditTableData(true)}
                  disabled={isAuditExported}
                >
                  Edit key facts
                </Button>
              ) : (
                <Fragment>
                  <Button
                    variant='secondary'
                    size='sm'
                    className='mr-2'
                    onClick={handleCancelTableEdit}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant={tableDataChanged ? 'warning' : 'primary'}
                    size='sm'
                    onClick={handleSaveTableData}
                  >
                    {savingTable && (
                      <FontAwesomeIcon
                        icon='circle-notch'
                        spin
                        className='mr-2'
                      />
                    )}
                    Save key facts
                  </Button>
                </Fragment>
              )}
            </Col>
          </Row>
        </Fragment>
      )}
      {!loading && !auditMeta && (
        <p className='text-center'>
          Something went wrong. Audit data is not available.
        </p>
      )}
    </div>
  );
};

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateData, // This is a custom function that we supplied to our table instance
  isEdit,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
    updateData(index, id, e.target.value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Form.Control
      readOnly={!isEdit}
      size='sm'
      type='number'
      value={value}
      onChange={onChange}
    />
  );
};

const defaultColumn = {
  Cell: EditableCell,
};

function AuditTable({ columns, data, updateData, isEdit }) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data,
      defaultColumn,
      updateData,
      isEdit,
    });

  return (
    <Table {...getTableProps()} bordered size='sm'>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default InfoTab;
