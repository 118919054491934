import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DispatchDeleteItemModal from '../../shared/modals/DispatchDeleteItemModal';
import SelectColumnFilter from '../../shared/table/SelectColumnFilter';
import TableFiltered from '../../shared/table/TableFiltered';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchInterviewsRefresh,
  deleteInterview,
} from '../../../store/actions/interviewsActions';
import { toUrlId } from '../../../utils/convertId';
import { filterInterviewsAgainstTypeOfSite } from '../../../utils/filtering';

const SelectInterview = () => {
  const { auditId } = useParams();
  const location = useLocation();
  const queryParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const dispatch = useDispatch();
  const {
    apiData,
    loading,
    isOnline,
    auditSiteType,
    interviewRequestsPending,
  } = useSelector((state) => ({
    apiData: state.interviews.interviews,
    loading: state.interviews.loading,
    isOnline: state.offline.online,
    auditSiteType:
      state.audits.audits.find((audit) => audit.audit_id === parseInt(auditId))
        .type_of_site || '',
    interviewRequestsPending: state.interviews.interviews.some((interview) =>
      interview.hasOwnProperty('loading')
    ),
  }));
  const [modalShow, setModalShow] = useState(false);
  const [selectedFlatRows, setSelectedFlatRows] = useState([]);

  useEffect(() => {
    if (isOnline) {
      dispatch(fetchInterviewsRefresh(auditId));
    }
    // eslint-disable-next-line
  }, []);

  const showTableFilters = true;

  const columns = React.useMemo(
    () => [
      {
        Header: 'Contains questions in report',
        accessor: 'in_report',
        disableFilters: true,
        Cell: ({ value }) => {
          return value === true ? 'Yes' : 'No';
        },
      },
      {
        Header: 'Question category',
        accessor: 'questions_category_letter',
        Cell: ({ value, row: { original } }) => {
          return `${value} - ${original.question_category}`;
        },
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        Header: 'Interviewer',
        accessor: 'auditor',
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        Header: 'Interview partners',
        accessor: 'auditee',
        disableFilters: true,
      },
      {
        Header: 'Questions answered',
        accessor: (row) => `${row.questions_answered}/${row.questions_count}`,
        disableFilters: true,
      },
    ],
    []
  );

  const data = React.useMemo(
    // If navigated from Overview tab KPI with a query parameter,
    // prefilter table for significant/minor management system observations
    () =>
      queryParams.size > 0
        ? apiData
            .filter((interview) => interview.audit_id === parseInt(auditId))
            .filter(filterInterviewsAgainstTypeOfSite(auditSiteType))
            .filter((interview) =>
              interview.questions.some(
                (question) =>
                  question.is_significant === queryParams.get('mso') &&
                  question.in_report === true
              )
            )
        : apiData
            .filter((interview) => interview.audit_id === parseInt(auditId))
            .filter(filterInterviewsAgainstTypeOfSite(auditSiteType)),
    [apiData, auditId, auditSiteType, queryParams]
  );

  const handleChangeSelection = useCallback((row) => {
    setSelectedFlatRows(row);
  }, []);

  return (
    <Fragment>
      {(loading || interviewRequestsPending) && (
        <Row>
          <Col>
            <div className='text-center mb-3'>
              <FontAwesomeIcon icon='circle-notch' spin size='lg' />
            </div>
          </Col>
        </Row>
      )}
      {queryParams.size > 0 && (
        <Alert variant='info'>
          You are now viewing only interviews containing{' '}
          <strong>{queryParams.get('mso')}</strong> management system
          observations added to report. To see all results, open again 'Review
          existing interviews' page from Interviews tab.
        </Alert>
      )}
      <Row>
        <TableFiltered
          data={data}
          columns={columns}
          onChangeSelection={handleChangeSelection}
          showFilters={showTableFilters}
        />
      </Row>
      <Row>
        <Col sm={showTableFilters ? { offset: 3, span: 9 } : 12}>
          {!loading && !interviewRequestsPending && apiData.length > 0 && (
            <div className='text-right'>
              <Link
                to={`/audit/${auditId}/interviews/new-interview`}
                className='mr-2'
              >
                <Button variant='primary'>Start a new interview</Button>
              </Link>
              <Button
                variant='danger'
                className='mr-2'
                disabled={selectedFlatRows.length !== 1}
                onClick={() => setModalShow(true)}
              >
                Delete
              </Button>
              <Link
                to={`/audit/${auditId}/interviews/${
                  selectedFlatRows[0]
                    ? toUrlId(selectedFlatRows[0].original.entity_id)
                    : 0
                }${queryParams.size > 0 ? `?${queryParams.toString()}` : ''}`}
              >
                <Button
                  variant='primary'
                  disabled={selectedFlatRows.length !== 1}
                >
                  Edit
                </Button>
              </Link>
            </div>
          )}
        </Col>
      </Row>

      <DispatchDeleteItemModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        selectedItemId={
          selectedFlatRows.length > 0
            ? toUrlId(selectedFlatRows[0].original.entity_id)
            : ''
        }
        deleteAction={deleteInterview}
        auditId={auditId}
      />
    </Fragment>
  );
};

export default SelectInterview;
