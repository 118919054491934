import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Link, useParams, Prompt } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tags from '../../shared/tags/Tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LinkQuestions from '../../shared/linking/LinkQuestions';
import LinkDocuments from '../../shared/linking/LinkDocuments';
import LinkPhotos from '../../shared/linking/LinkPhotos';
import EntitySyncedModal from '../../shared/modals/EntitySyncedModal';
import { useSelector, useDispatch } from 'react-redux';
import { toGoodPracticeEntityId } from '../../../utils/convertId';
import { updateGP, createGP } from '../../../store/actions/gpsActions';
import { useAppContext } from '../../../utils/auth/context';
import useAuditExported from '../../../utils/customHooks/useAuditExported';

const GoodPracticeForm = () => {
  const { auditId, gpId } = useParams();
  const dispatch = useDispatch();
  const isAuditExported = useAuditExported();
  const { authUser } = useAppContext();
  const { gpDetails } = useSelector((state) => {
    const gp = gpId
      ? state.goodpractices.gps.find(
          (gp) => gp.entity_id === toGoodPracticeEntityId(gpId)
        )
      : null;
    return {
      gpDetails: gp,
    };
  });

  const [buttonLoading, setButtonLoading] = useState(false);
  const [formSaved, setFormSaved] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [pageType, setPageType] = useState('');
  const [error, setError] = useState(null);
  const [tags, setTags] = useState([]);
  const [linkedQuestions, setLinkedQuestions] = useState([]);
  const [linkedDocuments, setLinkedDocuments] = useState([]);
  const [linkedPhotos, setLinkedPhotos] = useState([]);
  const [baseState, setBaseState] = useState({
    area: '',
    in_report: false,
    description: '',
  });
  const pageTypeRef = useRef('');
  const [syncModalShow, setSyncModalShow] = useState(false);

  useEffect(() => {
    // Display modal when currently viewed entity is synchronised
    // and its temporary id is replaced with a backend-generated one
    if (
      pageTypeRef.current === 'edit' &&
      pageType === 'new' &&
      syncModalShow === false
    ) {
      setSyncModalShow(true);
    }
    // eslint-disable-next-line
  }, [pageType]);

  useEffect(() => {
    if (gpDetails) {
      // Case - good practice details page
      setPageType('edit');
      pageTypeRef.current = 'edit';
      const baseData = (({ area, in_report, description }) => ({
        area,
        in_report,
        description: decodeURI(description),
      }))(gpDetails);
      setBaseState(baseData);
      setTags(gpDetails.tags || []);
      setLinkedQuestions(gpDetails.link_questions || []);
      setLinkedDocuments(gpDetails.link_documents || []);
      setLinkedPhotos(gpDetails.link_photos || []);
    } else {
      // Case - new good practice page
      setPageType('new');
    }
    // eslint-disable-next-line
  }, [gpDetails]);

  const handleAddTag = (newTag) => {
    setFormSaved(false);
    setFormChanged(true);
    setTags((tags) => [...tags, newTag]);
  };

  const handleRemoveTag = (tagName) => {
    setFormSaved(false);
    setFormChanged(true);
    setTags(tags.filter((tag) => tag !== tagName));
  };

  const handleAddLinkedQuestion = (linkedQuestion) => {
    setFormSaved(false);
    setFormChanged(true);
    setLinkedQuestions((linkedQuestions) => [
      ...linkedQuestions,
      linkedQuestion,
    ]);
  };

  const handleRemoveLinkedQuestion = (linkedQuestion) => {
    setFormSaved(false);
    setFormChanged(true);
    setLinkedQuestions(
      linkedQuestions.filter((question) => question !== linkedQuestion)
    );
  };

  const handleAddLinkedDocument = (linkedDocument) => {
    setFormSaved(false);
    setFormChanged(true);
    setLinkedDocuments((linkedDocuments) => [
      ...linkedDocuments,
      linkedDocument,
    ]);
  };

  const handleRemoveLinkedDocument = (linkedDocument) => {
    setFormSaved(false);
    setFormChanged(true);
    setLinkedDocuments(
      linkedDocuments.filter((question) => question !== linkedDocument)
    );
  };

  const handleAddLinkedPhoto = (linkedPhotosArray) => {
    setFormSaved(false);
    setFormChanged(true);
    setLinkedPhotos([...new Set([...linkedPhotos, ...linkedPhotosArray])]);
  };

  const handleRemoveLinkedPhoto = (linkedPhoto) => {
    setFormSaved(false);
    setFormChanged(true);
    setLinkedPhotos(linkedPhotos.filter((photo) => photo !== linkedPhoto));
  };

  const handleBaseChange = (e) => {
    setFormSaved(false);
    setFormChanged(true);
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let newBaseState = {
      ...baseState,
      [e.target.name]: value,
    };
    setBaseState(newBaseState);
  };

  const validate = (state) => {
    if (!state.area.trim()) {
      return 'Area field is required.';
    }
    if (!state.description.trim()) {
      return 'Description field is required.';
    }
    return null;
  };

  const handleCloseModal = () => setModalShow(false);
  const handleShowModal = () => setModalShow(true);

  const handleSubmitForm = () => {
    const errorMsg = validate(baseState);
    if (errorMsg) {
      setError(errorMsg);
      return;
    } else {
      setError(null);
      let gpObj = {
        ...baseState,
        desc_in_report: true, // Removed field from UI, but left in request body
        description: encodeURI(baseState.description),
        auditor: authUser ? authUser.username : 'John Doe',
        tags: tags,
        link_photos: linkedPhotos,
        link_questions: linkedQuestions,
        link_documents: linkedDocuments,
      };

      if (pageType === 'new') {
        dispatch(createGP({ auditId, gpObj }));
        handleShowModal();
        clearForm();
      } else if (pageType === 'edit') {
        dispatch(updateGP({ auditId, gpId, gpObj }));
        setFormChanged(false);
        setFormSaved(true);
      }
    }
  };

  const clearForm = () => {
    setBaseState({
      area: '',
      in_report: false,
      description: '',
    });
    setTags([]);
    setLinkedQuestions([]);
    setLinkedDocuments([]);
    setLinkedPhotos([]);
  };

  return (
    <Fragment>
      {pageType === 'edit' && (
        <Prompt
          when={formChanged}
          message='You have unsaved changes. Are you sure you want to leave?'
        />
      )}
      <Form>
        <Row>
          <Col sm={3}>
            <Form.Group controlId='area'>
              <Form.Label>Area *</Form.Label>
              <Form.Control
                type='text'
                size='sm'
                name='area'
                value={baseState.area}
                onChange={handleBaseChange}
              />
            </Form.Group>
            <Form.Group controlId='addToReport'>
              <Form.Check
                type='checkbox'
                label='Add to report'
                name='in_report'
                checked={baseState.in_report}
                onChange={handleBaseChange}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group controlId='description'>
              <Form.Label>Description *</Form.Label>
              <Form.Control
                as='textarea'
                rows='5'
                name='description'
                value={baseState.description}
                onChange={handleBaseChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <div className='pt-4'>
              <LinkPhotos
                linked={linkedPhotos}
                onAddLinkedPhoto={handleAddLinkedPhoto}
                onRemoveLinkedPhoto={handleRemoveLinkedPhoto}
              />
              <LinkQuestions
                linked={linkedQuestions}
                onAddLinkedQuestion={handleAddLinkedQuestion}
                onRemoveLinkedQuestion={handleRemoveLinkedQuestion}
              />
              <LinkDocuments
                linked={linkedDocuments}
                onAddLinkedDocument={handleAddLinkedDocument}
                onRemoveLinkedDocument={handleRemoveLinkedDocument}
              />
              <Tags
                tags={tags}
                onAddTag={handleAddTag}
                onRemoveTag={handleRemoveTag}
              />
            </div>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col>
          <p className='text-danger text-right'>{error}</p>
          <div className='text-right'>
            <Button
              variant={
                pageType === 'edit' && formChanged
                  ? 'warning'
                  : pageType === 'edit' && formSaved
                  ? 'success'
                  : 'primary'
              }
              onClick={handleSubmitForm}
              disabled={isAuditExported}
            >
              {buttonLoading && (
                <FontAwesomeIcon icon='circle-notch' spin className='mr-2' />
              )}
              {pageType === 'new' ? 'Create good practice' : 'Save changes'}
            </Button>
            <Link
              to={
                pageType === 'new'
                  ? `/audit/${auditId}/observations`
                  : `/audit/${auditId}/observations/select-good-practice`
              }
              className='ml-2'
            >
              <Button variant='secondary'>
                {pageType === 'new' ? 'Cancel' : 'Back to Good practices'}
              </Button>
            </Link>
          </div>
        </Col>
      </Row>

      <Modal
        show={modalShow}
        onHide={handleCloseModal}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Good practice created</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          New good practice was successfully created. Proceed to the list of{' '}
          <strong>Good practices</strong> or close the window to create another
          good practice.
        </Modal.Body>
        <Modal.Footer>
          <Link to={`/audit/${auditId}/observations/select-good-practice`}>
            <Button variant='primary'>Good practices</Button>
          </Link>
        </Modal.Footer>
      </Modal>

      <EntitySyncedModal
        show={syncModalShow}
        onHide={() => setSyncModalShow(false)}
        entityType='Good practice'
        entityListLink={`/audit/${auditId}/observations/select-good-practice`}
      />
    </Fragment>
  );
};

export default GoodPracticeForm;
