import React, { Fragment } from 'react';
import NavTiles from '../shared/nav-tiles/NavTiles';
import { useParams } from 'react-router-dom';

const ObservationsTab = () => {
  const { auditId } = useParams();

  const navTilesData = [
    {
      tileIcon: 'plus-circle',
      tileLabel: 'Create a new observation',
      tooltipContent: 'Create a new observation',
      linkPath: `/audit/${auditId}/observations/new-observation`,
    },
    {
      tileIcon: 'play-circle',
      tileLabel: 'Review existing observations',
      tooltipContent: 'Review existing observations',
      linkPath: `/audit/${auditId}/observations/select-observation`,
    },
    {
      tileIcon: 'plus-circle',
      tileLabel: 'Create a good practice',
      tooltipContent: 'Create a good practice',
      linkPath: `/audit/${auditId}/observations/new-good-practice`,
    },
    {
      tileIcon: 'play-circle',
      tileLabel: 'Review good practices',
      tooltipContent: 'Review good practices',
      linkPath: `/audit/${auditId}/observations/select-good-practice`,
    },
  ];
  return (
    <Fragment>
      <div className='mt-5'>
        <NavTiles navTilesData={navTilesData} />
      </div>
    </Fragment>
  );
};

export default ObservationsTab;
