import React, { useState, useEffect } from 'react';
import axiosInst from '../../http/axiosConfig';
import { addInterviewQuestion } from '../../http/requests';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AddQuestionModal = ({
  show,
  onHide,
  questionCategories,
  handleAddQuestion,
  handleAddCategory,
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState(questionCategories);
  const [questionState, setQuestionState] = useState({
    question: '',
    tooltip: '',
    categorySelect: '',
    categoryLetter: '',
    categoryName: '',
  });

  useEffect(() => {
    setCategories(questionCategories);
  }, [questionCategories]);

  const handleChange = (e) => {
    setQuestionState({
      ...questionState,
      [e.target.name]: e.target.value,
    });
  };

  const clearForm = () => {
    setQuestionState({
      question: '',
      tooltip: '',
      categorySelect: '',
      categoryLetter: '',
      categoryName: '',
    });
  };

  const validate = (state) => {
    if (
      !(state.categorySelect || (state.categoryLetter && state.categoryName))
    ) {
      return 'Select a category or enter a new one.';
    }
    if (
      categories
        .map((item) => item.category_letter)
        .indexOf(state.categoryLetter.toUpperCase()) > -1
    ) {
      return 'New category letter must be unique.';
    }
    if (
      categories
        .map((item) => item.question_category.toUpperCase())
        .indexOf(state.categoryName.toUpperCase().trim()) > -1
    ) {
      return 'New category name must be unique.';
    }
    if (!state.question) {
      return 'Question text field is required.';
    }
    if (!state.tooltip) {
      return 'Question tooltip field is required.';
    }
    return null;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const errorMsg = validate(questionState);
    if (errorMsg) {
      setError(errorMsg);
      return;
    } else {
      setError(null);

      const questionObj = {
        questionText: questionState.question,
        tooltip: questionState.tooltip,
      };

      let newCategoryCreated = false;

      if (questionState.categorySelect) {
        questionObj.categoryLetter = questionState.categorySelect.toUpperCase();
        questionObj.questionCategory = categories.find(
          (item) => item.category_letter === questionState.categorySelect
        ).question_category;
      } else {
        questionObj.categoryLetter = questionState.categoryLetter.toUpperCase();
        questionObj.questionCategory = questionState.categoryName.trim();
        newCategoryCreated = true;
      }

      // console.log('submit', questionObj);
      setButtonLoading(true);
      axiosInst(addInterviewQuestion(questionObj))
        .then((response) => {
          handleAddQuestion(response.data);
          if (newCategoryCreated) {
            handleAddCategory({
              category_letter: questionState.categoryLetter.toUpperCase(),
              question_category: questionState.categoryName,
            });
          }
          clearForm();
          onHide();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setButtonLoading(false);
        });
    }
  };

  return (
    <Modal show={show} onHide={onHide} backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Add new question</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id='questionForm' onSubmit={handleFormSubmit}>
          <Form.Group>
            <Form.Label>Category *</Form.Label>
            <Form.Text className='text-muted'>
              Select an existing category or create a new one.
            </Form.Text>
            <Form.Control
              className='mb-3'
              as='select'
              name='categorySelect'
              value={
                questionState.categoryLetter || questionState.categoryName
                  ? ''
                  : questionState.categorySelect
              }
              disabled={
                questionState.categoryLetter || questionState.categoryName
              }
              onChange={handleChange}
            >
              <option value=''>-</option>
              {categories.map((item, index) => (
                <option value={item.category_letter} key={index}>
                  {item.category_letter} - {item.question_category}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col xs={5}>
                <Form.Control
                  type='text'
                  name='categoryLetter'
                  value={
                    questionState.categorySelect
                      ? ''
                      : questionState.categoryLetter
                  }
                  disabled={questionState.categorySelect}
                  onChange={handleChange}
                  placeholder='New category letter'
                  maxLength='1'
                />
              </Col>
              <Col>
                <Form.Control
                  type='text'
                  name='categoryName'
                  value={
                    questionState.categorySelect
                      ? ''
                      : questionState.categoryName
                  }
                  disabled={questionState.categorySelect}
                  onChange={handleChange}
                  placeholder='New category name'
                />
              </Col>
            </Row>
            <Form.Text className='text-muted'>
              Category letter and name must be unique.
            </Form.Text>
          </Form.Group>
          <Form.Group controlId='questionText'>
            <Form.Label>Question text *</Form.Label>
            <Form.Control
              type='text'
              name='question'
              value={questionState.question}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId='questionTooltip'>
            <Form.Label>Question tooltip *</Form.Label>
            <Form.Control
              type='text'
              name='tooltip'
              value={questionState.tooltip}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
        <p className='text-danger'>{error}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>
          Cancel
        </Button>
        <Button variant='primary' form='questionForm' type='submit'>
          {buttonLoading ? (
            <FontAwesomeIcon icon='circle-notch' spin className='mr-2' />
          ) : (
            ''
          )}
          Add question
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// AddQuestionModal.defaultProps = {
//   selectedQuestionId: '',
// };

export default AddQuestionModal;
