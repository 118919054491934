import {
  FETCH_AUDITS,
  FETCH_AUDIT_REFRESH,
  FETCH_AUDIT_REFRESH_COMMIT,
  SET_LOADING,
  UPDATE_AUDIT,
  UPDATE_AUDIT_COMMIT,
  EXPORT_AUDIT,
  DELETE_ARCHIVED_AUDIT,
} from '../types/auditsTypes';

const initialState = {
  audits: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AUDITS:
      return {
        ...state,
        audits: action.payload,
        loading: false,
      };
    case FETCH_AUDIT_REFRESH:
      return {
        ...state,
        audits: state.audits.map((audit) =>
          audit.audit_id === action.payload
            ? {
                ...audit,
                loading: true,
              }
            : audit
        ),
      };
    case FETCH_AUDIT_REFRESH_COMMIT:
      return {
        ...state,
        audits: state.audits.map((audit) => {
          if (audit.audit_id === action.meta.audit_id) {
            const { loading, ...auditDataWithoutLoading } = audit;
            return {
              ...auditDataWithoutLoading,
              ...action.payload.data,
            };
          } else {
            return audit;
          }
        }),
      };
    case UPDATE_AUDIT:
      return {
        ...state,
        audits: state.audits.map((audit) =>
          audit.audit_id === action.payload.audit_id
            ? {
                ...audit,
                ...action.payload.data,
              }
            : audit
        ),
      };
    case UPDATE_AUDIT_COMMIT:
      return {
        ...state,
        audits: state.audits.map((audit) =>
          audit.audit_id === action.meta.audit_id
            ? {
                ...audit,
                ...action.payload.data,
              }
            : audit
        ),
      };
    case EXPORT_AUDIT:
      return {
        ...state,
        audits: state.audits.map((audit) =>
          audit.audit_id === action.payload
            ? {
                ...audit,
                authorized: false,
                sync_status: 'pending',
              }
            : audit
        ),
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case DELETE_ARCHIVED_AUDIT:
      return {
        ...state,
        audits: state.audits.filter(
          (audit) => audit.audit_id !== action.payload
        ),
      };
    default:
      return state;
  }
};
