import React, { useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import axiosInst from '../../../http/axiosConfig';
import { getPresignedUploadUrl } from '../../../http/requests';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import useAuditExported from '../../../utils/customHooks/useAuditExported';
import useAuditInChina from '../../../utils/customHooks/useAuditInChina';
import { convertUploadedFileName } from '../../../utils/convert';
import { photosCategories } from '../../../utils/photos';
import Resizer from 'react-image-file-resizer';
import piexif from 'piexifjs';
import { toast } from 'react-toastify';

import styles from './PhotoUpload.module.scss';

const PhotoUpload = () => {
  const { auditId } = useParams();
  const { isOnline } = useSelector((state) => ({
    isOnline: state.offline.online,
  }));
  const history = useHistory();
  const isAuditExported = useAuditExported();
  const isAuditInChina = useAuditInChina();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [photosCategory, setPhotosCategory] = useState('');
  const fileLimit = 20;
  const fileInput = useRef(null);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800, // maxWidth
        800, // maxHeight
        'JPEG', // compressFormat
        92, // quality
        0, // rotation
        (uri) => {
          // callBack
          resolve(uri);
        },
        'base64' // outputType
      );
    });

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const base64ToFile = async (dataUrl, fileName) => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: 'image/jpeg' });
  };

  const resizeSelectedFile = (file) =>
    new Promise(async (resolve, reject) => {
      try {
        if (file.type.toLowerCase().indexOf('png') > -1) {
          // Case - PNG files - (usually) no exif data
          // Resize image only
          const resizedPhotoBase64 = await resizeFile(file);
          const resizedPhotoFile = await base64ToFile(
            resizedPhotoBase64,
            file.name
          );
          resolve(resizedPhotoFile);
        } else {
          // Case - JPG, JPEG files - contain exif data
          // Resize image and append original exif data,
          // so that backend can handle image orientation properly
          const photoInBase64 = await fileToBase64(file);
          const exifObj = piexif.load(photoInBase64);
          const exifStr = piexif.dump(exifObj);
          const resizedPhotoBase64 = await resizeFile(file);
          const resizedPhotoWithExifBase64 = piexif.insert(
            exifStr,
            resizedPhotoBase64
          );
          const resizedPhotoWithExifFile = await base64ToFile(
            resizedPhotoWithExifBase64,
            file.name
          );
          resolve(resizedPhotoWithExifFile);
        }
      } catch (error) {
        reject(error);
      }
    });

  const handleSelectFiles = async (files) => {
    try {
      const filesArray = Array.from(files)
        .map((file) => {
          // Replace spaces, hashes and diacritics in file name
          return new File([file], convertUploadedFileName(file.name), {
            type: file.type,
          });
        })
        .filter(validateFile);
      if (selectedFiles.length + filesArray.length <= fileLimit) {
        setProcessing(true);
        const filesResized = await Promise.all(
          filesArray.map((file) => resizeSelectedFile(file))
        );
        setProcessing(false);
        setSelectedFiles((prevArray) => [...prevArray, ...filesResized]);
      }
    } catch (err) {
      setProcessing(false);
      console.log(err);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    // Prevent the browser from opening the image
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;
    if (files.length) {
      handleSelectFiles(files);
    }
  };

  const validateFile = (file) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    // Check for duplicated file names
    if (selectedFiles.some((selectedFile) => selectedFile.name === file.name)) {
      return false;
    }
    return true;
  };

  const fileSize = (size) => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const removeFile = (fileName) => {
    setSelectedFiles(selectedFiles.filter((file) => file.name !== fileName));
    fileInput.current.value = '';
  };

  const handleProcessFiles = () => {
    if (isOnline) {
      handleUpload();
    } else {
      handleProcessFilesOffline();
    }
  };

  const handleProcessFilesOffline = () => {
    history.push({
      pathname: `/audit/${auditId}/photo-upload/photo-edit`,
      search: `?offline=true&category=${photosCategory}`,
      state: selectedFiles,
    });
  };

  const handleUpload = () => {
    setLoading(true);
    const timestamp = Date.now();
    axiosInst(
      getPresignedUploadUrl({ auditId, timestamp, category: photosCategory })
    )
      .then((res) => {
        Promise.allSettled(
          selectedFiles.map((file) =>
            uploadFileToS3({ file, presignedData: res.data })
          )
        )
          .then((response) => {
            const uploadedFiles = response.filter(
              (item) => item.status === 'fulfilled'
            );
            const notUploadedFiles = response
              .filter((item) => item.status !== 'fulfilled')
              .map((item) => item.value.name);
            setSelectedFiles(
              selectedFiles.filter(
                (item) => notUploadedFiles.indexOf(item.name) > -1
              )
            );
            setLoading(false);
            if (notUploadedFiles.length) {
              toast.error(
                `Following files were NOT uploaded: ${notUploadedFiles.join(
                  ', '
                )}.`,
                { autoClose: false }
              );
            }
            if (uploadedFiles.length) {
              history.push({
                pathname: `/audit/${auditId}/photo-upload/photo-edit`,
                search: `?sessionId=${timestamp}&fileCount=${uploadedFiles.length}`,
              });
            }
          })
          .catch((err) => setLoading(false));
      })
      .catch((error) => {
        toast.error(
          `Unable to fetch upload URL. Please try to upload files again.`,
          { autoClose: false }
        );
      });
  };

  const uploadFileToS3 = ({ file, presignedData }) => {
    const formData = new FormData();
    Object.keys(presignedData.fields).forEach((key) => {
      formData.append(key, presignedData.fields[key]);
    });
    formData.append('file', file);
    const request = {
      method: 'POST',
      url: presignedData.url,
      data: formData,
    };
    return axios(request)
      .then((response) => {
        return {
          name: file.name,
          isUploaded: true,
        };
      })
      .catch((error) => {
        return {
          name: file.name,
          isUploaded: false,
        };
      });
  };

  return (
    <div className='pt-3 pb-2'>
      {!isOnline && (
        <Alert variant='info'>
          Please note: In offline mode, you should only upload pictures that are
          relevant to the report, since you need to anonymize them manually.
        </Alert>
      )}
      <Alert variant='primary'>
        To fully use photo space in report it's recommended to upload pictures
        in <strong>4:3</strong> aspect ratio and <strong>portrait</strong>{' '}
        orientation.
      </Alert>
      {isAuditInChina && (
        <Alert variant='primary' className={styles.alert_china}>
          <strong>WARNING!!!</strong> For audits in <strong>China</strong>,
          auditors <strong>MUST</strong> anonymize any pictures showing a
          person's face before uploading them to beSafe!
        </Alert>
      )}
      <Row>
        <Col>
          <div
            className={styles.drop_zone}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={() => fileInput.current.click()}
          >
            <p>Click to browse files or drag and drop here...</p>
            <p>
              Max. {fileLimit} images allowed {'(.jpeg, .jpg, .png)'}.
            </p>
            <input
              type='file'
              accept='image/jpeg, image/jpg, image/png'
              ref={fileInput}
              hidden
              multiple
              onChange={(e) => handleSelectFiles(e.target.files)}
            />
          </div>
        </Col>
        <Col>
          <div className='d-flex flex-column justify-content-between h-100'>
            {processing && (
              <div className='text-center'>
                <FontAwesomeIcon icon='circle-notch' spin size='lg' />
              </div>
            )}
            <div className={styles.selected_files}>
              {selectedFiles.map((file, i) => (
                <div className={styles.selected_file} key={i}>
                  <img src={URL.createObjectURL(file)} alt='' />
                  {/* <img src={file.preview_url} alt='' /> */}
                  <div className='text-break'>{file.name}</div>
                  <div className='mx-2'>({fileSize(file.size)})</div>
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className='pointer ml-auto text-danger'
                    onClick={() => removeFile(file.name)}
                  />
                </div>
              ))}
            </div>
            {selectedFiles.length > 0 && (
              <div className='d-flex justify-content-end'>
                <small className='text-muted'>
                  {selectedFiles.length}/{fileLimit} files
                </small>
              </div>
            )}
            <div className='mt-3'>
              <Form.Group controlId='photos_category'>
                <Form.Label>Photos Category *</Form.Label>
                <Form.Control
                  size='sm'
                  as='select'
                  name='photosCategory'
                  value={photosCategory}
                  onChange={(e) => setPhotosCategory(e.target.value)}
                >
                  <option value='' disabled>
                    -
                  </option>
                  {photosCategories &&
                    photosCategories.map((category, i) => (
                      <option value={category.value} key={i}>
                        {category.label}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Button
                variant='primary'
                block
                disabled={
                  selectedFiles.length === 0 ||
                  isAuditExported ||
                  !photosCategory
                }
                onClick={handleProcessFiles}
              >
                {loading && (
                  <FontAwesomeIcon icon='circle-notch' spin className='mr-2' />
                )}
                Upload and anonymize images
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PhotoUpload;
