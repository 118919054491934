import {
  SET_LOADING,
  FETCH_INTERVIEWS_INIT,
  FETCH_INTERVIEWS_REFRESH_COMMIT,
  FETCH_INTERVIEWS_REFRESH_ROLLBACK,
  FETCH_INTERVIEW_DETAILS_INIT,
  FETCH_INTERVIEW_DETAILS_REFRESH,
  FETCH_INTERVIEW_DETAILS_REFRESH_COMMIT,
  FETCH_INTERVIEW_DETAILS_REFRESH_ROLLBACK,
  UPDATE_INTERVIEW_ANSWER,
  UPDATE_INTERVIEW_ANSWER_COMMIT,
  CREATE_INTERVIEW,
  CREATE_INTERVIEW_COMMIT,
  DELETE_INTERVIEW,
} from '../types/interviewsTypes';
import { toUrlId } from '../../utils/convertId';
import { sortByEntityId, sortInterviewQuestions } from '../../utils/sorting';

const initialState = {
  interviews: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case FETCH_INTERVIEWS_INIT:
      return {
        ...state,
        interviews: [
          ...state.interviews,
          ...action.payload.map((interview) => {
            return {
              ...interview,
              questions: [],
            };
          }),
        ].sort(sortByEntityId),
        loading: false,
      };
    case FETCH_INTERVIEWS_REFRESH_COMMIT:
      const interviewsFromOtherAudits = state.interviews.filter(
        (interview) => interview.audit_id !== action.meta.audit_id
      );
      const updatedInterviews = action.payload.data.map((interview) => {
        const existingInterview = state.interviews.find(
          (int) => int.entity_id === interview.entity_id
        );
        if (existingInterview) {
          return {
            ...existingInterview,
            ...interview,
          };
        } else {
          return interview;
        }
      });
      return {
        ...state,
        interviews: [...interviewsFromOtherAudits, ...updatedInterviews].sort(
          sortByEntityId
        ),
        loading: false,
      };
    case FETCH_INTERVIEWS_REFRESH_ROLLBACK: {
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_INTERVIEW_DETAILS_INIT:
      return {
        ...state,
        interviews: state.interviews.map((interview) =>
          interview.entity_id === action.payload.entity_id
            ? {
                ...interview,
                questions: action.payload.questions
                  .map((question) => {
                    return {
                      ...question,
                      link_observations: question.link_observations || [],
                      link_photos: question.link_photos || [],
                      link_documents: question.link_documents || [],
                      tags: question.tags || [],
                      is_significant: question.is_significant || '',
                      question_answer: question.question_answer || '',
                      question_comment: question.question_comment || '',
                      root_cause: question.root_cause || '',
                      impact: question.impact || '',
                    };
                  })
                  .sort(sortInterviewQuestions),
              }
            : interview
        ),
      };
    case FETCH_INTERVIEW_DETAILS_REFRESH:
      return {
        ...state,
        interviews: state.interviews.map((interview) =>
          interview.entity_id === action.payload.entity_id
            ? {
                ...interview,
                loading: true,
              }
            : interview
        ),
      };
    case FETCH_INTERVIEW_DETAILS_REFRESH_COMMIT:
      return {
        ...state,
        interviews: state.interviews.map((interview) => {
          if (interview.entity_id === action.payload.entity_id) {
            const updatedInterview = {
              ...interview,
              questions: action.payload.data
                .map((question) => {
                  return {
                    ...question,
                    link_observations: question.link_observations || [],
                    link_photos: question.link_photos || [],
                    link_documents: question.link_documents || [],
                    tags: question.tags || [],
                    is_significant: question.is_significant || '',
                    question_answer: question.question_answer || '',
                    question_comment: question.question_comment || '',
                    root_cause: question.root_cause || '',
                    impact: question.impact || '',
                  };
                })
                .sort(sortInterviewQuestions),
            };
            delete updatedInterview.loading;
            return updatedInterview;
          } else {
            return interview;
          }
        }),
      };
    case FETCH_INTERVIEW_DETAILS_REFRESH_ROLLBACK:
      return {
        ...state,
        interviews: state.interviews.map((interview) => {
          if (interview.entity_id === action.payload.entity_id) {
            const { loading, ...updatedInterview } = interview;
            return updatedInterview;
          } else {
            return interview;
          }
        }),
      };
    case UPDATE_INTERVIEW_ANSWER:
      return {
        ...state,
        interviews: state.interviews.map((interview) => {
          if (interview.entity_id === action.payload.entity_id) {
            return {
              ...interview,
              questions: updateInterviewQuestions(
                interview.questions,
                action.payload
              ),
              in_report: updateInterviewQuestions(
                interview.questions,
                action.payload
              ).some((q) => q.in_report === true),
              questions_in_report: updateInterviewQuestions(
                interview.questions,
                action.payload
              ).filter((q) => q.in_report === true).length,
              questions_answered: updateInterviewQuestions(
                interview.questions,
                action.payload
              ).filter((q) => q.question_answer).length,
            };
          } else {
            return interview;
          }
        }),
      };
    case UPDATE_INTERVIEW_ANSWER_COMMIT:
      return {
        ...state,
        interviews: state.interviews.map((interview) => {
          if (interview.entity_id === action.meta.entity_id) {
            return {
              ...interview,
              questions: interview.questions.map((question) => {
                if (question.question_id === action.meta.question_id) {
                  return {
                    ...question,
                    ...action.payload.data,
                  };
                } else {
                  return question;
                }
              }),
            };
          } else {
            return interview;
          }
        }),
      };
    case CREATE_INTERVIEW:
      return {
        ...state,
        interviews: [...state.interviews, action.payload.data].sort(
          sortByEntityId
        ),
      };
    case CREATE_INTERVIEW_COMMIT:
      const newEntityId = action.payload.data.entity_id;
      const newCreationTimestamp = action.payload.data.creation_timestamp;
      return {
        ...state,
        interviews: state.interviews
          .map((interview) => {
            if (interview.entity_id === action.meta.temp_entity_id) {
              const { entity_id, ...intData } = interview;
              return {
                ...intData,
                ...action.payload.data,
                questions: interview.questions.map((int) => {
                  return {
                    ...int,
                    entity_id: toUrlId(newEntityId),
                    entity_question: `#${toUrlId(newEntityId)}#${
                      int.question_id
                    }`,
                    creation_timestamp: newCreationTimestamp,
                    update_timestamp: newCreationTimestamp,
                  };
                }),
              };
            } else {
              return interview;
            }
          })
          .sort(sortByEntityId),
      };
    case DELETE_INTERVIEW:
      return {
        ...state,
        interviews: state.interviews.filter(
          (interview) => interview.entity_id !== action.payload.entity_id
        ),
      };
    default:
      return state;
  }
};

const updateInterviewQuestions = (interviewQuestions, actionPayload) => {
  return interviewQuestions.map((question) => {
    if (question.question_id === actionPayload.question_id) {
      return {
        ...question,
        ...actionPayload.data,
      };
    } else {
      return question;
    }
  });
};
