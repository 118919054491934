import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DispatchDeleteItemModal from '../../shared/modals/DispatchDeleteItemModal';
import { isItemLinked } from '../../../utils/linking';
import { readPhotosFromDB, photosCategories } from '../../../utils/photos';
import { useSelector, useDispatch } from 'react-redux';
import {
  deletePhotosArray,
  fetchPhotosRefresh,
} from '../../../store/actions/photosActions';
import * as moment from 'moment';

const PhotoGallery = () => {
  const { auditId } = useParams();
  const dispatch = useDispatch();
  const { apiData, loading, isOnline } = useSelector((state) => ({
    apiData: state.photos.photos.filter(
      (photo) => photo.audit_id === parseInt(auditId)
    ),
    loading: state.photos.loading,
    isOnline: state.offline.online,
  }));
  const history = useHistory();
  const [photoBlobs, setPhotoBlobs] = useState(null);
  const [displayedPhotos, setDisplayedPhotos] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState(new Map());
  const [modalShow, setModalShow] = useState(false);
  const [category, setCategory] = useState('');
  const [linkFilter, setLinkFilter] = useState('');
  const [criticalityFilter, setCriticalityFilter] = useState('');
  const [dateSortOrder, setDateSortOrder] = useState('oldest');
  const [readingBlobs, setReadingBlobs] = useState(false);
  const didMountRef = useRef(false);

  const filterShowAll = (photo) => {
    return photo;
  };

  const [linkFilterFunction, setLinkFilterFunction] = useState(
    () => filterShowAll
  );
  const [criticalityFilterFunction, setCriticalityFilterFunction] = useState(
    () => filterShowAll
  );

  useEffect(() => {
    if (isOnline) {
      dispatch(fetchPhotosRefresh(auditId));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function readBlobs(photoArray) {
      const photoBlobsFromDB = await readPhotosFromDB(photoArray);
      setPhotoBlobs(photoBlobsFromDB);
      setDisplayedPhotos(sortByCreationDate(dateSortOrder, apiData));
      setReadingBlobs(false);
    }
    setReadingBlobs(true);
    readBlobs(apiData);
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (didMountRef.current) {
      const filteredPhotos = apiData
        .filter(linkFilterFunction)
        .filter(criticalityFilterFunction)
        .filter((photo) => (category ? photo.category === category : photo));
      setDisplayedPhotos(sortByCreationDate(dateSortOrder, filteredPhotos));
    } else {
      didMountRef.current = true;
    }
    // eslint-disable-next-line
  }, [linkFilterFunction, criticalityFilterFunction, category]);

  const handlePhotoClick = (photo) => {
    if (selectedPhotos.has(photo.photo_id)) {
      setSelectedPhotos((prev) => {
        const newState = new Map(prev);
        newState.delete(photo.photo_id);
        return newState;
      });
    } else {
      setSelectedPhotos((prev) => new Map([...prev, [photo.photo_id, photo]]));
    }
  };

  const clearSelection = () => {
    setSelectedPhotos(new Map());
  };

  const editSelectedPhotos = () => {
    history.push(
      `/audit/${auditId}/photo-gallery/photo-edit`,
      Array.from(selectedPhotos.keys())
    );
  };

  const handleRemoveImageFromUI = (photoIdsList) => {
    // Clear selected photos
    clearSelection();
    const updatedDisplayedImageList = displayedPhotos.filter((item) => {
      return !photoIdsList.includes(item.photo_id);
    });
    setPhotoBlobs(removeKeys(photoIdsList, photoBlobs));
    setDisplayedPhotos(updatedDisplayedImageList);
  };

  const removeKeys = (arr, obj) => {
    const objCopy = { ...obj };
    arr.forEach((key) => {
      delete objCopy[key];
    });
    return objCopy;
  };

  const filterInterviewLinkedPhotos = (photo) => {
    return photo.link_questions && photo.link_questions.length > 0;
  };

  const filterObservationLinkedPhotos = (photo) => {
    return photo.link_observations && photo.link_observations.length > 0;
  };

  const filterInterviewAndObservationLinkedPhotos = (photo) => {
    return (
      photo.link_questions &&
      photo.link_questions.length > 0 &&
      photo.link_observations &&
      photo.link_observations.length > 0
    );
  };

  const filterNonLinkedPhotos = (photo) => {
    return (
      (!photo.link_questions || photo.link_questions.length === 0) &&
      (!photo.link_observations || photo.link_observations.length === 0)
    );
  };

  const filterCriticalLinkedPhotos = (photo) => {
    return photo.linked_critical;
  };

  const filterMinorLinkedPhotos = (photo) => {
    return (
      ((photo.link_questions && photo.link_questions.length > 0) ||
        (photo.link_observations && photo.link_observations.length > 0)) &&
      !photo.linked_critical
    );
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    clearSelection();
  };

  const handleLinkFilterChange = (e) => {
    const value = e.target.value;
    setLinkFilter(value);
    clearSelection();
    let filteringFunction;
    if (value === '') {
      filteringFunction = filterShowAll;
    } else if (value === 'int') {
      filteringFunction = filterInterviewLinkedPhotos;
    } else if (value === 'obs') {
      filteringFunction = filterObservationLinkedPhotos;
    } else if (value === 'int-obs') {
      filteringFunction = filterInterviewAndObservationLinkedPhotos;
    } else if (value === 'non') {
      filteringFunction = filterNonLinkedPhotos;
    }
    setLinkFilterFunction(() => filteringFunction);
  };

  const handleCriticalityFilterChange = (e) => {
    const value = e.target.value;
    setCriticalityFilter(value);
    clearSelection();
    let filteringFunction;
    if (value === '') {
      filteringFunction = filterShowAll;
    } else if (value === 'critical') {
      filteringFunction = filterCriticalLinkedPhotos;
    } else if (value === 'minor') {
      filteringFunction = filterMinorLinkedPhotos;
    }
    setCriticalityFilterFunction(() => filteringFunction);
  };

  const clearFilters = () => {
    clearSelection();
    setLinkFilter('');
    setCriticalityFilter('');
    setLinkFilterFunction(() => filterShowAll);
    setCriticalityFilterFunction(() => filterShowAll);
    setCategory('');
  };

  const handleSortDateChange = (e) => {
    const value = e.target.value;
    setDateSortOrder(value);
    clearSelection();
    setDisplayedPhotos(sortByCreationDate(value, displayedPhotos));
  };

  const sortByCreationDate = (sortOrder, photosArray) => {
    if (sortOrder === 'oldest') {
      return photosArray.sort(
        (a, b) =>
          new moment(a.creation_timestamp) - new moment(b.creation_timestamp)
      );
    } else {
      return photosArray.sort(
        (a, b) =>
          new moment(b.creation_timestamp) - new moment(a.creation_timestamp)
      );
    }
  };

  return (
    <Fragment>
      <Row>
        <Col sm={3}>
          <Form>
            <Form.Group controlId='sort_by_creation_date'>
              <Form.Label>Sort by creation date:</Form.Label>
              <Form.Control
                size='sm'
                as='select'
                name='sort_by_creation_date'
                value={dateSortOrder}
                onChange={handleSortDateChange}
              >
                <option value='oldest'>Oldest first</option>
                <option value='newest'>Newest first</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId='link_category'>
              <Form.Label>Filter by category:</Form.Label>
              <Form.Control
                size='sm'
                as='select'
                name='category_filter'
                value={category}
                onChange={handleCategoryChange}
              >
                <option value=''>-</option>
                {photosCategories &&
                  photosCategories.map((category, i) => (
                    <option value={category.value} key={i}>
                      {category.label}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId='link_filter'>
              <Form.Label>Filter by linking:</Form.Label>
              <Form.Control
                size='sm'
                as='select'
                name='link_filter'
                value={linkFilter}
                onChange={handleLinkFilterChange}
              >
                <option value=''>Show all</option>
                <option value='non'>Non linked</option>
                <option value='int'>Linked to interviews</option>
                <option value='obs'>Linked to observations</option>
                <option value='int-obs'>
                  Linked to interviews and observations
                </option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId='criticality_filter'>
              <Form.Label>Filter by criticality:</Form.Label>
              <Form.Control
                size='sm'
                as='select'
                name='criticality_filter'
                value={criticalityFilter}
                onChange={handleCriticalityFilterChange}
              >
                <option value=''>Show all</option>
                <option value='critical'>Critical / CTS</option>
                <option value='minor'>Minor</option>
              </Form.Control>
            </Form.Group>
            <Button size='sm' block onClick={() => clearFilters()}>
              Clear filters
            </Button>
          </Form>
        </Col>
        <Col sm={9}>
          {loading && (
            <div className='text-center mb-3'>
              <FontAwesomeIcon icon='circle-notch' spin size='lg' />
            </div>
          )}
          {!loading &&
            !readingBlobs &&
            apiData.length > 0 &&
            (!photoBlobs ||
              Object.keys(photoBlobs).length < apiData.length) && (
              <Alert variant='warning'>
                Something went wrong - some photo files are not available. To
                load them, restore network connection and reopen Photo Gallery.
              </Alert>
            )}
          {displayedPhotos.length > 0 &&
            photoBlobs &&
            Object.keys(photoBlobs).length > 0 && (
              <Fragment>
                <div className='border mb-3 thumbnails-wrapper'>
                  {displayedPhotos.map(
                    (photo, i) =>
                      photoBlobs[photo.photo_id] && (
                        <div
                          className={
                            'pointer p-2 thumbnail-item ' +
                            (selectedPhotos.has(photo.photo_id)
                              ? 'selected'
                              : '')
                          }
                          key={i}
                          onClick={() => handlePhotoClick(photo)}
                        >
                          <img src={photoBlobs[photo.photo_id] || ''} alt='' />
                        </div>
                      )
                  )}
                </div>
                {!loading && (
                  <div className='text-right'>
                    <Button
                      variant='danger'
                      className='mr-2'
                      disabled={selectedPhotos.size === 0}
                      onClick={() => setModalShow(true)}
                    >
                      Delete
                    </Button>
                    <Button
                      variant='primary'
                      disabled={selectedPhotos.size === 0}
                      onClick={() => editSelectedPhotos()}
                    >
                      Edit
                    </Button>
                  </div>
                )}
              </Fragment>
            )}
          {!loading && apiData.length > 0 && displayedPhotos.length === 0 && (
            <p className='text-center'>No results for selected filters</p>
          )}
          {!loading && apiData.length === 0 && (
            <p className='text-center'>No photos added for this audit</p>
          )}
        </Col>
      </Row>

      <DispatchDeleteItemModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        selectedItemId={Array.from(selectedPhotos.keys())}
        isLinked={isItemLinked(Array.from(selectedPhotos.values()))}
        handleRemoveFromTable={handleRemoveImageFromUI}
        deleteAction={deletePhotosArray}
        auditId={auditId}
      />
    </Fragment>
  );
};

export default PhotoGallery;
