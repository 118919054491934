import { FETCH_OBSERVATIONS_REFRESH_COMMIT } from '../types/observationsTypes';
import { toUrlId } from '../../utils/convertId';
import { fetchObservationDetailsRefresh } from '../actions/observationsActions';

export const observationsRefreshMiddleware = ({ getState, dispatch }) => {
  return function (next) {
    return async function (action) {
      if (action.type === FETCH_OBSERVATIONS_REFRESH_COMMIT) {
        action.payload.data.forEach((observation) => {
          const { audit_id, entity_id } = observation;
          dispatch(
            fetchObservationDetailsRefresh({
              auditId: audit_id,
              observationId: toUrlId(entity_id),
            })
          );
        });
      }
      return next(action);
    };
  };
};
