import * as localForage from 'localforage';
import axios from 'axios';

// Check if photo with a given key is already stored in local forage
const isPhotoStored = async (photoKey) => {
  return (await localForage.getItem(photoKey)) !== null;
};

export const storePhotoFile = async (photo) => {
  // Check if photo is already stored
  const photoKey = getPhotoKey(photo);
  if (!(await isPhotoStored(photoKey))) {
    const response = await axios({
      method: 'get',
      url: photo.photo_url,
      responseType: 'blob',
    });
    return await localForage.setItem(
      photoKey,
      new File([response.data], photo.upload_file_name, {
        type: `image/${getFileType(photo.upload_file_name)}`,
      })
    );
  }
};

// For a given array of photo objects, reads local forage and
// returns an object { photo_id: blob_url, ... }
export const readPhotosFromDB = async (photoArray) => {
  const res = await Promise.all(
    photoArray.map((photo) => readSinglePhotoFromDB(photo))
  );
  return res.reduce((acc, curr) => {
    if (curr) {
      acc[curr.photo_id] = curr.blob_url;
      return acc;
    }
    return acc;
  }, {});
};

const readSinglePhotoFromDB = (photo) => {
  return localForage.getItem(getPhotoKey(photo)).then((res) => {
    if (res) {
      return {
        photo_id: photo.photo_id,
        blob_url: URL.createObjectURL(res),
      };
    }
  });
};

export const readPhotoByPhotoKey = (photoKey) => {
  return localForage.getItem(photoKey).then((res) => {
    if (res) {
      return URL.createObjectURL(res);
    }
  });
};

export const getPhotoKey = (photo) => {
  if (photo) {
    return `${new Date(photo.update_timestamp).getTime()}_${photo.session_id}_${
      photo.upload_file_name
    }`;
  } else {
    return '';
  }
};

export const getFileType = (fileName) => {
  const fileType = fileName.substring(
    fileName.lastIndexOf('.') + 1,
    fileName.length
  );
  // return fileType === 'jpg' && offlinePath ? 'jpeg' : fileType;
  return fileType;
};

export const keyToPhotoId = (key) => {
  // Convert key (<update_timestamp>_<session_id>_<file_name>)
  // to photo_id format (<session_id>#<file_name>)
  const part = key.substring(key.indexOf('_') + 1);
  return `${part.substring(0, part.indexOf('_'))}#${part.substring(
    part.indexOf('_') + 1
  )}`;
};

export const removeDuplicatePhotos = async () => {
  const localForageKeys = await localForage.keys();
  localForageKeys.forEach(async (key) => {
    const staticKeyPart = key.substring(key.indexOf('_') + 1);
    const photoKeysArray = localForageKeys
      .filter((key) => key.indexOf(staticKeyPart) > -1)
      .sort();
    if (photoKeysArray.length > 1) {
      photoKeysArray.pop();
      for (let k of photoKeysArray) {
        await localForage.removeItem(k);
      }
    }
  });
};

export const photosCategories = [
  { value: 'electrical_safety', label: 'Electrical Safety' },
  { value: 'energy_isolation', label: 'Energy Isolation' },
  { value: 'hazardous_substances', label: 'Hazardous Substances' },
  { value: 'mechanical_safety', label: 'Mechanical Safety' },
  { value: 'excavation_works', label: 'Excavation Works' },
  { value: 'confined_spaces', label: 'Confined Spaces' },
  {
    value: 'fire_safety_and_emergency_management',
    label: 'Fire Safety and Emergency Management',
  },
  { value: 'working_at_height', label: 'Working @ Height' },
  { value: 'lifting_procedures', label: 'Lifting Procedures' },
];
