import { UPDATE_PHOTO_META_COMMIT } from '../types/photosTypes';
import * as localForage from 'localforage';
import { getPhotoKey, getFileType } from '../../utils/photos';
import axios from 'axios';

export const photoUpdateMiddleware = ({ getState, dispatch }) => {
  return function (next) {
    return async function (action) {
      if (action.type === UPDATE_PHOTO_META_COMMIT) {
        // After photo was updated and it's update_timestamp changed
        // replace photo's key in localForage to reflect most recent changes
        const updatedPhotoMeta = action.payload.data;
        const { session_id, upload_file_name } = updatedPhotoMeta;
        const localForageKeys = await localForage.keys();
        const outdatedPhotoKey = localForageKeys.find(
          (key) => key.indexOf(`${session_id}_${upload_file_name}`) > -1
        );
        if (outdatedPhotoKey) {
          // Fetch latest version of the photo from AWS and store it in localforage
          const response = await axios({
            method: 'get',
            url: action.meta.photo_url,
            responseType: 'blob',
          });
          await localForage.removeItem(outdatedPhotoKey);
          await localForage.setItem(
            getPhotoKey(updatedPhotoMeta),
            new File([response.data], upload_file_name, {
              type: `image/${getFileType(upload_file_name)}`,
            })
          );
        }
      }
      return next(action);
    };
  };
};
