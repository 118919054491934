import React, { Fragment, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import TableFiltered from '../table/TableFiltered';
import SelectColumnFilter from '../table/SelectColumnFilter';
import { useSelector } from 'react-redux';
import { getFileType } from '../../../utils/photos';

const LinkDocuments = ({
  linked,
  onAddLinkedDocument,
  onRemoveLinkedDocument,
}) => {
  const { auditId } = useParams();
  const history = useHistory();
  const { documents, auditDocuments } = useSelector((state) => ({
    documents: state.documents.documents,
    auditDocuments: state.documents.documents.filter(
      (document) =>
        document.audit_id === parseInt(auditId) || document.audit_id === 0
    ),
  }));
  const [show, setShow] = useState(false);
  const [selectedFlatRows, setSelectedFlatRows] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
        disableFilters: true,
      },
      {
        Header: 'File type',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          return `.${getFileType(original.document_id)}`;
        },
      },
      {
        Header: 'Category',
        accessor: 'category',
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        Header: 'Permanent',
        accessor: 'permanent',
        disableFilters: true,
        Cell: ({ value }) => {
          return value ? 'Yes' : 'No';
        },
      },
    ],
    []
  );

  const handleAddLinkedDocument = () => {
    const linkedDoc = selectedFlatRows[0].original.document_id;
    // Check for duplicates
    if (!linked.some((el) => el === linkedDoc)) {
      onAddLinkedDocument(linkedDoc);
    }
  };

  const handleRemoveLinkedDocument = (linkedDoc) => {
    onRemoveLinkedDocument(linkedDoc);
  };

  const data = React.useMemo(
    () =>
      documents.filter(
        (document) =>
          document.audit_id === parseInt(auditId) || document.audit_id === 0
      ),
    [documents, auditId]
  );

  const handleCloseModal = () => {
    setShow(false);
    // toggleAllRowsSelected(false);
  };

  const handleChangeSelection = useCallback((row) => {
    setSelectedFlatRows(row);
  }, []);

  const showDocumentDetails = (documentObj) => {
    history.push(`/audit/${auditId}/documents/edit`, documentObj);
  };

  return (
    <Fragment>
      <div>
        <h6 className='d-inline-block'>Linked documents:</h6>
        <FontAwesomeIcon
          icon='plus-circle'
          className='pointer ml-2 align-middle'
          onClick={() => setShow(true)}
        />
        {linked.length > 0 && (
          <div className='mb-2'>
            {linked.map((item, index) => {
              const doc = auditDocuments.find((el) => el.document_id === item);
              if (doc) {
                return (
                  <Fragment key={index}>
                    {index > 0 && <span className='mx-2'>|</span>}
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id={`tooltip-${index}`}>
                          Document title: {doc.title}
                          <br />
                          Category: {doc.category}
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon='file-alt'
                        size='lg'
                        color='#2387aa'
                        className='pointer'
                        onClick={() =>
                          showDocumentDetails({
                            documentId: item,
                            originalAuditId: doc.audit_id,
                          })
                        }
                      />
                    </OverlayTrigger>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className='pointer ml-2 text-danger'
                      onClick={() => handleRemoveLinkedDocument(item)}
                    />
                  </Fragment>
                );
              } else return '';
            })}
          </div>
        )}
      </div>
      <Modal
        show={show}
        onHide={handleCloseModal}
        size='xl'
        aria-labelledby='documents-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title id='documents-modal'>Select document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <TableFiltered
              data={data}
              columns={columns}
              onChangeSelection={handleChangeSelection}
              showFilters={true}
            />
          </Row>
          {auditDocuments.length > 0 && (
            <Row>
              <Col>
                <div className='text-right'>
                  <Button
                    variant='primary'
                    disabled={selectedFlatRows.length !== 1}
                    onClick={handleAddLinkedDocument}
                  >
                    Link selected element
                  </Button>
                </div>
              </Col>
            </Row>
          )}
          <Row className='mt-2'>
            <Col>
              <h6>Linked documents:</h6>
              {linked.length > 0 &&
                linked.map((item, index) => {
                  const doc = auditDocuments.find(
                    (el) => el.document_id === item
                  );
                  if (doc) {
                    return (
                      <p key={index}>
                        <strong>Document title</strong>: {doc.title} |{' '}
                        <strong>Category</strong>: {doc.category}
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className='pointer ml-2 text-danger'
                          onClick={() => handleRemoveLinkedDocument(item)}
                        />
                      </p>
                    );
                  } else return '';
                })}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

LinkDocuments.defaultProps = {
  linked: [],
};

export default LinkDocuments;
