import {
  SET_LOADING,
  FETCH_GPS_INIT,
  FETCH_GPS_REFRESH_COMMIT,
  FETCH_GPS_REFRESH_ROLLBACK,
  UPDATE_GP,
  UPDATE_GP_COMMIT,
  CREATE_GP,
  CREATE_GP_COMMIT,
  DELETE_GP,
} from '../types/gpsTypes';
import { sortByEntityId } from '../../utils/sorting';

const initialState = {
  gps: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case FETCH_GPS_INIT:
      return {
        ...state,
        gps: [
          ...state.gps,
          ...action.payload.map((gp) => {
            return {
              ...gp,
              link_questions: gp.link_questions || [],
              link_photos: gp.link_photos || [],
              link_documents: gp.link_documents || [],
              tags: gp.tags || [],
            };
          }),
        ].sort(sortByEntityId),
        loading: false,
      };
    case FETCH_GPS_REFRESH_COMMIT:
      const gpsFromOtherAudits = state.gps.filter(
        (gp) => gp.audit_id !== action.meta.audit_id
      );
      const updatedGps = action.payload.data.map((gp) => {
        const existingGp = state.gps.find((m) => m.gp_id === gp.gp_id);
        if (existingGp) {
          return {
            ...existingGp,
            ...gp,
            link_questions: gp.link_questions || [],
            link_photos: gp.link_photos || [],
            link_documents: gp.link_documents || [],
            tags: gp.tags || [],
          };
        } else {
          return gp;
        }
      });
      return {
        ...state,
        gps: [...gpsFromOtherAudits, ...updatedGps].sort(sortByEntityId),
        loading: false,
      };
    case FETCH_GPS_REFRESH_ROLLBACK:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_GP:
      return {
        ...state,
        gps: state.gps.map((gp) =>
          gp.entity_id === action.payload.entity_id
            ? {
                ...gp,
                ...action.payload.data,
              }
            : gp
        ),
      };
    case UPDATE_GP_COMMIT:
      return {
        ...state,
        gps: state.gps.map((gp) =>
          gp.entity_id === action.payload.data.entity_id
            ? {
                ...gp,
                ...action.payload.data,
              }
            : gp
        ),
      };
    case CREATE_GP:
      return {
        ...state,
        gps: [...state.gps, action.payload.data].sort(sortByEntityId),
      };
    case CREATE_GP_COMMIT:
      return {
        ...state,
        gps: state.gps.map((gp) => {
          if (gp.entity_id === action.meta.temp_entity_id) {
            const { entity_id, ...gpData } = gp;
            return {
              ...gpData,
              ...action.payload.data,
              link_photos: gpData.link_photos,
              link_questions: gpData.link_questions,
              link_documents: gpData.link_documents,
            };
          } else {
            return gp;
          }
        }),
      };
    case DELETE_GP:
      return {
        ...state,
        gps: state.gps.filter(
          (gp) => gp.entity_id !== action.payload.entity_id
        ),
      };
    default:
      return state;
  }
};
