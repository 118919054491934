import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export default function useAuditExported() {
  // Check if audit contains 'sync_status' field
  // which indicates that data export was initiated
  const { auditId } = useParams();
  return useSelector((state) =>
    state.audits.audits
      .find((audit) => audit.audit_id === parseInt(auditId))
      .hasOwnProperty('sync_status')
  );
}
