import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

const BreadcrumbsBar = () => {
  const breadcrumbs = useBreadcrumbs();

  return (
    <Breadcrumb className='my-3'>
      {breadcrumbs.map((br) => (
        <Breadcrumb.Item
          key={br.key}
          active={br.location.pathname === br.match.url}
          linkAs={Link}
          linkProps={{
            to: br.match.url === '/audit' ? '/select-audit' : br.match.url,
          }}
        >
          {br.match.url === '/audit' ? 'Select-audit' : br.breadcrumb}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default BreadcrumbsBar;
