import React, { Fragment } from 'react';
import PageName from '../layout/PageName';
import QuestionsTable from '../editQuestions/QuestionsTable';

const EditInterviewCatalogue = () => {
  return (
    <Fragment>
      <PageName name='Edit interview catalogue' />
      <QuestionsTable />
    </Fragment>
  );
};

export default EditInterviewCatalogue;
