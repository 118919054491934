import {
  SET_LOADING,
  FETCH_GPS_INIT,
  FETCH_GPS_REFRESH_COMMIT,
  FETCH_GPS_REFRESH_ROLLBACK,
  UPDATE_GP,
  UPDATE_GP_COMMIT,
  CREATE_GP,
  CREATE_GP_COMMIT,
  DELETE_GP,
  DELETE_GP_COMMIT,
} from '../types/gpsTypes';
import axiosInst from '../../http/axiosConfig';
import {
  fetchGPList,
  updateGP as updateGPRequest,
  createNewGP,
  deleteGPById,
} from '../../http/requests';
import { toGoodPracticeEntityId } from '../../utils/convertId';
import { toast } from 'react-toastify';

export const fetchGPsInit = (auditId) => {
  return (dispatch) => {
    axiosInst(fetchGPList(auditId))
      .then((response) => {
        dispatch({
          type: FETCH_GPS_INIT,
          payload: response.data,
        });
      })
      .catch((error) => {
        //
      });
  };
};

export const fetchGPsRefresh = (auditId) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      payload: true,
      meta: {
        offline: {
          effect: fetchGPList(auditId),
          commit: {
            type: FETCH_GPS_REFRESH_COMMIT,
            meta: {
              audit_id: parseInt(auditId),
            },
          },
          rollback: {
            type: FETCH_GPS_REFRESH_ROLLBACK,
          },
        },
      },
    });
  };
};

export const updateGP = ({ auditId, gpId, gpObj }) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_GP,
      payload: {
        entity_id: toGoodPracticeEntityId(gpId),
        data: gpObj,
      },
      meta: {
        offline: {
          effect: updateGPRequest({ auditId, gpId, gpObj }),
          commit: {
            type: UPDATE_GP_COMMIT,
            meta: {
              then: () => () => {
                toast.success('Good practice updated');
              },
            },
          },
        },
      },
    });
  };
};

export const createGP = ({ auditId, gpObj }) => {
  // Create temporary id - timestamp
  const tempId = `GP${Date.now()}_temp`;
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_GP,
      payload: {
        entity_id: toGoodPracticeEntityId(tempId),
        data: {
          ...gpObj,
          entity_id: toGoodPracticeEntityId(tempId),
          audit_id: parseInt(auditId),
        },
      },
      meta: {
        offline: {
          effect: createNewGP({ auditId, gpObj }),
          commit: {
            type: CREATE_GP_COMMIT,
            meta: {
              temp_entity_id: toGoodPracticeEntityId(tempId),
              then: () => () => {
                toast.success('Good practice created');
              },
            },
          },
        },
      },
    });
  };
};

export const deleteGP = ([auditId, gpId]) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_GP,
      payload: {
        entity_id: toGoodPracticeEntityId(gpId),
      },
      meta: {
        offline: {
          effect: deleteGPById([auditId, gpId]),
          commit: {
            type: DELETE_GP_COMMIT,
            meta: {
              then: () => () => {
                toast.success('Good practice deleted');
              },
            },
          },
        },
      },
    });
  };
};
