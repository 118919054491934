import {
  SET_LOADING,
  FETCH_OBSERVATIONS_INIT,
  FETCH_OBSERVATIONS_REFRESH_COMMIT,
  FETCH_OBSERVATIONS_REFRESH_ROLLBACK,
  FETCH_OBSERVATION_DETAILS_INIT,
  FETCH_OBSERVATION_DETAILS_REFRESH,
  FETCH_OBSERVATION_DETAILS_REFRESH_COMMIT,
  FETCH_OBSERVATION_DETAILS_REFRESH_ROLLBACK,
  UPDATE_OBSERVATION,
  UPDATE_OBSERVATION_COMMIT,
  CREATE_OBSERVATION,
  CREATE_OBSERVATION_COMMIT,
  DELETE_OBSERVATION,
  DELETE_OBSERVATION_COMMIT,
} from '../types/observationsTypes';
import axiosInst from '../../http/axiosConfig';
import {
  fetchObservationsList,
  fetchObservationDetails,
  updateObservation as updateObservationRequest,
  createNewObservation,
  deleteObservationById,
} from '../../http/requests';
import { toUrlId, toObservationEntityId } from '../../utils/convertId';
import { toast } from 'react-toastify';

export const fetchObservationsInit = (auditId) => {
  return (dispatch) => {
    // dispatch({
    //   type: SET_LOADING,
    //   payload: true,
    // });
    axiosInst(fetchObservationsList(auditId))
      .then((response) => {
        const auditObservations = response.data;
        dispatch({
          type: FETCH_OBSERVATIONS_INIT,
          payload: auditObservations,
        });
        auditObservations.forEach((observation) => {
          dispatch(
            fetchObservationDetailsInit({
              auditId,
              observationId: toUrlId(observation.entity_id),
            })
          );
        });
      })
      .catch((error) => {
        //
      });
  };
};

export const fetchObservationDetailsInit = ({ auditId, observationId }) => {
  return (dispatch) => {
    axiosInst(fetchObservationDetails({ auditId, observationId }))
      .then((response) => {
        dispatch({
          type: FETCH_OBSERVATION_DETAILS_INIT,
          payload: response.data,
        });
      })
      .catch((error) => {
        //
      });
  };
};

export const fetchObservationsRefresh = (auditId) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      payload: true,
      meta: {
        offline: {
          effect: fetchObservationsList(auditId),
          commit: {
            type: FETCH_OBSERVATIONS_REFRESH_COMMIT,
            meta: {
              audit_id: parseInt(auditId),
            },
          },
          rollback: {
            type: FETCH_OBSERVATIONS_REFRESH_ROLLBACK,
          },
        },
      },
    });
  };
};

export const fetchObservationDetailsRefresh = ({ auditId, observationId }) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_OBSERVATION_DETAILS_REFRESH,
      payload: {
        entity_id: toObservationEntityId(observationId),
      },
    });
    axiosInst(fetchObservationDetails({ auditId, observationId }))
      .then((response) => {
        dispatch({
          type: FETCH_OBSERVATION_DETAILS_REFRESH_COMMIT,
          payload: {
            entity_id: toObservationEntityId(observationId),
            data: response.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_OBSERVATION_DETAILS_REFRESH_ROLLBACK,
          payload: {
            entity_id: toObservationEntityId(observationId),
          },
        });
      });
  };
};

export const updateObservation = ({
  auditId,
  observationId,
  observationObj,
}) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_OBSERVATION,
      payload: {
        entity_id: toObservationEntityId(observationId),
        data: observationObj,
      },
      meta: {
        offline: {
          effect: updateObservationRequest({
            auditId,
            observationId,
            observationObj,
          }),
          commit: {
            type: UPDATE_OBSERVATION_COMMIT,
            meta: {
              then: () => () => {
                toast.success('Observation updated');
              },
            },
          },
        },
      },
    });
  };
};

export const createObservation = ({ auditId, observationObj }) => {
  // Create temporary id - timestamp
  const tempId = `O${Date.now()}_temp`;
  return (dispatch) => {
    dispatch({
      type: CREATE_OBSERVATION,
      payload: {
        entity_id: toObservationEntityId(tempId),
        data: {
          ...observationObj,
          entity_id: toObservationEntityId(tempId),
          audit_id: parseInt(auditId),
        },
      },
      meta: {
        offline: {
          effect: createNewObservation({
            auditId,
            observationObj,
          }),
          commit: {
            type: CREATE_OBSERVATION_COMMIT,
            meta: {
              temp_entity_id: toObservationEntityId(tempId),
              then: () => () => {
                toast.success('Observation created');
              },
            },
          },
        },
      },
    });
  };
};

export const deleteObservation = ([auditId, observationId]) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_OBSERVATION,
      payload: {
        entity_id: toObservationEntityId(observationId),
      },
      meta: {
        offline: {
          effect: deleteObservationById([auditId, observationId]),
          commit: {
            type: DELETE_OBSERVATION_COMMIT,
            meta: {
              then: () => () => {
                toast.success('Observation deleted');
              },
            },
          },
        },
      },
    });
  };
};
