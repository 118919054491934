import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Link, useParams, Prompt } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tags from '../../shared/tags/Tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QuestionSection1 from './QuestionSection1';
import QuestionSection2 from './QuestionSection2';
import QuestionSection3 from './QuestionSection3';
import LinkQuestions from '../../shared/linking/LinkQuestions';
import LinkDocuments from '../../shared/linking/LinkDocuments';
import LinkPhotos from '../../shared/linking/LinkPhotos';
import EntitySyncedModal from '../../shared/modals/EntitySyncedModal';
import { useSelector, useDispatch } from 'react-redux';
import { toObservationEntityId } from '../../../utils/convertId';
import {
  // fetchObservationDetailsRefresh,
  updateObservation,
  createObservation,
} from '../../../store/actions/observationsActions';
import { useAppContext } from '../../../utils/auth/context';
import useAuditExported from '../../../utils/customHooks/useAuditExported';

const ObservationForm = () => {
  const { auditId, observationId } = useParams();
  const dispatch = useDispatch();
  const isAuditExported = useAuditExported();
  const { authUser } = useAppContext();
  const { observationDetails, loading /*, isOnline*/ } = useSelector(
    (state) => {
      const obs = observationId
        ? state.observations.observations.find(
            (observation) =>
              observation.entity_id === toObservationEntityId(observationId)
          )
        : null;
      return {
        observationDetails: obs,
        loading: obs ? obs.loading : false,
        // isOnline: state.offline.online,
      };
    }
  );

  const [buttonLoading, setButtonLoading] = useState(false);
  const [questionSaved, setQuestionSaved] = useState(false);
  const [questionChanged, setQuestionChanged] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [pageType, setPageType] = useState('');
  const [error, setError] = useState(null);
  const [questionTags, setQuestionTags] = useState([]);
  const [linkedQuestions, setLinkedQuestions] = useState([]);
  const [linkedDocuments, setLinkedDocuments] = useState([]);
  const [linkedPhotos, setLinkedPhotos] = useState([]);
  const [baseState, setBaseState] = useState({
    aspect_category: '',
    area: '',
    in_report: false,
    title: '',
    observation_type: '',
    description: '',
  });
  const [potentialResult, setPotentialResult] = useState('');
  const [section1State, setSection1State] = useState({});
  const [section2State, setSection2State] = useState({});
  const [section3State, setSection3State] = useState({});
  const titleMaxLength = 350;
  const pageTypeRef = useRef('');
  const [syncModalShow, setSyncModalShow] = useState(false);

  useEffect(() => {
    // Display modal when currently viewed entity is synchronised
    // and its temporary id is replaced with a backend-generated one
    if (
      pageTypeRef.current === 'edit' &&
      pageType === 'new' &&
      syncModalShow === false
    ) {
      setSyncModalShow(true);
    }
    // eslint-disable-next-line
  }, [pageType]);

  useEffect(() => {
    if (observationDetails) {
      // Case - observation details page
      setPageType('edit');
      pageTypeRef.current = 'edit';
      const baseData = (({
        aspect_category,
        area,
        in_report,
        observation_type,
        description,
        title,
      }) => ({
        aspect_category,
        area,
        in_report,
        observation_type,
        description: decodeURI(description),
        title,
      }))(observationDetails);
      setBaseState(baseData);
      setQuestionTags(observationDetails.tags || []);
      setLinkedQuestions(observationDetails.link_questions || []);
      setLinkedDocuments(observationDetails.link_documents || []);
      setLinkedPhotos(observationDetails.link_photos || []);
      if (observationDetails.observation_type === 'Critical') {
        setPotentialResult(observationDetails.potential_result);
        const { section1, section2, section3 } = observationDetails;

        const section1Answers = Object.keys(section1).reduce((acc, curr) => {
          if (curr === 's1q3v1Other' || curr === 's1q3v2Other') {
            acc[curr] = decodeURI(section1[curr].answer);
          } else {
            acc[curr] = section1[curr].answer;
          }
          return acc;
        }, {});

        const section2Answers = Object.keys(section2).reduce((acc, curr) => {
          if (curr === 's2q4v1Other' || curr === 's2q4v2Other') {
            acc[curr] = decodeURI(section2[curr].answer);
          } else {
            acc[curr] = section2[curr].answer;
          }
          return acc;
        }, {});

        const section3Answers = Object.keys(section3).reduce((acc, curr) => {
          if (curr === 's3q3Other') {
            acc[curr] = decodeURI(section3[curr].answer);
          } else {
            acc[curr] = section3[curr].answer;
          }
          return acc;
        }, {});

        setSection1State(section1Answers);
        setSection2State(section2Answers);
        setSection3State(section3Answers);
      }
    } else {
      // Case - new observation page
      setPageType('new');
    }
    // eslint-disable-next-line
  }, [observationDetails]);

  const section1Questions = {
    s1q1: 'Is there a Risk Assessment / Work Instruction available for this task?',
    s1q2: 'Are the risks related to this task adequately identified?',
    s1q3v1: 'What was the root cause of failure?',
    s1q3v1Other: 'Please enter the root cause:',
    s1q3v2c1:
      'Risk level has not been properly assessed (Severity, Probability, Frequency)',
    s1q3v2c2:
      'Hazards related to this task were missing (e.g. mechanical risks were not included however electricity was)',
    s1q3v2c3:
      'Risk assessment does not reflect the actual conditions (e.g. changes in the machine/layout)',
    s1q3v2c4: 'Project/Site manager was not leading the risk assessment',
    s1q3v2c5:
      'Employee (one of the employees from the area) was not part of the risk assessment team',
    s1q3v2c6: 'Defined control measures were not addressing the hazard',
    s1q3v2cOther: 'Other',
    s1q3v2Other: 'Please enter the reason:',
  };

  const section2Questions = {
    s2q1: 'Is there a work permit requirement for this task?',
    s2q2: 'Is there a work permit available for this task?',
    s2q3: 'Is the work permit properly prepared?',
    s2q4v1: 'What was the root cause of failure?',
    s2q4v1Other: 'Please enter the root cause:',
    s2q4v2c1: 'The task was not clearly defined',
    s2q4v2c2: 'Identified hazards were not fully convering the actual task',
    s2q4v2c3: 'Defined control measures were not clear',
    s2q4v2c4: 'Defined control measures were not convering the actual hazards',
    s2q4v2c5: 'Work permit was conducted by incompetent people',
    s2q4v2cOther: 'Other',
    s2q4v2Other: 'Please enter the reason:',
  };

  const section3Questions = {
    s3q1: 'Is there a requirement for employee qualification/certification for this task?',
    s3q2: 'Does the employee have the required qualification/certification for this task?',
    s3q3: 'Why?',
    s3q3Other: 'Please enter the reason:',
  };

  const handleAddTag = (newTag) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    setQuestionTags((questionTags) => [...questionTags, newTag]);
  };

  const handleRemoveTag = (tagName) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    setQuestionTags(questionTags.filter((tag) => tag !== tagName));
  };

  const handleAddLinkedQuestion = (linkedQuestion) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    setLinkedQuestions((linkedQuestions) => [
      ...linkedQuestions,
      linkedQuestion,
    ]);
  };

  const handleRemoveLinkedQuestion = (linkedQuestion) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    setLinkedQuestions(
      linkedQuestions.filter((question) => question !== linkedQuestion)
    );
  };

  const handleAddLinkedDocument = (linkedDocument) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    setLinkedDocuments((linkedDocuments) => [
      ...linkedDocuments,
      linkedDocument,
    ]);
  };

  const handleRemoveLinkedDocument = (linkedDocument) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    setLinkedDocuments(
      linkedDocuments.filter((question) => question !== linkedDocument)
    );
  };

  const handleAddLinkedPhoto = (linkedPhotosArray) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    setLinkedPhotos([...new Set([...linkedPhotos, ...linkedPhotosArray])]);
  };

  const handleRemoveLinkedPhoto = (linkedPhoto) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    setLinkedPhotos(linkedPhotos.filter((photo) => photo !== linkedPhoto));
  };

  const handleBaseChange = (e) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let newBaseState = {
      ...baseState,
      [e.target.name]: value,
    };

    if (e.target.name === 'observation_type' && value !== 'Critical') {
      setSection1State({});
      setSection2State({});
      setSection3State({});
      setPotentialResult('');
    }
    if (
      e.target.name === 'aspect_category' &&
      baseState.observation_type === 'Minor'
    ) {
      newBaseState.title = value;
    }
    if (e.target.name === 'observation_type' && value === 'Minor') {
      newBaseState.title = baseState.aspect_category;
    }
    if (e.target.name === 'observation_type' && value !== 'Minor') {
      newBaseState.title = '';
    }
    setBaseState(newBaseState);
  };

  const handleResultChange = (e) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    setPotentialResult(e.target.value);
  };

  const handleSection1Change = (name, value) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    if (name === 's1q1' && value !== 'yes') {
      // 1st question - set to 'No' or '-'
      // Add only 's1q1' property
      setSection1State({
        [name]: value,
      });
    } else if (name === 's1q2' && value !== 'yes') {
      // 2nd question - set to 'No' or '-'
      // Add only 's1q1' and 's1q2' property
      setSection1State({
        s1q1: section1State.s1q1,
        [name]: value,
      });
    } else if (name === 's1q2' && value === 'yes') {
      // 2nd question - set to 'Yes'
      // Copy the 'state' object
      const newState = Object.assign({}, section1State);
      // Delete all properties from 's1q3v2' case
      Object.keys(section1State).forEach((item) => {
        if (item.indexOf('s1q3v2') === 0) {
          delete newState[item];
        }
      });

      setSection1State({
        ...newState,
        [name]: value,
      });
    } else if (name === 's1q3v1' && value !== 'Other') {
      // 3rd dropdown question - answer different than 'Other'
      const newState = Object.assign({}, section1State);
      delete newState.s1q3v1Other;
      setSection1State({
        ...newState,
        [name]: value,
      });
    } else if (name === 's1q3v2cOther' && value === false) {
      // 3rd question checkbox 'Other' - set to false
      const newState = Object.assign({}, section1State);
      delete newState.s1q3v2Other;
      setSection1State({
        ...newState,
        [name]: value,
      });
    } else {
      setSection1State({
        ...section1State,
        [name]: value,
      });
    }
  };

  const handleSection2Change = (name, value) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    if (name === 's2q1' && value !== 'yes') {
      // 1st question - set to 'No' or '-'
      // Add only 's2q1' property
      setSection2State({
        [name]: value,
      });
    } else if (name === 's2q2' && value !== 'yes') {
      // 2nd question - set to 'No' or '-'
      // Add only 's2q1' and 's2q2' property
      setSection2State({
        s2q1: section2State.s2q1,
        [name]: value,
      });
    } else if (name === 's2q3' && value !== 'yes') {
      // 3rd question - set to 'No' or '-'
      // Add only 's1q1', 's1q2' and 's2q3' property
      setSection2State({
        s2q1: section2State.s2q1,
        s2q2: section2State.s2q2,
        [name]: value,
      });
    } else if (name === 's2q3' && value === 'yes') {
      // 3rd question - set to 'Yes'
      // Copy the 'state' object
      const newState = Object.assign({}, section2State);
      // Delete all properties from 's2q4v2' case
      Object.keys(section2State).forEach((item) => {
        if (item.indexOf('s2q4v2') === 0) {
          delete newState[item];
        }
      });

      setSection2State({
        ...newState,
        [name]: value,
      });
    } else if (name === 's2q4v1' && value !== 'Other') {
      // 4th dropdown question - answer different than 'Other'
      const newState = Object.assign({}, section2State);
      delete newState.s2q4v1Other;
      setSection2State({
        ...newState,
        [name]: value,
      });
    } else if (name === 's2q4v2cOther' && value === false) {
      // 4th question checkbox 'Other' - set to false
      const newState = Object.assign({}, section2State);
      delete newState.s2q4v2Other;
      setSection2State({
        ...newState,
        [name]: value,
      });
    } else {
      setSection2State({
        ...section2State,
        [name]: value,
      });
    }
  };

  const handleSection3Change = (name, value) => {
    setQuestionSaved(false);
    setQuestionChanged(true);
    if (name === 's3q1' && value !== 'yes') {
      // 1st question - set to 'No' or '-'
      // Add only 's3q1' property
      setSection3State({
        [name]: value,
      });
    } else if (name === 's3q2' && value !== 'no') {
      // 2nd question - set to 'Yes'
      // Add only 's3q1' and 's3q2' property
      setSection3State({
        s3q1: section3State.s3q1,
        [name]: value,
      });
    } else if (name === 's3q3' && value !== 'Other') {
      // 3rd question - answer different than 'Other'
      const newState = Object.assign({}, section3State);
      delete newState.s3q3Other;
      setSection3State({
        ...newState,
        [name]: value,
      });
    } else {
      setSection3State({
        ...section3State,
        [name]: value,
      });
    }
  };

  const validate = (state) => {
    if (!state.aspect_category) {
      return 'OS Aspect Category field is required.';
    }
    if (!state.area.trim()) {
      return 'Area field is required.';
    }
    if (!state.observation_type) {
      return 'Observation type field is required.';
    }
    if (!state.title.trim()) {
      return 'Observation title field is required.';
    }
    if (!state.description.trim()) {
      return 'Description field is required.';
    }
    return null;
  };

  const handleCloseModal = () => setModalShow(false);
  const handleShowModal = () => setModalShow(true);

  const handleSubmitObservation = () => {
    const errorMsg = validate(baseState);
    if (errorMsg) {
      setError(errorMsg);
      return;
    } else {
      setError(null);
      let observationObj = {
        ...baseState,
        desc_in_report: true, // Removed field from UI, but left in request body
        description: encodeURI(baseState.description),
        auditor: authUser ? authUser.username : 'John Doe',
        tags: questionTags,
        link_photos: linkedPhotos,
        link_questions: linkedQuestions,
        link_documents: linkedDocuments,
        potential_result: potentialResult,
      };
      if (baseState.observation_type === 'Critical') {
        const section1Obj = Object.keys(section1State).reduce((acc, curr) => {
          if (section1State[curr] !== false) {
            if (curr === 's1q3v1Other' || curr === 's1q3v2Other') {
              acc[curr] = {
                question: section1Questions[curr],
                answer: encodeURI(section1State[curr]),
              };
            } else {
              acc[curr] = {
                question: section1Questions[curr],
                answer: section1State[curr],
              };
            }
          }
          return acc;
        }, {});
        const section2Obj = Object.keys(section2State).reduce((acc, curr) => {
          if (section2State[curr] !== false) {
            if (curr === 's2q4v1Other' || curr === 's2q4v2Other') {
              acc[curr] = {
                question: section2Questions[curr],
                answer: encodeURI(section2State[curr]),
              };
            } else {
              acc[curr] = {
                question: section2Questions[curr],
                answer: section2State[curr],
              };
            }
          }
          return acc;
        }, {});
        const section3Obj = Object.keys(section3State).reduce((acc, curr) => {
          if (curr === 's3q3Other') {
            acc[curr] = {
              question: section3Questions[curr],
              answer: encodeURI(section3State[curr]),
            };
          } else {
            acc[curr] = {
              question: section3Questions[curr],
              answer: section3State[curr],
            };
          }
          return acc;
        }, {});

        observationObj.section1 = section1Obj;
        observationObj.section2 = section2Obj;
        observationObj.section3 = section3Obj;
      } else {
        observationObj.section1 = {};
        observationObj.section2 = {};
        observationObj.section3 = {};
      }

      if (pageType === 'new') {
        dispatch(createObservation({ auditId, observationObj }));
        handleShowModal();
        clearForm();
        // setButtonLoading(true);
        // axiosInst(createNewObservation({ auditId, observationObj }))
        //   .then((response) => {
        //     handleShowModal();
        //     clearForm();
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   })
        //   .finally(() => {
        //     setButtonLoading(false);
        //   });
      } else if (pageType === 'edit') {
        dispatch(updateObservation({ auditId, observationId, observationObj }));
        setQuestionChanged(false);
        setQuestionSaved(true);
        // axiosInst(updateObservation({ auditId, observationId, observationObj }))
        //   .then((response) => {
        //     setQuestionChanged(false);
        //     setQuestionSaved(true);
        //     setToastShow(true);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   })
        //   .finally(() => {
        //     setButtonLoading(false);
        //   });
      }
    }
  };

  const clearForm = () => {
    setBaseState({
      aspect_category: '',
      area: '',
      in_report: false,
      title: '',
      observation_type: '',
      description: '',
    });
    setSection1State({});
    setSection2State({});
    setSection3State({});
    setPotentialResult('');
    setQuestionTags([]);
    setLinkedQuestions([]);
    setLinkedDocuments([]);
    setLinkedPhotos([]);
  };

  return (
    <Fragment>
      {pageType === 'edit' && (
        <Prompt
          when={questionChanged}
          message='You have unsaved changes. Are you sure you want to leave?'
        />
      )}
      {loading && (
        <div className='text-center mb-3'>
          <FontAwesomeIcon icon='circle-notch' spin size='lg' />
        </div>
      )}
      <Form>
        <Row>
          <Col sm={3}>
            <Form.Group controlId='aspect_category'>
              <Form.Label>OS Aspect Category *</Form.Label>
              <Form.Control
                size='sm'
                as='select'
                name='aspect_category'
                value={baseState.aspect_category}
                onChange={handleBaseChange}
              >
                <option value=''>-</option>
                <option value='Electrical Safety'>Electrical Safety</option>
                <option value='Energy Isolation'>Energy Isolation</option>
                <option value='Hazardous Substances'>
                  Hazardous Substances
                </option>
                <option value='Mechanical Safety'>Mechanical Safety</option>
                <option value='Excavation Works'>Excavation Works</option>
                <option value='Confined Spaces'>Confined Spaces</option>
                <option value='Fire Safety and Emergency Management'>
                  Fire Safety and Emergency Management
                </option>
                <option value='Working @ Height'>Working @ Height</option>
                <option value='Lifting Procedures'>Lifting Procedures</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId='area'>
              <Form.Label>Area *</Form.Label>
              <Form.Control
                type='text'
                size='sm'
                name='area'
                value={baseState.area}
                onChange={handleBaseChange}
              />
            </Form.Group>
            <Form.Group controlId='addToReport'>
              <Form.Check
                type='checkbox'
                label='Add observation to report'
                name='in_report'
                checked={baseState.in_report}
                onChange={handleBaseChange}
              />
            </Form.Group>
            <Form.Group controlId='observation_type'>
              <Form.Label>Observation Type *</Form.Label>
              <Form.Control
                size='sm'
                as='select'
                name='observation_type'
                value={baseState.observation_type}
                onChange={handleBaseChange}
              >
                <option value=''>-</option>
                <option value='Minor'>Minor</option>
                <option value='Critical'>Critical</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group controlId='title'>
              <Form.Label>
                Observation title *
                {baseState.in_report ? ' (used as header in report)' : ''}
              </Form.Label>
              <Form.Control
                type='text'
                size='sm'
                name='title'
                readOnly={baseState.observation_type === 'Minor'}
                maxLength={titleMaxLength}
                value={baseState.title}
                onChange={handleBaseChange}
              />
              <div className='d-flex justify-content-between'>
                <small className='text-muted'>
                  Minor observations use only OS Aspect Category as header in
                  report.
                  <br />
                  Critical observations use OS Aspect Category and Title as
                  header in report.
                </small>
                <small className='text-muted'>
                  {baseState.title.length}/{titleMaxLength}
                </small>
              </div>
            </Form.Group>
            <Form.Group controlId='description'>
              <Form.Label>Description *</Form.Label>
              <Form.Control
                as='textarea'
                rows='5'
                name='description'
                value={baseState.description}
                onChange={handleBaseChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <div className='pt-4'>
              <LinkPhotos
                linked={linkedPhotos}
                onAddLinkedPhoto={handleAddLinkedPhoto}
                onRemoveLinkedPhoto={handleRemoveLinkedPhoto}
              />
              <LinkQuestions
                linked={linkedQuestions}
                onAddLinkedQuestion={handleAddLinkedQuestion}
                onRemoveLinkedQuestion={handleRemoveLinkedQuestion}
              />
              <LinkDocuments
                linked={linkedDocuments}
                onAddLinkedDocument={handleAddLinkedDocument}
                onRemoveLinkedDocument={handleRemoveLinkedDocument}
              />
              <Tags
                tags={questionTags}
                onAddTag={handleAddTag}
                onRemoveTag={handleRemoveTag}
              />
            </div>
          </Col>
        </Row>
        {baseState.observation_type === 'Critical' && (
          <div>
            <Row>
              <Col sm={3}>
                <Form.Group controlId='potentialResult'>
                  <Form.Label>Potential result</Form.Label>
                  <Form.Control
                    size='sm'
                    as='select'
                    name='potentialResult'
                    value={potentialResult}
                    onChange={handleResultChange}
                  >
                    <option value=''>-</option>
                    <option value='Significant Incident'>
                      Significant Incident
                    </option>
                    <option value='Serious Impact on Health'>
                      Serious Impact on Health
                    </option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <h4 className='mt-3'>Please answer questions below:</h4>
            <Row>
              <Col sm={4} className='border-right'>
                <QuestionSection1
                  state={section1State}
                  questions={section1Questions}
                  onChange={handleSection1Change}
                />
              </Col>
              <Col sm={4} className='border-right'>
                <QuestionSection2
                  state={section2State}
                  questions={section2Questions}
                  onChange={handleSection2Change}
                />
              </Col>
              <Col sm={4}>
                <QuestionSection3
                  state={section3State}
                  questions={section3Questions}
                  onChange={handleSection3Change}
                />
              </Col>
            </Row>
          </div>
        )}
      </Form>
      <Row>
        <Col>
          <p className='text-danger text-right'>{error}</p>
          <div className='text-right'>
            <Button
              variant={
                pageType === 'edit' && questionChanged
                  ? 'warning'
                  : pageType === 'edit' && questionSaved
                  ? 'success'
                  : 'primary'
              }
              onClick={handleSubmitObservation}
              disabled={isAuditExported}
            >
              {buttonLoading && (
                <FontAwesomeIcon icon='circle-notch' spin className='mr-2' />
              )}
              {pageType === 'new' ? 'Create observation' : 'Save changes'}
            </Button>
            <Link
              to={
                pageType === 'new'
                  ? `/audit/${auditId}/observations`
                  : `/audit/${auditId}/observations/select-observation`
              }
              className='ml-2'
            >
              <Button variant='secondary'>
                {pageType === 'new' ? 'Cancel' : 'Back to Observations'}
              </Button>
            </Link>
          </div>
        </Col>
      </Row>

      <Modal
        show={modalShow}
        onHide={handleCloseModal}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Observation created</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          New observation was successfully created. Proceed to the list of{' '}
          <strong>Observations</strong> or close the window to create another
          observation.
        </Modal.Body>
        <Modal.Footer>
          <Link to={`/audit/${auditId}/observations/select-observation`}>
            <Button variant='primary'>Observations</Button>
          </Link>
        </Modal.Footer>
      </Modal>

      <EntitySyncedModal
        show={syncModalShow}
        onHide={() => setSyncModalShow(false)}
        entityType='Observation'
        entityListLink={`/audit/${auditId}/observations/select-observation`}
      />
    </Fragment>
  );
};

export default ObservationForm;
