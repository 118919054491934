import { EXPORT_AUDIT } from '../types/auditsTypes';
import * as localForage from 'localforage';
import { keyToPhotoId } from '../../utils/photos';

export const exportAuditMiddleware = ({ getState, dispatch }) => {
  return function (next) {
    return async function (action) {
      if (action.type === EXPORT_AUDIT) {
        const auditId = action.payload;
        const auditPhotosIds = getState()
          .photos.photos.filter((photo) => photo.audit_id === auditId)
          .map((photo) => photo.photo_id);
        const localForageKeys = await localForage.keys();
        const keysToBeRemoved = localForageKeys.filter((key) => {
          return auditPhotosIds.indexOf(keyToPhotoId(key)) > -1;
        });
        keysToBeRemoved.forEach((key) => {
          localForage.removeItem(key);
        });
      }
      return next(action);
    };
  };
};
