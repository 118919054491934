import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_COMMIT,
  FETCH_QUESTIONS,
  FETCH_QUESTIONS_COMMIT,
} from '../types/questionsTypes';
import {
  fetchInterviewQuestionCategories,
  fetchInterviewQuestions,
} from '../../http/requests';

export const fetchCategories = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_CATEGORIES,
      meta: {
        offline: {
          effect: fetchInterviewQuestionCategories(),
          commit: {
            type: FETCH_CATEGORIES_COMMIT,
          },
        },
      },
    });
  };
};

export const fetchQuestions = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_QUESTIONS,
      meta: {
        offline: {
          effect: fetchInterviewQuestions(),
          commit: {
            type: FETCH_QUESTIONS_COMMIT,
          },
        },
      },
    });
  };
};
