import React, { useEffect, useState, Fragment } from 'react';
import { useParams, Link, Prompt, useLocation } from 'react-router-dom';
import InterviewQuestionItem from './InterviewQuestionItem';
import EntitySyncedModal from '../../shared/modals/EntitySyncedModal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector /*, useDispatch*/ } from 'react-redux';
import { toInterviewEntityId } from '../../../utils/convertId';
// import { fetchInterviewDetailsRefresh } from '../../../store/actions/interviewsActions';

import './InterviewQuestions.scss';

const InterviewQuestions = () => {
  const { auditId, interviewId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const dispatch = useDispatch();
  const { interviewQuestions, interviewMeta, loading /*, isOnline*/ } =
    useSelector((state) => {
      const currentInterview = state.interviews.interviews.find(
        (interview) => interview.entity_id === toInterviewEntityId(interviewId)
      );
      let currentInterviewQuestions = currentInterview?.questions || [];
      // If navigated from Overview tab KPI with a query parameter,
      // prefilter table for significant/minor management system observations
      if (queryParams.size > 0) {
        currentInterviewQuestions = currentInterviewQuestions.filter(
          (question) =>
            question.is_significant === queryParams.get('mso') &&
            question.in_report === true
        );
      }
      return {
        interviewQuestions: currentInterviewQuestions,
        interviewMeta: {
          auditor: currentInterview?.auditor || '',
          auditee: currentInterview?.auditee || '',
          category_letter: currentInterview?.questions_category_letter || '',
          question_category: currentInterview?.question_category || '',
        },
        loading: currentInterview?.loading || false,
        // isOnline: state.offline.online,
      };
    });
  const [unsavedQuestions, setUnsavedQuestions] = useState(new Set());
  const [syncModalShow, setSyncModalShow] = useState(false);

  useEffect(() => {
    // Display modal when currently viewed entity is synchronised
    // and its temporary id is replaced with a backend-generated one
    if (interviewQuestions.length === 0 && syncModalShow === false) {
      setSyncModalShow(true);
    }
    // eslint-disable-next-line
  }, [interviewQuestions]);

  // const changeQuestionInReport = (question_id) => {
  //   const updatedQuestions = interviewQuestions.map((question) => {
  //     if (question_id === question.question_id) {
  //       return { ...question, in_report: !question.in_report };
  //     }
  //     return question;
  //   });
  //   setInterviewQuestions(updatedQuestions);
  // };

  // const changeField = ({ question_id, field, value }) => {
  //   const updatedQuestions = interviewQuestions.map((question) => {
  //     if (question_id === question.question_id) {
  //       return { ...question, [field]: value };
  //     }
  //     return question;
  //   });
  //   setInterviewQuestions(updatedQuestions);
  // };

  // const handleUpdateQuestion = (questionObj) => {
  //   const updatedQuestions = interviewQuestions.map((question) => {
  //     if (questionObj.questionId === question.question_id) {
  //       return {
  //         ...question,
  //         in_report: questionObj.inReport,
  //         is_significant: questionObj.isSignificant,
  //         question_answer: questionObj.questionAnswer,
  //         question_comment: questionObj.questionComment,
  //       };
  //     }
  //     return question;
  //   });
  //   // setInterviewQuestions(updatedQuestions);
  //   setToastShow(true);
  // };

  const handleQuestionModified = ({ question_id, questionChanged }) => {
    const newSet = new Set(unsavedQuestions);
    if (questionChanged) {
      newSet.add(question_id);
    } else {
      newSet.delete(question_id);
    }
    setUnsavedQuestions(newSet);
  };

  // const handleSaveQuestion = (question_id) => {
  //   const selectedQuestion = interviewQuestions
  //     .filter((question) => question.question_id === question_id)
  //     .map((question) => {
  //       return {
  //         questionId: question.question_id,
  //         questionAnswer: question.question_answer || '',
  //         isSignificant: question.is_significant || '',
  //         inReport: question.in_report,
  //         questionComment: question.question_comment || '',
  //       };
  //     });

  //   axiosInst(
  //     updateInterviewQuestionAnswers({ interviewMeta, selectedQuestion })
  //   )
  //     .then((response) => {
  //       console.log(response);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     })
  //     .finally(() => {
  //       console.log('finally - updateInterviewQuestionAnswers');
  //     });
  // };

  return (
    <Fragment>
      <Prompt
        when={unsavedQuestions.size > 0}
        message='You have unsaved changes. Are you sure you want to leave?'
      />
      {queryParams.size > 0 && (
        <Alert variant='info'>
          You are now viewing only <strong>{queryParams.get('mso')}</strong>{' '}
          management system observations added to report. To see all results,
          open again 'Review existing interviews' page from Interviews tab.
        </Alert>
      )}
      <Row>
        <Col xs={10}>
          {loading && (
            <div className='text-center mb-3'>
              <FontAwesomeIcon icon='circle-notch' spin size='lg' />
            </div>
          )}
          <div className='interview-wrapper border'>
            {interviewQuestions.length > 0 &&
              interviewQuestions.map((question) => (
                <InterviewQuestionItem
                  question={question}
                  // changeField={changeField}
                  // changeQuestionInReport={changeQuestionInReport}
                  // handleSaveQuestion={handleSaveQuestion}
                  // handleUpdateQuestion={handleUpdateQuestion}
                  handleQuestionModified={handleQuestionModified}
                  key={question.question_id}
                />
              ))}
          </div>
        </Col>
        <Col xs={2}>
          <div className='d-flex flex-column justify-content-between h-100'>
            <Form>
              {loading && (
                <div className='text-center mb-3'>
                  <FontAwesomeIcon icon='circle-notch' spin size='lg' />
                </div>
              )}
              {interviewMeta && (
                <Fragment>
                  <Form.Group controlId='category'>
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      readOnly
                      type='text'
                      value={`${interviewMeta.category_letter} - ${interviewMeta.question_category}`}
                      size='sm'
                    />
                  </Form.Group>
                  <Form.Group controlId='interviewer'>
                    <Form.Label>Interviewer</Form.Label>
                    <Form.Control
                      readOnly
                      type='text'
                      value={interviewMeta.auditor}
                      size='sm'
                    />
                  </Form.Group>
                  <Form.Group controlId='interviewPartner'>
                    <Form.Label>Interview partner</Form.Label>
                    <Form.Control
                      readOnly
                      type='text'
                      value={interviewMeta.auditee}
                      size='sm'
                    />
                  </Form.Group>
                </Fragment>
              )}
            </Form>
            <div>
              <Link
                to={`/audit/${auditId}/interviews/select-interview${
                  queryParams.size > 0 ? `?${queryParams.toString()}` : ''
                }`}
              >
                <Button variant='secondary' block>
                  Back to Interviews
                </Button>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <EntitySyncedModal
        show={syncModalShow}
        onHide={() => setSyncModalShow(false)}
        entityType='Interview'
        entityListLink={`/audit/${auditId}/interviews/select-interview`}
      />
    </Fragment>
  );
};

export default InterviewQuestions;
