import {
  SET_LOADING,
  FETCH_PHOTOS_INIT,
  FETCH_PHOTOS_REFRESH_COMMIT,
  FETCH_PHOTOS_REFRESH_ROLLBACK,
  UPDATE_PHOTO_META,
  UPDATE_PHOTO_META_COMMIT,
  GET_PRESIGNED_URL,
  GET_PRESIGNED_URL_COMMIT,
  ADD_PHOTO,
  DELETE_PHOTO,
  DELETE_PHOTO_COMMIT,
  DELETE_PHOTOS_ARRAY,
  DELETE_PHOTOS_ARRAY_COMMIT,
} from '../types/photosTypes';
import axiosInst from '../../http/axiosConfig';
import {
  fetchImagesList,
  updateImageMeta,
  getPresignedUploadUrl,
  deleteImageById,
  deleteImagesList,
} from '../../http/requests';
import { storePhotoFile } from '../../utils/photos';
import { toast } from 'react-toastify';

export const fetchPhotosInit = (auditId) => {
  return (dispatch) => {
    axiosInst(fetchImagesList(auditId))
      .then((response) => {
        const auditPhotos = response.data;
        dispatch({
          type: FETCH_PHOTOS_INIT,
          payload: auditPhotos,
        });
        auditPhotos.forEach((photo) => {
          storePhotoFile(photo);
        });
      })
      .catch((error) => {
        //
      });
  };
};

export const fetchPhotosRefresh = (auditId) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      payload: true,
      meta: {
        offline: {
          effect: fetchImagesList(auditId),
          commit: {
            type: FETCH_PHOTOS_REFRESH_COMMIT,
            meta: {
              audit_id: parseInt(auditId),
            },
          },
          rollback: {
            type: FETCH_PHOTOS_REFRESH_ROLLBACK,
          },
        },
      },
    });
  };
};

export const updatePhotoMeta = ({ auditId, photoId, imageData }) => {
  const { photo_url, ...imageDataWithoutUrl } = imageData;
  return (dispatch) => {
    dispatch({
      type: UPDATE_PHOTO_META,
      payload: {
        photo_id: photoId,
        imageData,
      },
      meta: {
        offline: {
          effect: updateImageMeta({
            auditId,
            photoId,
            imageData: imageDataWithoutUrl,
          }),
          commit: {
            type: UPDATE_PHOTO_META_COMMIT,
            meta: {
              photo_id: photoId,
              photo_url,
              then: () => () => {
                toast.success('Photo updated');
              },
            },
          },
        },
      },
    });
  };
};

export const getPresignedUrl = ({ auditId, timestamp, category, photoObj }) => {
  return (dispatch) => {
    dispatch({
      type: GET_PRESIGNED_URL,
      payload: {
        photo_id: photoObj.photo_id,
      },
      meta: {
        offline: {
          effect: getPresignedUploadUrl({ auditId, timestamp, category }),
          commit: {
            type: GET_PRESIGNED_URL_COMMIT,
            meta: {
              photoObj,
            },
          },
        },
      },
    });
  };
};

export const addPhoto = (photoObj) => {
  // Unified payloads for UPDATE_PHOTO_META and ADD_PHOTO actions to use them in crossSliceReducer
  const { photo_id, ...imageData } = photoObj;
  return {
    type: ADD_PHOTO,
    payload: {
      photo_id,
      imageData,
    },
  };
};

export const deletePhoto = ([auditId, photoId]) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_PHOTO,
      payload: {
        photo_id: photoId,
      },
      meta: {
        offline: {
          effect: deleteImageById([auditId, photoId]),
          commit: {
            type: DELETE_PHOTO_COMMIT,
            meta: {
              then: () => () => {
                toast.success('Photo deleted');
              },
            },
          },
        },
      },
    });
  };
};

export const deletePhotosArray = ([auditId, photoIdsList]) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_PHOTOS_ARRAY,
      payload: {
        photoIdsList,
      },
      meta: {
        offline: {
          effect: deleteImagesList([auditId, photoIdsList]),
          commit: {
            type: DELETE_PHOTOS_ARRAY_COMMIT,
            meta: {
              then: () => () => {
                toast.success('Photo(s) deleted');
              },
            },
          },
        },
      },
    });
  };
};
