import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import './NetworkStatus.scss';

function NetworkStatus() {
  const isOnline = useSelector((state) => state.offline.online);

  return (
    <Fragment>
      Network status:{' '}
      <span
        className={`network-status-light ${
          isOnline
            ? 'network-status-light--online'
            : 'network-status-light--offline'
        }`}
      ></span>
    </Fragment>
  );
}

export default NetworkStatus;
