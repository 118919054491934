import React, { Fragment } from 'react';
import PageName from '../layout/PageName';

function Imprint() {
  return (
    <Fragment>
      <PageName name='Imprint' />
      <h3 className='mb-4'>Corporate Information</h3>
      <p className='mb-5'>
        <strong>Corporate Headquarters</strong>
        <br />
        <br />
        Siemens Aktiengesellschaft
        <br />
        Werner-von-Siemens-Straße 1<br />
        80333 Munich
        <br />
        Germany
        <br />
        <br />
        <a href='mailto:contact@siemens.com'>contact@siemens.com</a>
        <br />
        Tel. +49 89 636-00
        <br />
        Fax +49 69 797 6664
        <br />
        <br />
        <strong>Chairman of the Supervisory Board</strong>
        <br />
        Jim Hagemann Snabe
        <br />
        <br />
        <strong>Managing Board</strong>
        <br />
        Roland Busch (President and CEO)
        <br />
        Cedrik Neike
        <br />
        Matthias Rebellius
        <br />
        Ralf P. Thomas
        <br />
        Judith Wiese
        <br />
        <br />
        <strong>Registered offices</strong>
        <br />
        Berlin and Munich, Germany
        <br />
        <br />
        <strong>Commercial registries</strong>
        <br />
        Berlin-Charlottenburg, HRB 12300
        <br />
        Munich, HRB 6684
        <br />
        <br />
        <strong>VAT registration number</strong>
        <br />
        DE129274202
        <br />
        <br />
        <strong>
          Responsible according to Paragraph 18,&nbsp;
          <br />
          Section 2 of the German MstV (Medienstaatsvertrag)
        </strong>
        <br />
        Lynette Jackson
        <br />
        Siemens Aktiengesellschaft
        <br />
        Communications
        <br />
        Werner-von-Siemens-Straße 1<br />
        80333 Munich
        <br />
        Germany
      </p>
    </Fragment>
  );
}

export default Imprint;
