import {
  SET_LOADING,
  FETCH_PHOTOS_INIT,
  FETCH_PHOTOS_REFRESH_COMMIT,
  FETCH_PHOTOS_REFRESH_ROLLBACK,
  UPDATE_PHOTO_META,
  UPDATE_PHOTO_META_COMMIT,
  ADD_PHOTO,
  DELETE_PHOTO,
  DELETE_PHOTOS_ARRAY,
} from '../types/photosTypes';
import { sortPhotos } from '../../utils/sorting';

const initialState = {
  photos: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case FETCH_PHOTOS_INIT:
      return {
        ...state,
        photos: [
          ...state.photos,
          ...action.payload.map((photo) => {
            const { thumbnail_url, upload_url, ...photoObj } = photo;
            return {
              ...photoObj,
              link_questions: photoObj.link_questions || [],
              link_documents: photoObj.link_documents || [],
              link_observations: photoObj.link_observations || [],
              tags: photoObj.tags || [],
            };
          }),
        ].sort(sortPhotos),
      };
    case FETCH_PHOTOS_REFRESH_COMMIT:
      const photosFromOtherAudits = state.photos.filter(
        (photo) => photo.audit_id !== action.meta.audit_id
      );
      const updatedPhotos = action.payload.data.map((photo) => {
        const { thumbnail_url, upload_url, ...photoObj } = photo;
        const existingPhoto = state.photos.find(
          (obs) => obs.entity_id === photo.entity_id
        );
        if (existingPhoto) {
          return {
            ...existingPhoto,
            ...photoObj,
            link_questions: photoObj.link_questions || [],
            link_documents: photoObj.link_documents || [],
            link_observations: photoObj.link_observations || [],
            tags: photoObj.tags || [],
          };
        } else {
          return photoObj;
        }
      });
      return {
        ...state,
        photos: [...photosFromOtherAudits, ...updatedPhotos].sort(sortPhotos),
        loading: false,
      };
    case FETCH_PHOTOS_REFRESH_ROLLBACK:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PHOTO_META:
      return {
        ...state,
        photos: state.photos.map((photo) => {
          if (photo.photo_id === action.payload.photo_id) {
            return {
              ...photo,
              ...action.payload.imageData,
            };
          } else {
            return photo;
          }
        }),
      };
    case UPDATE_PHOTO_META_COMMIT:
      return {
        ...state,
        photos: state.photos.map((photo) => {
          if (photo.photo_id === action.meta.photo_id) {
            const { thumbnail_url, upload_url, ...photoObj } =
              action.payload.data;
            return {
              ...photo,
              ...photoObj,
              link_questions: photoObj.link_questions || [],
              link_documents: photoObj.link_documents || [],
              link_observations: photoObj.link_observations || [],
              tags: photoObj.tags || [],
            };
          } else {
            return photo;
          }
        }),
      };
    case ADD_PHOTO:
      return {
        ...state,
        photos: [
          ...state.photos,
          { photo_id: action.payload.photo_id, ...action.payload.imageData },
        ],
      };
    case DELETE_PHOTO:
      return {
        ...state,
        photos: state.photos.filter(
          (photo) => photo.photo_id !== action.payload.photo_id
        ),
      };
    case DELETE_PHOTOS_ARRAY:
      return {
        ...state,
        photos: state.photos.filter((photo) => {
          return !action.payload.photoIdsList.includes(photo.photo_id);
        }),
      };
    default:
      return state;
  }
};
