import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faImage } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { readPhotosFromDB, photosCategories } from '../../../utils/photos';

const LinkPhotos = ({ linked, onAddLinkedPhoto, onRemoveLinkedPhoto }) => {
  const { auditId } = useParams();
  const history = useHistory();
  const photos = useSelector((state) =>
    state.photos.photos.filter((photo) => photo.audit_id === parseInt(auditId))
  );
  const [photoBlobs, setPhotoBlobs] = useState(null);
  const [show, setShow] = useState(false);
  const [selectedPhotos, setSelectedPhotos] = useState(new Map());
  const [category, setCategory] = useState('');
  const [displayedPhotos, setDisplayedPhotos] = useState([]);
  const didMountRef = useRef(false);

  useEffect(() => {
    async function readBlobs(photoArray) {
      const photoBlobsFromDB = await readPhotosFromDB(photoArray);
      setPhotoBlobs(photoBlobsFromDB);
      setDisplayedPhotos(photos);
    }
    readBlobs(photos);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (didMountRef.current) {
      const filteredPhotos = photos.filter((photo) =>
        category ? photo.category === category : photo
      );
      setDisplayedPhotos(filteredPhotos);
    } else {
      didMountRef.current = true;
    }
    // eslint-disable-next-line
  }, [category]);

  const handlePhotoClick = (photo) => {
    if (selectedPhotos.has(photo.photo_id)) {
      setSelectedPhotos((prev) => {
        const newState = new Map(prev);
        newState.delete(photo.photo_id);
        return newState;
      });
    } else {
      setSelectedPhotos(
        (prev) => new Map([...prev, [photo.photo_id, photo.photo_id]])
      );
    }
  };

  const clearSelection = () => {
    setSelectedPhotos(new Map());
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    clearSelection();
  };

  const handleAddLinkedPhoto = () => {
    onAddLinkedPhoto(Array.from(selectedPhotos.values()));
    clearSelection();
  };

  const handleRemoveLinkedPhoto = (linkedPhoto) => {
    onRemoveLinkedPhoto(linkedPhoto);
    clearSelection();
  };

  const handleCloseModal = () => {
    setShow(false);
    clearSelection();
  };

  const showPhotoDetails = (photoId) => {
    history.push(`/audit/${auditId}/photo-gallery/photo-edit`, [photoId]);
  };

  return (
    <Fragment>
      <div>
        <h6 className='d-inline-block'>Linked photos:</h6>
        <FontAwesomeIcon
          icon='plus-circle'
          className='pointer ml-2 align-middle'
          onClick={() => setShow(true)}
        />
        {linked.length > 0 && (
          <div className='mb-2'>
            {linked.map((item, index) => {
              const thumbnail = photos.find((el) => el.photo_id === item);
              if (thumbnail) {
                return (
                  <Fragment key={index}>
                    {index > 0 && <span className='mx-2'>|</span>}
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id={`tooltip-${index}`}>
                          Name: {item.split('#')[1]}
                          <br />
                          Creation date: {thumbnail.creation_timestamp}
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon={faImage}
                        size='lg'
                        color='#2387aa'
                        className='pointer'
                        onClick={() => showPhotoDetails(item)}
                      />
                    </OverlayTrigger>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className='pointer ml-2 text-danger'
                      onClick={() => handleRemoveLinkedPhoto(item)}
                    />
                  </Fragment>
                );
              } else return '';
            })}
          </div>
        )}
      </div>
      <Modal
        show={show}
        onHide={handleCloseModal}
        size='xl'
        aria-labelledby='photos-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title id='photos-modal'>Select photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {photos.length > 0 &&
            (!photoBlobs || Object.keys(photoBlobs).length < photos.length) && (
              <Alert variant='warning'>
                Something went wrong - some photo files are not available. To
                load them, restore network connection and reopen Photo Gallery.
              </Alert>
            )}
          <Row>
            <Col>
              <Form.Group controlId='link_category' className='w-25'>
                <Form.Label>Filter by category:</Form.Label>
                <Form.Control
                  size='sm'
                  as='select'
                  name='category_filter'
                  value={category}
                  onChange={handleCategoryChange}
                >
                  <option value=''>-</option>
                  {photosCategories &&
                    photosCategories.map((category, i) => (
                      <option value={category.value} key={i}>
                        {category.label}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              {displayedPhotos.length > 0 &&
                photoBlobs &&
                Object.keys(photoBlobs).length > 0 && (
                  <div className='border mb-3 thumbnails-wrapper'>
                    {displayedPhotos.map(
                      (photo, i) =>
                        photoBlobs[photo.photo_id] && (
                          <div
                            className={
                              'pointer p-2 thumbnail-item ' +
                              (selectedPhotos.has(photo.photo_id)
                                ? 'selected'
                                : '')
                            }
                            key={i}
                            onClick={() => handlePhotoClick(photo)}
                          >
                            <img
                              src={photoBlobs[photo.photo_id] || ''}
                              alt=''
                            />
                          </div>
                        )
                    )}
                  </div>
                )}
              {photos.length > 0 && displayedPhotos.length === 0 && (
                <p className='text-center'>No results for selected filters</p>
              )}
              {photos.length === 0 && (
                <p className='text-center'>No photos added for this audit</p>
              )}
            </Col>
          </Row>
          {displayedPhotos.length > 0 && (
            <Row>
              <Col>
                <div className='text-right'>
                  <Button
                    variant='primary'
                    disabled={selectedPhotos.size === 0}
                    onClick={handleAddLinkedPhoto}
                  >
                    Link selected element
                  </Button>
                </div>
              </Col>
            </Row>
          )}
          <Row className='mt-2'>
            <Col>
              <h6>Linked photos:</h6>
              {linked.length > 0 &&
                photoBlobs &&
                Object.keys(photoBlobs).length > 0 &&
                linked.map((item, index) => {
                  const thumbnail = photos.find((el) => el.photo_id === item);
                  if (thumbnail) {
                    return (
                      <div className='mb-2' key={index}>
                        <div className='p-2 thumbnail-item'>
                          {photoBlobs[thumbnail.photo_id] && (
                            <img
                              src={photoBlobs[thumbnail.photo_id] || ''}
                              alt=''
                            />
                          )}
                        </div>
                        <strong>Name</strong>: {item.split('#')[1]} |{' '}
                        <strong>Creation date</strong>:{' '}
                        {thumbnail.creation_timestamp}
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className='pointer ml-2 text-danger'
                          onClick={() => handleRemoveLinkedPhoto(item)}
                        />
                      </div>
                    );
                  } else return '';
                })}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

LinkPhotos.defaultProps = {
  linked: [],
};

export default LinkPhotos;
