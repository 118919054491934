// Convert e.g. '#OBSERVATION#O16069894' to 'O16069894'
export const toUrlId = (entity_id) => {
  return entity_id.split('#')[2];
};

// Convert e.g. '#OBSERVATION#O16069894' to '#O16069894'
export const toLinkId = (entity_id) => {
  return `#${entity_id.split('#')[2]}`;
};

// Convert e.g. 'O16069894' to '#OBSERVATION#O16069894'
export const toObservationEntityId = (observationId) => {
  return '#OBSERVATION#' + observationId;
};

// Convert e.g. 'I16069894' to '#INTERVIEW#I16069894'
export const toInterviewEntityId = (interviewId) => {
  return '#INTERVIEW#' + interviewId;
};

// Convert e.g. 'GP16069894' to '#GOODPRACTICE#GP16069894'
export const toGoodPracticeEntityId = (gpId) => {
  return '#GOODPRACTICE#' + gpId;
};

// Convert e.g. '#OBSERVATION#O123' or '#INTERVIEW#I123_temp' to 123 (as number)
export const toTimestamp = (entity_id) => {
  return parseInt(entity_id.split('#')[2].substring(1).split('_')[0]);
};

// Convert e.g. '#I1616097216#F1' to '#INTERVIEW#I1616097216'
export const questionIdToInterviewEntityId = (question_id) => {
  return `#INTERVIEW#${question_id.split('#')[1]}`;
};
