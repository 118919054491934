import { combineReducers } from 'redux';
import auditsReducer from './reducers/auditsReducer';
import auditsSummaryReducer from './reducers/auditsSummaryReducer';
import questionsReducer from './reducers/questionsReducer';
import observationsReducer from './reducers/observationsReducer';
import interviewsReducer from './reducers/interviewsReducer';
import photosReducer from './reducers/photosReducer';
import documentsReducer from './reducers/documentsReducer';
import goodPracticesReducer from './reducers/gpsReducer';
import requestsReducer from './reducers/requestsReducer';
import crossSliceReducer from './reducers/crossSliceReducer';
import reduceReducers from 'reduce-reducers';

const combinedReducers = combineReducers({
  audits: auditsReducer,
  auditsSummary: auditsSummaryReducer,
  observations: observationsReducer,
  interviews: interviewsReducer,
  photos: photosReducer,
  documents: documentsReducer,
  goodpractices: goodPracticesReducer,
  questions: questionsReducer,
  requestsPending: requestsReducer,
});

export default reduceReducers(crossSliceReducer, combinedReducers);
