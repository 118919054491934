// Collection of axios request objects
// Example - see: https://github.com/axios/axios#axios-api

/* Audits list requests */

export const fetchAuditsList = () => {
  return {
    method: 'get',
    url: `/audits`,
  };
};

/* END Audits list requests */

/* Interview requests */

export const createNewInterview = ({ auditId, interviewObj }) => {
  return {
    method: 'post',
    url: `/audits/${auditId}/interviews`,
    data: interviewObj,
  };
};

export const deleteInterviewById = ([auditId, interviewId]) => {
  return {
    method: 'delete',
    url: `/audits/${auditId}/interviews/${interviewId}`,
  };
};

export const updateInterviewQuestionAnswers = ({
  audit_id,
  question_id,
  interview_id,
  questionObj,
}) => {
  return {
    method: 'put',
    url: `/audits/${audit_id}/interviews/${interview_id}`,
    params: {
      questionId: question_id,
    },
    data: questionObj,
  };
};

/* END Interview requests */

/* Interview catalogue requests */

export const fetchInterviewQuestionCategories = () => {
  return {
    method: 'get',
    url: '/questions/interviews/categories',
  };
};

export const fetchInterviewQuestions = () => {
  return {
    method: 'get',
    url: '/questions/interviews',
  };
};

export const fetchInterviewsList = (auditId) => {
  return {
    method: 'get',
    url: `/audits/${auditId}/interviews`,
  };
};

export const fetchInterviewDetails = ({ auditId, interviewId }) => {
  return {
    method: 'get',
    url: `/audits/${auditId}/interviews/${interviewId}`,
  };
};

export const addInterviewQuestion = (questionObj) => {
  return {
    method: 'post',
    url: '/questions/interviews',
    data: questionObj,
  };
};

export const deleteInterviewQuestionById = (questionId) => {
  return {
    method: 'delete',
    url: `/questions/interviews/${questionId}`,
  };
};

export const updateInterviewQuestion = ({
  questionId,
  questionState: { question, tooltip, category },
}) => {
  return {
    method: 'put',
    url: '/questions/interviews',
    data: {
      questionId: questionId,
      questionCategory: category,
      questionText: question,
      tooltip: tooltip,
    },
  };
};

/* END Interview catalogue requests */

/* Observation requests */

export const fetchObservationsList = (auditId) => {
  return {
    method: 'get',
    url: `/audits/${auditId}/observations`,
  };
};

export const fetchObservationDetails = ({ auditId, observationId }) => {
  return {
    method: 'get',
    url: `/audits/${auditId}/observations/${observationId}`,
  };
};

export const createNewObservation = ({ auditId, observationObj }) => {
  return {
    method: 'post',
    url: `/audits/${auditId}/observations`,
    data: observationObj,
  };
};

export const updateObservation = ({
  auditId,
  observationId,
  observationObj,
}) => {
  return {
    method: 'put',
    url: `/audits/${auditId}/observations/${observationId}`,
    data: observationObj,
  };
};

export const deleteObservationById = ([auditId, observationId]) => {
  return {
    method: 'delete',
    url: `/audits/${auditId}/observations/${observationId}`,
  };
};

/* END Observation requests */

/* Photo requests */

export const fetchImagesList = (auditId) => {
  return {
    method: 'get',
    url: `/audits/${auditId}/photos`,
  };
};

export const updateImageMeta = ({ auditId, photoId, imageData }) => {
  // photoId contains a hash ('#') character so it must be encoded
  return {
    method: 'post',
    url: `/audits/${auditId}/photos/${encodeURIComponent(photoId)}`,
    data: imageData,
  };
};

export const deleteImageById = ([auditId, photoId]) => {
  return {
    method: 'delete',
    url: `/audits/${auditId}/photos/${encodeURIComponent(photoId)}`,
  };
};

export const deleteImagesList = ([auditId, photoIdsList]) => {
  return {
    method: 'delete',
    url: `/audits/${auditId}/photos`,
    data: {
      photos_list: photoIdsList,
    },
  };
};

export const fetchSessionImages = ({ auditId, sessionId }) => {
  return {
    method: 'get',
    url: `/audits/${auditId}/photos/session/${sessionId}`,
  };
};

export const getPresignedUploadUrl = ({ auditId, timestamp, category }) => {
  return {
    method: 'get',
    url: `/audits/${auditId}/photo_upload_url/${timestamp}/${category}`,
  };
};

/* END Photo requests */

/* Audit requests */

export const fetchAuditData = (auditId) => {
  return {
    method: 'get',
    url: `/audits/${auditId}`,
  };
};

export const fetchAuditSummary = (auditId) => {
  return {
    method: 'get',
    url: `/audits/${auditId}/summary`,
  };
};

export const updateAuditData = ({ auditId, data }) => {
  return {
    method: 'post',
    url: `/audits/${auditId}`,
    data: data,
  };
};

// Initiate audit data export
export const exportAuditData = (auditId) => {
  return {
    method: 'delete',
    url: `/audits/${auditId}`,
    data: {
      action: 'archive',
    },
  };
};

// Delete archived audit from audits list
export const deleteArchivedAudit = (auditId) => {
  return {
    method: 'delete',
    url: `/audits/${auditId}`,
    data: {
      action: 'delete',
    },
  };
};

/* END Audit requests */

/* Documents requests */

export const fetchDocumentsList = (auditId) => {
  return {
    method: 'get',
    url: `/audits/${auditId}/documents`,
  };
};

export const fetchDocumentDetails = ({
  auditId,
  documentId,
  documentAuditId,
}) => {
  return {
    method: 'get',
    url: `/audits/${auditId}/documents/${encodeURIComponent(documentId)}`,
    params: {
      docAuditId: documentAuditId,
    },
  };
};

export const createDocumentObject = ({
  auditId,
  documentAuditId,
  documentId,
  documentMetaData,
}) => {
  return {
    method: 'post',
    url: `/audits/${auditId}/documents/${encodeURIComponent(documentId)}`,
    data: documentMetaData,
    params: {
      docAuditId: documentAuditId,
    },
  };
};

export const updateDocumentObject = ({
  auditId,
  documentAuditId,
  documentId,
  documentMetaData,
}) => {
  return {
    method: 'put',
    url: `/audits/${auditId}/documents/${encodeURIComponent(documentId)}`,
    data: documentMetaData,
    params: {
      docAuditId: documentAuditId,
    },
  };
};

export const deleteDocumentById = ([auditId, documentId, documentAuditId]) => {
  return {
    method: 'delete',
    url: `/audits/${auditId}/documents/${encodeURIComponent(documentId)}`,
    params: {
      docAuditId: documentAuditId,
    },
  };
};

/* END Documents requests */

/* MPA requests */

export const fetchMPAList = (auditId) => {
  return {
    method: 'get',
    url: `/audits/${auditId}/mpa`,
  };
};

export const createNewMPA = ({ auditId, mpaObj }) => {
  return {
    method: 'post',
    url: `/audits/${auditId}/mpa`,
    data: mpaObj,
  };
};

export const deleteMPAById = ([auditId, mpaId]) => {
  return {
    method: 'delete',
    url: `/audits/${auditId}/mpa/${mpaId}`,
  };
};

export const updateMPA = ({ auditId, mpaId, mpaObj }) => {
  return {
    method: 'put',
    url: `/audits/${auditId}/mpa/${mpaId}`,
    data: mpaObj,
  };
};

/* END MPA requests */

/* Good practices requests */

export const fetchGPList = (auditId) => {
  return {
    method: 'get',
    url: `/audits/${auditId}/goodpractice`,
  };
};

export const createNewGP = ({ auditId, gpObj }) => {
  return {
    method: 'post',
    url: `/audits/${auditId}/goodpractice`,
    data: gpObj,
  };
};

export const deleteGPById = ([auditId, gpId]) => {
  return {
    method: 'delete',
    url: `/audits/${auditId}/goodpractice/${gpId}`,
  };
};

export const updateGP = ({ auditId, gpId, gpObj }) => {
  return {
    method: 'put',
    url: `/audits/${auditId}/goodpractice/${gpId}`,
    data: gpObj,
  };
};

/* END Good practices requests */
