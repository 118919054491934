import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import NavTiles from '../shared/nav-tiles/NavTiles';
import Alert from 'react-bootstrap/Alert';
import { useSelector } from 'react-redux';

const DocumentsTab = () => {
  const { auditId } = useParams();
  const isOnline = useSelector((state) => state.offline.online);

  const navTilesData = [
    {
      tileIcon: 'plus-circle',
      tileLabel: 'Add a new document',
      tooltipContent: `Add a new document - only in online mode`,
      linkPath: `/audit/${auditId}/documents/new-document`,
      isOfflineDisabled: true,
    },
    {
      tileIcon: 'search',
      tileLabel: 'Browse documents',
      tooltipContent: 'Browse documents',
      linkPath: `/audit/${auditId}/documents/select-document`,
    },
  ];

  return (
    <Fragment>
      {!isOnline && (
        <Alert variant='info'>
          In offline mode documents functionalities are limited - you can't add
          new documents.
        </Alert>
      )}
      <div className='mt-5'>
        <NavTiles navTilesData={navTilesData} />
      </div>
    </Fragment>
  );
};

export default DocumentsTab;
