import React, { Fragment, useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faQuestion } from '@fortawesome/free-solid-svg-icons';
import TableFiltered from '../table/TableFiltered';
import { useSelector } from 'react-redux';
import { sortInterviewQuestions } from '../../../utils/sorting';
import { filterInterviewsAgainstTypeOfSite } from '../../../utils/filtering';

const LinkQuestions = ({
  linked,
  onAddLinkedQuestion,
  onRemoveLinkedQuestion,
}) => {
  const { auditId } = useParams();
  const { interviews, questions, auditSiteType } = useSelector((state) => {
    const interviews = state.interviews.interviews;
    const auditSiteType =
      state.audits.audits.find((audit) => audit.audit_id === parseInt(auditId))
        .type_of_site || '';
    const questions = interviews
      .filter((interview) => interview.audit_id === parseInt(auditId))
      .filter(filterInterviewsAgainstTypeOfSite(auditSiteType))
      .reduce((acc, interview) => {
        return [...acc, ...interview.questions];
      }, [])
      .sort(sortInterviewQuestions);
    return {
      interviews: interviews,
      questions: questions,
      auditSiteType: auditSiteType,
    };
  });
  const [show, setShow] = useState(false);
  const [selectedFlatRows, setSelectedFlatRows] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Interview category',
        accessor: 'category_letter',
        disableFilters: true,
        Cell: ({ value, row: { original } }) => {
          return `${value} - ${original.question_category}`;
        },
      },
      {
        Header: 'Question number',
        accessor: 'entity_question',
        disableFilters: true,
        Cell: ({ value }) => {
          return value.split('#')[2];
        },
      },
      {
        Header: 'Interviewer',
        accessor: 'auditor',
        disableFilters: true,
      },
      {
        Header: 'Interview partner(s)',
        accessor: 'auditee',
        disableFilters: true,
      },
    ],
    []
  );

  const handleAddLinkedQuestion = () => {
    const linkedQuestion = selectedFlatRows[0].original.entity_question;
    // Check for duplicates
    if (!linked.some((el) => el === linkedQuestion)) {
      onAddLinkedQuestion(linkedQuestion);
    }
  };

  const handleRemoveLinkedQuestion = (linkedQuestion) => {
    onRemoveLinkedQuestion(linkedQuestion);
  };

  const data = React.useMemo(
    () =>
      interviews
        .filter((interview) => interview.audit_id === parseInt(auditId))
        .filter(filterInterviewsAgainstTypeOfSite(auditSiteType))
        .reduce((acc, interview) => {
          return [...acc, ...interview.questions];
        }, [])
        .sort(sortInterviewQuestions),
    [interviews, auditId, auditSiteType]
  );

  const handleCloseModal = () => {
    setShow(false);
    // toggleAllRowsSelected(false);
  };

  const handleChangeSelection = useCallback((row) => {
    setSelectedFlatRows(row);
  }, []);

  return (
    <Fragment>
      <div>
        <h6 className='d-inline-block'>Linked questions:</h6>
        <FontAwesomeIcon
          icon='plus-circle'
          className='pointer ml-2 align-middle'
          onClick={() => setShow(true)}
        />
        {linked.length > 0 && (
          <div className='mb-2'>
            {linked.map((item, index) => {
              const q = questions.find((el) => el.entity_question === item);
              if (q) {
                return (
                  <Fragment key={index}>
                    {index > 0 && <span className='mx-2'>|</span>}
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id={`tooltip-${index}`}>
                          Interview category: {q.category_letter} -{' '}
                          {q.question_category}
                          <br />
                          Question number: {item.split('#')[2]}
                          <br />
                          Interviewer: {q.auditor}
                          <br />
                          Interview partner(s): {q.auditee}
                        </Tooltip>
                      }
                    >
                      <Link
                        to={`/audit/${auditId}/interviews/${
                          item.split('#')[1]
                        }`}
                      >
                        <FontAwesomeIcon
                          icon={faQuestion}
                          size='lg'
                          color='#2387aa'
                        />
                      </Link>
                    </OverlayTrigger>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className='pointer ml-2 text-danger'
                      onClick={() => handleRemoveLinkedQuestion(item)}
                    />
                  </Fragment>
                );
              } else return '';
            })}
          </div>
        )}
      </div>
      <Modal
        show={show}
        onHide={handleCloseModal}
        size='xl'
        aria-labelledby='observations-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title id='observations-modal'>
            Select interview question
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <TableFiltered
              data={data}
              columns={columns}
              onChangeSelection={handleChangeSelection}
              showFilters={false}
            />
          </Row>
          {questions.length > 0 && (
            <Row>
              <Col>
                <div className='text-right'>
                  <Button
                    variant='primary'
                    disabled={selectedFlatRows.length !== 1}
                    onClick={handleAddLinkedQuestion}
                  >
                    Link selected element
                  </Button>
                </div>
              </Col>
            </Row>
          )}
          <Row className='mt-2'>
            <Col>
              <h6>Linked questions:</h6>
              {linked.length > 0 &&
                questions.length > 0 &&
                linked.map((item, index) => {
                  const q = questions.find((el) => el.entity_question === item);
                  if (q) {
                    return (
                      <p key={index}>
                        <strong>Interview category</strong>: {q.category_letter}{' '}
                        - {q.question_category} |{' '}
                        <strong>Question number</strong>: {item.split('#')[2]} |{' '}
                        <strong>Interviewer</strong>: {q.auditor} |{' '}
                        <strong>Interview partner(s)</strong>: {q.auditee}
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className='pointer ml-2 text-danger'
                          onClick={() => handleRemoveLinkedQuestion(item)}
                        />
                      </p>
                    );
                  } else return '';
                })}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

LinkQuestions.defaultProps = {
  linked: [],
};

export default LinkQuestions;
