import latinize from 'latinize';

latinize.characters = {
  ...latinize.characters,
  Ä: 'Ae',
  Ö: 'Oe',
  Ü: 'Ue',
  ä: 'ae',
  ö: 'oe',
  ü: 'ue',
};

export default latinize;
