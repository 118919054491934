import React, { Fragment } from 'react';
import PageName from '../layout/PageName';
import AuditSelectionTable from '../selectAudit/AuditSelectionTable';

const SelectAudit = () => {
  return (
    <Fragment>
      <PageName name='Select audit' />
      <AuditSelectionTable />
    </Fragment>
  );
};

export default SelectAudit;
