export const SET_LOADING = 'mpas/setLoading';
export const FETCH_MPAS_INIT = 'fetchMpasInit';
export const FETCH_MPAS_REFRESH_COMMIT = 'fetchMpasRefreshCommit';
export const FETCH_MPAS_REFRESH_ROLLBACK = 'fetchMpasRefreshRollback';
export const UPDATE_MPA = 'updateMpa';
export const UPDATE_MPA_COMMIT = 'updateMpaCommit';
export const CREATE_MPA = 'createMpa';
export const CREATE_MPA_COMMIT = 'createMpaCommit';
export const DELETE_MPA = 'deleteMpa';
export const DELETE_MPA_COMMIT = 'deleteMpaCommit';
