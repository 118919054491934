import {
  SET_LOADING,
  FETCH_INTERVIEWS_INIT,
  FETCH_INTERVIEWS_REFRESH_COMMIT,
  FETCH_INTERVIEWS_REFRESH_ROLLBACK,
  FETCH_INTERVIEW_DETAILS_INIT,
  FETCH_INTERVIEW_DETAILS_REFRESH,
  FETCH_INTERVIEW_DETAILS_REFRESH_COMMIT,
  FETCH_INTERVIEW_DETAILS_REFRESH_ROLLBACK,
  UPDATE_INTERVIEW_ANSWER,
  UPDATE_INTERVIEW_ANSWER_COMMIT,
  CREATE_INTERVIEW,
  CREATE_INTERVIEW_COMMIT,
  DELETE_INTERVIEW,
  DELETE_INTERVIEW_COMMIT,
} from '../types/interviewsTypes';
import axiosInst from '../../http/axiosConfig';
import {
  fetchInterviewsList,
  fetchInterviewDetails,
  updateInterviewQuestionAnswers,
  createNewInterview,
  deleteInterviewById,
} from '../../http/requests';
import { toUrlId, toInterviewEntityId } from '../../utils/convertId';
import { sortInterviewQuestions } from '../../utils/sorting';
import { toast } from 'react-toastify';

export const fetchInterviewsInit = (auditId) => {
  return (dispatch) => {
    // dispatch({
    //   type: SET_LOADING,
    //   payload: true,
    // });
    axiosInst(fetchInterviewsList(auditId))
      .then((response) => {
        const auditInterviews = response.data;
        dispatch({
          type: FETCH_INTERVIEWS_INIT,
          payload: auditInterviews,
        });
        auditInterviews.forEach((interview) => {
          dispatch(
            fetchInterviewDetailsInit({
              auditId,
              interviewId: toUrlId(interview.entity_id),
            })
          );
        });
      })
      .catch((error) => {
        //
      });
  };
};

export const fetchInterviewDetailsInit = ({ auditId, interviewId }) => {
  return (dispatch) => {
    axiosInst(fetchInterviewDetails({ auditId, interviewId }))
      .then((response) => {
        dispatch({
          type: FETCH_INTERVIEW_DETAILS_INIT,
          payload: {
            questions: response.data,
            entity_id: toInterviewEntityId(interviewId),
          },
        });
      })
      .catch((error) => {
        //
      });
  };
};

export const fetchInterviewsRefresh = (auditId) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      payload: true,
      meta: {
        offline: {
          effect: fetchInterviewsList(auditId),
          commit: {
            type: FETCH_INTERVIEWS_REFRESH_COMMIT,
            meta: {
              audit_id: parseInt(auditId),
            },
          },
          rollback: {
            type: FETCH_INTERVIEWS_REFRESH_ROLLBACK,
          },
        },
      },
    });
  };
};

export const fetchInterviewDetailsRefresh = ({ auditId, interviewId }) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_INTERVIEW_DETAILS_REFRESH,
      payload: {
        entity_id: toInterviewEntityId(interviewId),
      },
    });
    axiosInst(fetchInterviewDetails({ auditId, interviewId }))
      .then((response) => {
        dispatch({
          type: FETCH_INTERVIEW_DETAILS_REFRESH_COMMIT,
          payload: {
            entity_id: toInterviewEntityId(interviewId),
            data: response.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_INTERVIEW_DETAILS_REFRESH_ROLLBACK,
          payload: {
            entity_id: toInterviewEntityId(interviewId),
          },
        });
      });
  };
};

export const updateInterviewAnswer = ({
  audit_id,
  question_id,
  interview_id,
  questionObj,
}) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_INTERVIEW_ANSWER,
      payload: {
        entity_id: toInterviewEntityId(interview_id),
        question_id,
        data: questionObj,
      },
      meta: {
        offline: {
          effect: updateInterviewQuestionAnswers({
            audit_id,
            question_id,
            interview_id,
            questionObj,
          }),
          commit: {
            type: UPDATE_INTERVIEW_ANSWER_COMMIT,
            meta: {
              entity_id: toInterviewEntityId(interview_id),
              question_id,
              then: () => () => {
                toast.success('Interview answer updated');
              },
            },
          },
        },
      },
    });
  };
};

export const createInterview = ({ auditId, interviewObj }) => {
  // Create temporary id - timestamp
  const tempId = `I${Date.now()}_temp`;
  return (dispatch, getState) => {
    const questions = getState()
      .questions.questions.questions.filter(
        (q) =>
          q.category_letter.toLowerCase() ===
          interviewObj.questions_category_letter.toLowerCase()
      )
      .map((q) => {
        const { creation_timestamp, question_number, ...qRest } = q;
        return {
          ...qRest,
          audit_id: parseInt(auditId),
          auditee: interviewObj.auditee,
          auditor: interviewObj.auditor,
          entity_id: tempId,
          entity_question: `#${tempId}#${q.question_id}`,
          in_report: false,
          link_observations: [],
          link_photos: [],
          link_documents: [],
          tags: [],
          is_significant: '',
          question_answer: '',
          question_comment: '',
          root_cause: '',
          impact: '',
        };
      });
    const interviewData = {
      ...interviewObj,
      audit_id: parseInt(auditId),
      in_report: false,
      entity_id: toInterviewEntityId(tempId),
      question_category: getState().questions.categories.categories.find(
        (cat) =>
          cat.category_letter.toLowerCase() ===
          interviewObj.questions_category_letter.toLowerCase()
      ).question_category,
      questions_answered: 0,
      questions_in_report: 0,
      questions_count: questions.length,
      questions: questions.sort(sortInterviewQuestions),
    };

    dispatch({
      type: CREATE_INTERVIEW,
      payload: {
        entity_id: toInterviewEntityId(tempId),
        data: interviewData,
      },
      meta: {
        offline: {
          effect: createNewInterview({ auditId, interviewObj }),
          commit: {
            type: CREATE_INTERVIEW_COMMIT,
            meta: {
              temp_entity_id: toInterviewEntityId(tempId),
              then: () => () => {
                toast.success('Interview created');
              },
            },
          },
        },
      },
    });
  };
};

export const deleteInterview = ([auditId, interviewId]) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_INTERVIEW,
      payload: {
        entity_id: toInterviewEntityId(interviewId),
      },
      meta: {
        offline: {
          effect: deleteInterviewById([auditId, interviewId]),
          commit: {
            type: DELETE_INTERVIEW_COMMIT,
            meta: {
              then: () => () => {
                toast.success('Interview deleted');
              },
            },
          },
        },
      },
    });
  };
};
