import React, { Fragment, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

const Tags = ({ tags, onAddTag, onRemoveTag }) => {
  const [showTagInput, setShowTagInput] = useState(false);
  const [tagInput, setTagInput] = useState('');

  const handleAddTag = () => {
    if (tagInput.trim()) {
      onAddTag(tagInput.trim());
      setTagInput('');
    }
  };

  const handleCloseTagInput = () => {
    setTagInput('');
    setShowTagInput(false);
  };

  const handleRemoveTag = (tagName) => {
    onRemoveTag(tagName);
  };

  return (
    <Fragment>
      <div>
        <h6 className='d-inline-block'>Tags</h6>
        <FontAwesomeIcon
          icon='plus-circle'
          className='pointer ml-2 align-middle'
          onClick={() => setShowTagInput(true)}
        />
      </div>
      <div className='mb-2'>
        {tags.length > 0 &&
          tags.map((tag, index) => (
            <Badge variant='success' className='mr-1 mb-1' key={index}>
              {tag}
              <FontAwesomeIcon
                icon={faTimesCircle}
                className='pointer ml-1'
                onClick={() => handleRemoveTag(tag)}
              />
            </Badge>
          ))}
      </div>
      {showTagInput && (
        <Row className='align-items-center'>
          <Col xs={8}>
            <Form.Control
              type='text'
              placeholder='Enter tag name'
              size='sm'
              value={tagInput}
              onChange={(e) => setTagInput(e.target.value)}
            />
          </Col>
          <Col className='text-center'>
            <FontAwesomeIcon
              icon={faCheckCircle}
              className='pointer mr-2'
              onClick={handleAddTag}
            />
            <FontAwesomeIcon
              icon={faTimesCircle}
              className='pointer'
              onClick={handleCloseTagInput}
            />
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default Tags;
