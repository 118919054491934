export const filterInterviewsAgainstTypeOfSite = (auditSiteType) => {
  return (interview) => {
    if (auditSiteType.toLowerCase() === 'manufacturing') {
      return interview.questions_category_letter.toLowerCase() !== 'd';
    } else {
      return interview;
    }
  };
};

export const filterInterviewCategoriesAgainstTypeOfSite = (auditSiteType) => {
  return (category) => {
    if (
      auditSiteType.toLowerCase() === 'manufacturing' &&
      category.category_letter.toLowerCase() === 'd'
    ) {
      return {
        ...category,
        disabled: true,
      };
    } else {
      return category;
    }
  };
};
